import * as ko from 'knockout';
import dashboard from './dashboard';
import supplierPurchaseOrder from './supplier-purchase-order';
import createPurchaseOrder from './create-purchase-order';
import details from './details';
import receive from './receive';

export default function () {
  ko.components.register(dashboard.name, dashboard);
  ko.components.register(supplierPurchaseOrder.name, supplierPurchaseOrder);
  ko.components.register(createPurchaseOrder.name, createPurchaseOrder);
  ko.components.register(details.name, details);
  ko.components.register(receive.name, receive);
}
