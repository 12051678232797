import * as ko from 'knockout';
import { Computed, Observable } from 'knockout';
import { userService } from '../../api/service.auth';
import router from '../../routing/router';
import routes from '../../routing/routes';
import { dialog, component } from '../app';
import { DialogParams, FieldType } from '../elements/bp-dialog';

class BpHeader {
  logo: Computed<string>;
  isAdmin: Computed<boolean>;
  highlight: {
    shipments: ko.PureComputed<boolean>;
    batches: ko.PureComputed<boolean>;
    weborders: ko.PureComputed<boolean>;
    admin: ko.PureComputed<boolean>;
    timesheet: ko.PureComputed<boolean>;
  };

  readonly dialog: Observable<DialogParams | null>;

  constructor(params: any) {
    this.logo = ko.pureComputed(() => params.logo ? ko.unwrap(params.logo) : '');

    this.dialog = ko.observable(null);

    this.highlight = {
      shipments: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/shipments') === 0;
      }),
      batches: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/batches') === 0;
      }),
      weborders: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/weborders') === 0;
      }),
      admin: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/admin') === 0;
      }),
      timesheet: ko.pureComputed(() => {
        component();

        return window.location.pathname.indexOf('/timesheet') === 0;
      })
    };

    const roles = ko.observableArray<string>();
    const isUserAdmin = ko.observable<boolean>(false);

    this.isAdmin = ko.pureComputed(() => roles.indexOf('Admin') > -1 || roles.indexOf('Super') > -1 || isUserAdmin() || 1 == 1);

    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);

      if (!user.roles) { return; }

      roles(user.roles);
      isUserAdmin(user.isAdmin);
    }
  }

  actions = {
    logout: (): void => {
      userService.logout();

      router.goto(routes.home.interpolate({}));
    }
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({})),
    admin: (): void => router.goto(routes.adminDashboard.interpolate({})),
    shipments: (): void => router.goto(routes.shipmentsDashboard.interpolate({})),
    batches: (): void => router.goto(routes.batchesDashboard.interpolate({})),

    weborders: (): void => router.goto(routes.webordersDashboard.interpolate({})),

    timesheet: (): void => router.goto(routes.timesheetDetails.interpolate({})),
  }
}

export default {
  name: 'bp-header',
  viewModel: BpHeader,
  template: require('./bp-header.html')
};
