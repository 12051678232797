import { IdInfo } from '../components/elements/bp-dialog';
import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { fetchHandler } from './fetchHandler';

export const suppliersService = {
  getAll,
  getActive,
  getById,
  getProducts,
  createSupplier,
  deactivateSupplier,
  reactivateSupplier,
  updateSupplier,
  createSupplierProduct,
  getConsumableProducts,
  getSupplierProducts,
  getSupplierProductsById,
  updateSupplierProduct,
  deactivateSupplierProduct,
  reactivateSupplierProduct,
  updateProductConsumable,
  createProductConsumable,
  createSupplierInvoice,
  getFreightTypes
};

type SupplierResponse = {
  supplierId: number;
  name: string;
  contactName: string;
  orderEmailAddress: string;
  contactPhoneNumber: string;
  isActive: boolean;
  orderNotes: string;
}

type SupplierProductResponse = {
  supplierProductId: number;
  name: string;
  isActive: boolean;
  unitWeight: number;
  unitType: string;
  lastPurchasePrice: number;
  priceChangeTime: string;
  previousPurchasePrice: string;
  isPackaging: boolean;
  supplierOrderCode: string;
}

type GetConsumableProductsResponse = {
  consumableProductId: number;
  name: string;
  price: number;
  quantity: number;
}

type GetSupplierProductsResponse = {
  supplierProductId: number;
  name: string;
  supplierName: string;
}

function getAll(): Promise<SupplierResponse[]> {
  return fetchHandler(`${config.apiUrl}/supplier/getSuppliers`, authPostDataRequest({}))
    .then((result) => { return result.suppliers; });
}

function getActive(): Promise<SupplierResponse[]> {
  return fetchHandler(`${config.apiUrl}/supplier/getSuppliers`, authPostDataRequest({ activeOnly: true }))
    .then((result) => { return result.suppliers; });
}

function getById(supplierId: number): Promise<SupplierResponse> {
  return fetchHandler(`${config.apiUrl}/supplier/details`, authPostDataRequest({ supplierId }))
    .then(supplier => { return supplier; });
}

function getProducts(supplierId: number): Promise<SupplierProductResponse[]> {
  return fetchHandler(`${config.apiUrl}/supplier/getProducts`, authPostDataRequest({ supplierId, activeOnly: true }))
    .then(result => { return result.supplierProducts; });
}

function createSupplier(name: string, orderEmailAddress: string, contactName: string, contactPhoneNumber: string, orderNotes: string): Promise<SupplierResponse> {
  return fetchHandler(`${config.apiUrl}/supplier/create`, authPostDataRequest({ name, orderEmailAddress, contactName, contactPhoneNumber, orderNotes }))
    .then((supplier: SupplierResponse) => { return supplier; });
}

function deactivateSupplier(supplierId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/supplier/activate`, authPostDataRequest({ supplierId, isActive: false }))
    .then((result) => { return result; });
}

function reactivateSupplier(supplierId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/supplier/activate`, authPostDataRequest({ supplierId, isActive: true }))
    .then((result) => { return result; });
}

function updateSupplier(supplierId: number, name: string, orderEmailAddress: string, contactName: string, contactPhoneNumber: string | null, orderNotes: string | null): Promise<SupplierResponse> {
  return fetchHandler(`${config.apiUrl}/supplier/update`, authPostDataRequest({ supplierId, name, orderEmailAddress, contactName, contactPhoneNumber, orderNotes }))
    .then((supplier: SupplierResponse) => { return supplier; });
}

function createSupplierProduct(supplierId: number, name: string, unitWeight: number, unitType: string, lastPurchasePrice: number, isPackaging: boolean, supplierOrderCode: string): Promise<SupplierProductResponse> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/create`, authPostDataRequest({ supplierId, name, unitWeight, unitType, lastPurchasePrice, isPackaging, supplierOrderCode }))
    .then((supplierProduct: SupplierProductResponse) => { return supplierProduct; });
}

function updateSupplierProduct(supplierId: number, supplierProductId: number, name: string, unitWeight: number, unitType: string, lastPurchasePrice: number, isPackaging: boolean, supplierOrderCode: string): Promise<SupplierProductResponse> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/update`, authPostDataRequest({ supplierId, supplierProductId, name, unitWeight, unitType, lastPurchasePrice, isPackaging, supplierOrderCode }))
    .then((supplierProduct: SupplierProductResponse) => { return supplierProduct; });
}

function deactivateSupplierProduct(supplierId: number, supplierProductId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/activate`, authPostDataRequest({ supplierId, supplierProductId, isActive: false }))
    .then((result) => { return result; });
}

function reactivateSupplierProduct(supplierId: number, supplierProductId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/activate`, authPostDataRequest({ supplierId, supplierProductId, isActive: true }))
    .then((result) => { return result; });
}

function getConsumableProducts(): Promise<GetConsumableProductsResponse[]> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/getConsumables`, authPostDataRequest({}))
    .then((result) => { return result.list; });
}

function updateProductConsumable(productConsumableId: number, name: string, price: number, quantity: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/updateConsumable`, authPostDataRequest({ productConsumableId, name, price, quantity }))
    .then((result: boolean) => { return result; });
}

function createProductConsumable(name: string, price: number, quantity: number): Promise<number> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/createConsumable`, authPostDataRequest({ name, price, quantity }))
    .then((result: number) => { return result; });
}

function getSupplierProducts(): Promise<GetSupplierProductsResponse[]> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/get`, authPostDataRequest({}))
    .then((result) => { return result.list; });
}

function getSupplierProductsById(supplierId: number): Promise<GetSupplierProductsResponse[]> {
  return fetchHandler(`${config.apiUrl}/supplierProduct/get`, authPostDataRequest({ supplierId }))
    .then((result) => { return result.list; });
}

function createSupplierInvoice(supplierId: number, invoiceNumber: string): Promise<number> {
  return fetchHandler(`${config.apiUrl}/supplier/saveSupplierInvoice`, authPostDataRequest({ supplierId, invoiceNumber }))
    .then(result => { return result; });
}

function getFreightTypes(): Promise<any[]> {
  return fetchHandler(`${config.apiUrl}/idInfo/freightTypes`, authPostDataRequest({}))
    .then((result) => result);
}
