import { timer } from 'd3';
import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { dashboardService } from '../../api/service.dashboard';
import { locationsService } from '../../api/service.locations';
import { productsService } from '../../api/service.products';
import router from '../../routing/router';
import routes from '../../routing/routes';
import { formatDateTime, formatWeight } from '../../utils/format';
import { sortFn } from '../../utils/sort-functions';
import { dialog } from '../app';
import { FieldType, IdInfo } from '../elements/bp-dialog';

class Main {
  links: ObservableArray<any>;
  history: ObservableArray<any>;

  readonly productsList: ObservableArray<IdInfo> = ko.observableArray();
  readonly accessoriesList: ObservableArray<IdInfo> = ko.observableArray();
  readonly locations: ObservableArray<any> = ko.observableArray();
  readonly locationsList: ObservableArray<IdInfo> = ko.observableArray();
  readonly productLocations: ObservableArray<any> = ko.observableArray();
  readonly userPin: Observable<string> = ko.observable('');

  readonly lastUpdated: Observable<string>;
  timeout = 60000 * 5;

  reloadInterval: NodeJS.Timer | undefined;

  constructor(params: any) {
    this.links = ko.observableArray(params.links);
    this.history = ko.observableArray(params.history);

    this.links.push({ action: () => this.actions.createMovement(), label: 'Movement', icon: 'icon/servers-add', description: 'Create a movement' });
    this.links.push({ action: () => this.actions.addToStock(), label: 'Add Accessories', icon: 'icon/servers-add', description: 'Add to stock' });

    this.loadRecentMovements();

    if (this.locations().length == 0) {
      this.loadLocations();
    }

    this.updateProductLocationMaps();

    const currentTime = new Date().toLocaleTimeString();
    this.lastUpdated = ko.observable(`Updated: ${currentTime}`);

    if (currentTime >= '05:00:00 PM' && currentTime < '06:00:00 AM') {
      this.timeout = 60000 * 60 * 12;
    } else {
      this.timeout = 60000 * 5;
    }

    this.startReload();
  }

  startReload = () => {
    this.lastUpdated(`Updated: ${new Date().toLocaleTimeString()}`);
    this.reloadInterval = setInterval(() => window.location.reload(true), this.timeout);
  };

  endReload = () => {

    this.lastUpdated('');

    if (this.reloadInterval != null) {
      clearInterval(this.reloadInterval);
    }
  }

  loadRecentMovements = () => {
    this.history([]);
    dashboardService.getRecentMovements()
      .then(results => {
        results.map(m => {
          this.history.push({
            date: formatDateTime(m.time),
            description: `${m.fromLocation} -> ${m.toLocation} (x${m.quantity})`,
            label: `${m.productName} ${formatWeight(m.unitWeight)}`
          });
        });
      });
  };

  updateProductLocationMaps = () => {
    dashboardService.getTheBoard()
      .then(results => {

        const productRows = results.map(p => {
          return ({
            ...p,
            rowClass: p.isCritical ? 'critical' : p.isWarning ? 'warning' : !p.isCritical && !p.isWarning ? 'idle' : '',
          });
        });

        const blocks = productRows.filter(r => r.groupName === 'Blocks (Bone In)' || r.groupName === 'Blocks (Meat Only)' || r.groupName === 'Whole / Pieces')
          .sort(function (a: any, b: any) {
            return sortFn(a.groupName, b.groupName) || sortFn(a.productName, b.productName)
          });

        const bones = productRows.filter(r => r.groupName === 'Bones')
          .sort(function (a: any, b: any) {
            return sortFn(a.productName, b.productName)
          });

        const bulk = productRows.filter(r => r.groupName === 'Bulk')
          .sort(function (a: any, b: any) {
            return sortFn(a.productName, b.productName)
          });

        const accessories = productRows.filter(r => r.groupName === 'Accessories')
          .sort(function (a: any, b: any) {
            return sortFn(a.productName, b.productName)
          });

        const products = blocks.concat(bones).concat(bulk).concat(accessories);

        this.productLocations(products);
      });
  };

  loadLocations = () => {
    dashboardService.getPhysicalLocations()
      .then((results) => {
        const locationMap = results.map(l => {

          return ({
            locationId: l.locationId,
            name: l.name
          });

        });

        this.locations(locationMap);
      });
  };

  actions = {
    createMovement: () => {
      this.timeout = 60000 * 5;
      this.endReload();
      this.startReload();

      const errorMessage = ko.observable('');

      const userPinField: FieldType<string> = {
        title: 'User PIN',
        type: 'userPin',
        value: ko.observable(this.userPin()),
        setFocus: true
      };

      const productField: FieldType<IdInfo> = {
        title: 'Product:',
        type: 'dropdown',
        value: ko.observable<number>(),
        options: this.productsList,
        optionsCaption: ko.observable('loading... '),
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        filterText: ko.observable('')
      };

      const fromField: FieldType<IdInfo> = {
        title: 'Move From:',
        type: 'dropdown',
        value: ko.observable<number>(),
        options: this.locationsList,
        optionsCaption: ko.observable('loading... '),
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        filterText: ko.observable('')
      };

      const toField: FieldType<IdInfo> = {
        title: 'Move To:',
        type: 'dropdown',
        value: ko.observable<number>(),
        options: this.locationsList,
        optionsCaption: ko.observable('loading... '),
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        filterText: ko.observable('')
      };

      const quantityField: FieldType<number> = {
        title: 'Quantity Moved:',
        type: 'number',
        value: ko.observable(0),
      };

      const errorField: FieldType<string> = {
        title: '',
        type: 'display',
        value: errorMessage
      };

      dialog({
        title: 'Create new movement',
        message: ko.observable(''),
        fields: ko.observableArray([userPinField, productField, fromField, toField, quantityField, errorField]),
        submitText: 'Save',
        cancelText: 'Cancel',
        submitAction: () => {
          let errors = '';

          const userPin = userPinField.value();
          const productId = Number(productField.value());
          const fromLocationId = Number(fromField.value());
          const toLocationId = Number(toField.value());
          const quantity = Number(quantityField.value());

          if (userPin === '') {
            errors += 'You must provide your USER PIN <br />';
          }

          if (productId < 1) {
            errors += 'You must select a product to move <br />';
          }

          if (fromLocationId < 1) {
            errors += 'You must select a location to move FROM <br />';
          }

          if (toLocationId < 1) {
            errors += 'You must select a location to move TO <br />';
          }

          if (quantity == 0) {
            errors += 'You cant move a 0 quantity <br />';
          }

          if (errors !== '') {
            errorMessage(`<span style='color: red'>Errors: ${errors}</span>`);

            return;
          }

          this.userPin(userPin as string);

          dashboardService.saveMovement(
            userPin,
            productId,
            fromLocationId,
            toLocationId,
            quantity
          )
            .then(result => {
              if (!result.success) {

                errorMessage(`<span style='color: red'>Errors: ${result.message}</span>`);
                return;
              }

              this.updateProductLocationMaps();
              this.loadRecentMovements();

              dialog(null);
            });

          this.startReload();
        }
      });

      ko.computed(() => {
        productsService.getAllManufactured()
          .then(results => {
            const productMap = results.map(p => ({
              Id: p.productId,
              Name: p.name
            }));

            this.productsList(productMap);
            productField.optionsCaption && productField.optionsCaption('-- SELECT --');
          });

        locationsService.getAll()
          .then(results => {
            const locationMap = results.map(l => ({
              Id: l.locationId,
              Name: l.name
            }));

            this.locationsList(locationMap);
            fromField.optionsCaption && fromField.optionsCaption('-- SELECT --');
            toField.optionsCaption && toField.optionsCaption('-- SELECT --');

            fromField.value(8);

          });
      });
    },

    addToStock: () => {
      this.timeout = 60000 * 5;
      this.endReload();
      this.startReload();

      const userPinField: FieldType<string> = {
        title: 'User PIN',
        type: 'userPin',
        value: ko.observable(this.userPin()),
        setFocus: true
      };

      const productField: FieldType<IdInfo> = {
        title: 'Product:',
        type: 'dropdown',
        value: ko.observable<number>(),
        options: this.accessoriesList,
        optionsCaption: ko.observable('loading... '),
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        filterText: ko.observable('')
      };

      const quantityField: FieldType<number> = {
        title: 'Quantity Added to Retail:',
        type: 'number',
        value: ko.observable(0),
      };

      dialog({
        title: 'Create new movement',
        message: ko.observable(''),
        fields: ko.observableArray([userPinField, productField, quantityField]),
        submitText: 'Save',
        cancelText: 'Cancel',
        submitAction: () => {
          let errors = '';

          const userPin = userPinField.value();
          const productId = Number(productField.value());
          const fromLocationId = 8;
          const toLocationId = 6;
          const quantity = Number(quantityField.value());

          if (userPin === '') {
            errors += 'You must provide your USER PIN <br />';
          }

          if (productId < 1) {
            errors += 'You must select a product to move <br />';
          }

          if (fromLocationId < 1) {
            errors += 'You must select a location to move FROM <br />';
          }

          if (toLocationId < 1) {
            errors += 'You must select a location to move TO <br />';
          }

          if (quantity == 0) {
            errors += 'You cant move a 0 quantity <br />';
          }

          if (errors !== '') {
            alert('errors');

            return;
          }

          this.userPin(userPin as string);

          dashboardService.saveMovement(
            userPin,
            productId,
            fromLocationId,
            toLocationId,
            quantity
          )
            .then(result => {
              this.updateProductLocationMaps();
              this.loadRecentMovements();
            });

          dialog(null);
          this.startReload();
        }
      });

      ko.computed(() => {
        productsService.getAllAccessories()
          .then(results => {
            const productMap = results.map(p => ({
              Id: p.productId,
              Name: p.name
            }));

            this.accessoriesList(productMap);
            productField.optionsCaption && productField.optionsCaption('-- SELECT --');
          });

        locationsService.getAll()
          .then(results => {
            const locationMap = results.map(l => ({
              Id: l.locationId,
              Name: l.name
            }));

            this.locationsList(locationMap);
          });
      });
    },

    createProductMovement: (productId: number, product: string, toLocationId: number, location: string) => {
      this.timeout = 60000 * 5;
      this.endReload();
      this.startReload();

      const errorMessage = ko.observable('');

      const userPinField: FieldType<string> = {
        title: 'User PIN',
        type: 'userPin',
        value: ko.observable(this.userPin()),
        setFocus: true
      };

      const productField: FieldType<string> = {
        title: 'Product:',
        type: 'display',
        value: ko.observable<string>(`Product: ${product}`)
      };

      const fromField: FieldType<IdInfo> = {
        title: 'Move From:',
        type: 'dropdown',
        value: ko.observable<number>(),
        options: this.locationsList,
        optionsCaption: ko.observable('loading... '),
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        filterText: ko.observable('')
      };

      const toField: FieldType<string> = {
        title: 'Move To:',
        type: 'display',
        value: ko.observable<string>(`Move To: ${location}`)
      };

      const quantityField: FieldType<number> = {
        title: 'Quantity Moved:',
        type: 'number',
        value: ko.observable(0),
      };

      const errorField: FieldType<string> = {
        title: '',
        type: 'display',
        value: errorMessage
      };

      dialog({
        title: 'Create new movement',
        message: ko.observable(''),
        fields: ko.observableArray([userPinField, productField, fromField, toField, quantityField, errorField]),
        submitText: 'Save',
        cancelText: 'Cancel',
        submitAction: () => {
          let errors = '';

          const userPin = userPinField.value();
          const fromLocationId = Number(fromField.value());
          const quantity = Number(quantityField.value());

          if (userPin === '') {
            errors += 'You must provide your USER PIN <br />';
          }

          if (fromLocationId < 1) {
            errors += 'You must select a location to move FROM <br />';
          }

          if (quantity == 0) {
            errors += 'You cant move a 0 quantity <br />';
          }

          if (errors !== '') {
            errorMessage(`<span style='color: red'>Errors: ${errors}</span>`);

            return;
          }

          this.userPin(userPin as string);

          dashboardService.saveMovement(
            userPin,
            productId,
            fromLocationId,
            toLocationId,
            quantity
          )
            .then(result => {
              if (!result.success) {

                errorMessage(`<span style='color: red'>Errors: ${result.message}</span>`);
                return;
              }

              this.updateProductLocationMaps();
              this.loadRecentMovements();

              dialog(null);
            });
        }
      });

      ko.computed(() => {
        locationsService.getAll()
          .then(results => {
            const locationMap = results.map(l => ({
              Id: l.locationId,
              Name: l.name
            }));

            this.locationsList(locationMap);
            fromField.optionsCaption && fromField.optionsCaption('-- SELECT --');

            fromField.value(8);

          });
      });
    },

    adjustProductCount: (productId: number, product: string, locationId: number, location: string, currentCount: number) => {
      this.timeout = 60000 * 5;
      this.endReload();
      this.startReload();

      const errorMessage = ko.observable('');

      const userPinField: FieldType<string> = {
        title: 'User PIN',
        type: 'userPin',
        value: ko.observable(this.userPin()),
        setFocus: true
      };

      const productField: FieldType<string> = {
        title: 'Product:',
        type: 'display',
        value: ko.observable<string>(`Product: ${product}`)
      };

      const locationField: FieldType<string> = {
        title: 'Location:',
        type: 'display',
        value: ko.observable<string>(`Location: ${location}`)
      };

      const quantityField: FieldType<number> = {
        title: 'Current Count - Enter the ACTUAL figure that is in location',
        type: 'number',
        value: ko.observable(currentCount),
      };

      const errorField: FieldType<string> = {
        title: '',
        type: 'display',
        value: errorMessage
      };

      dialog({
        title: 'Adjust product count',
        message: ko.observable('Only use this when the count is incorrect and needs fixing.'),
        fields: ko.observableArray([userPinField, productField, locationField, quantityField, errorField]),
        submitText: 'Save',
        cancelText: 'Cancel',
        submitAction: () => {
          let errors = '';

          const userPin = userPinField.value();
          const quantity = Number(quantityField.value());

          if (userPin === '') {
            errors += 'You must provide your USER PIN <br />';
          }

          if (quantity < 0) {
            errors += 'You cant enter a quantity less than 0<br />';
          }

          if (errors !== '') {
            errorMessage(`<span style='color: red'>Errors: ${errors}</span>`);

            return;
          }

          this.userPin(userPin as string);

          dashboardService.saveAdjustment(
            userPin,
            productId,
            locationId,
            quantity
          )
            .then(result => {
              if (!result.success) {

                errorMessage(`<span style='color: red'>Errors: ${result.message}</span>`);
                return;
              }

              this.updateProductLocationMaps();
              this.loadRecentMovements();

              dialog(null);
            });
        }
      });

    },
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'section-main',
  viewModel: Main,
  template: require('./main.html')
};
