import { PurchaseOrderQuantities } from '../components/views/shipments/create-purchase-order';
import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

export const shipmentsService = {
  getAll,
  getAllForSupplier,
  getUnreceived,
  getById,
  createOrder,
  getInvoiceItems,
  saveSupplierInvoice,
  getshipmentsForProduct
};

type ShipmentResponse = {
  supplierInvoiceId: number;
  receivedTime: number;
  supplierId: number;
  supplier: string;
  totalCost: number;
  itemCount: number;
  emailLogId: number;
  purchaseOrderNotes: string;
  time: number;
  firstName: string;
  lastName: string;
  invoiceNumber: string;
  items: any[];
}

type ShipmentItemResponse = {
  supplierProductId: number;
  supplierProduct: string;
  quantity: number;
  unitWeight: number;
  unitType: string;
  pricePerKg: number;
}

export type UnreceivedPurchaseOrderResponse = {
  emailLogId: number;
  name: string;
  purchaseOrderNotes: string;
  supplierId: number;
  supplierInvoiceId: number;
  time: string;
  orderItems: OrderItemResponse[];
}

export type OrderItemResponse = {
  supplierProductId: number;
  productName: string,
  quantity: number;
  pricePerKg: number;
  unitType: string;
  unitWeightGram: number;
}

export type InvoiceItemRequestModel = {
  supplierProductId: number;
  quantity: number;
  pricePerKg: number;
}

function getAll(): Promise<ShipmentResponse[]> {
  return fetchHandler(`${config.apiUrl}/shipments/get`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getAllForSupplier(supplierId: number): Promise<ShipmentResponse[]> {
  return fetchHandler(`${config.apiUrl}/shipments/getForSupplier`, authPostDataRequest({ supplierId }))
    .then((results) => { return results; });
}

function getUnreceived(supplierId: number): Promise<UnreceivedPurchaseOrderResponse[]> {
  return fetchHandler(`${config.apiUrl}/shipments/getOpenPurchaseOrders`, authPostDataRequest({ supplierId }))
    .then((results) => { return results; });
}

function getById(supplierInvoiceId: number): Promise<ShipmentResponse> {
  return fetchHandler(`${config.apiUrl}/shipments/details`, authPostDataRequest({ supplierInvoiceId }))
    .then(result => { return result; });
}

function getInvoiceItems(supplierInvoiceId: number): Promise<ShipmentItemResponse[]> {
  return fetchHandler(`${config.apiUrl}/shipments/invoiceItems`, authPostDataRequest({ supplierInvoiceId }))
    .then(result => { return result; });
}

function createOrder(supplierId: number, purchaseOrderNotes: string, items: PurchaseOrderQuantities[]): Promise<number | null> {
  console.log(items);

  return fetchHandler(`${config.apiUrl}/shipments/createPurchaseOrder`, authPostDataRequest({ supplierId, purchaseOrderNotes, items }))
    .then(result => { return result; });
}

function saveSupplierInvoice(supplierInvoiceId: number, invoiceNumber: string, items: InvoiceItemRequestModel[]): Promise<number> {
  return fetchHandler(`${config.apiUrl}/shipments/saveSupplierInvoice`, authPostDataRequest({ supplierInvoiceId, invoiceNumber, items }))
    .then(result => { return result; });
}

function getshipmentsForProduct(supplierProductId: number, limit: number): Promise<[{ shipmentId: number, receivedTime: number, quantity: number }]> {
  return fetchHandler(`${config.apiUrl}/shipments/getForSupplierProduct`, authPostDataRequest({ supplierProductId, limit }))
    .then((result) => { return result; });
}
