import * as ko from 'knockout';
import { Observable } from 'knockout';
import { usersService } from '../../../api/service.users';
import { ShippingMethodResponse, webOrdersService } from '../../../api/service.weborders';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatDateTime, formatTime } from '../../../utils/format';
import { calculateOrderProgressClass } from '../../../utils/helpers';
import { dialog } from '../../app';
import { ConfirmDialogParams } from '../../elements/bp-confirm-dialog';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';

type CreateModel = {
  userPin: Observable<string>;
  customerId: Observable<number>;
  paymentMethodId: Observable<number>;
  shippingMethodId: Observable<number>;
  addressId: Observable<number>;
};

class WebordersDashboard {
  readonly title: Observable<string>;
  readonly weborders: ko.ObservableArray<any>;
  readonly newDataDialog: Observable<DialogParams | null>;
  readonly confirmDialog: Observable<ConfirmDialogParams | null>;
  readonly customerList: ko.ObservableArray<IdInfo>;
  readonly paymentMethodList: ko.ObservableArray<IdInfo>;
  readonly shippingMethodList: ko.ObservableArray<IdInfo & ShippingMethodResponse>;
  readonly userAddressList: ko.ObservableArray<IdInfo>;

  createOrderModel: CreateModel;

  readonly lastUpdated: string;

  constructor(params: any) {
    let title = '(Open)';

    switch (params.type) {
      case 'pickup': title = ' (Pickups for today)'; break;
      case 'freighted': title = ' (To freight)'; break;
      case 'search': title = ' (Search results)'; break;
      default: title = ' (Open)';
    }

    this.title = ko.observable(`Web Orders ${title}`);
    this.weborders = ko.observableArray();
    this.newDataDialog = ko.observable(null);
    this.confirmDialog = ko.observable(null);

    this.customerList = ko.observableArray();
    this.paymentMethodList = ko.observableArray();
    this.shippingMethodList = ko.observableArray();
    this.userAddressList = ko.observableArray();

    this.createOrderModel = {
      userPin: ko.observable(''),
      customerId: ko.observable(0),
      paymentMethodId: ko.observable(0),
      shippingMethodId: ko.observable(0),
      addressId: ko.observable(0),
    };

    this.loadWebOrders(params.type, params.terms);

    let timeout = 60000 * 15;
    const currentTime = new Date().toLocaleTimeString();
    this.lastUpdated = `Updated: ${currentTime}`;

    if (currentTime >= '05:00:00 PM' && currentTime < '06:00:00 AM') {
      timeout = 60000 * 60 * 12;
    }

    setTimeout(() => window.location.reload(true), timeout);

    if (this.customerList().length == 0) {
      this.loadCustomers();
    }

    if (this.paymentMethodList().length == 0) {
      this.loadPaymentMethods();
    }

    if (this.shippingMethodList().length == 0) {
      this.loadShippingMethods();
    }

    ko.computed(() => {
      usersService.getByIdAddresses(this.createOrderModel.customerId())
        .then(addresses => {
          this.userAddressList(addresses.map(c => { return ({ Id: c.userAddressId, Name: `${c.streetAddress}, ${c.suburb} ${c.city} ${c.isDefault ? '(Default)' : ''}` }) }));
        });
    });
  }

  loadCustomers = () => {
    usersService.getAll()
      .then(users => {
        this.customerList(users.map(c => { return ({ Id: c.userId, Name: `${c.firstName} ${c.lastName} - ${c.emailAddress}` }) }));
      });
  };

  loadPaymentMethods = () => {
    webOrdersService.getPaymentMethods()
      .then(paymentMethods => {
        this.paymentMethodList(paymentMethods.map(c => { return ({ ...c, Id: c.id, Name: c.name }) }));
      });
  };

  loadShippingMethods = () => {
    webOrdersService.getShippingMethods()
      .then(shippingMethods => {
        this.shippingMethodList(shippingMethods.map(c => { return ({ ...c, Id: c.id, Name: c.name }) }));
      });
  };

  loadWebOrders = (type: string, terms: string) => {
    if (type === 'search') {
      const searchTerm = new URLSearchParams(terms);
      const mode = searchTerm.get('q');

      webOrdersService.getNameSearchResult(mode)
        .then(result => {
          this.updateWebOrdersList(result);
        })
    }

    webOrdersService.getCurrent()
      .then(result => {
        let filteredResults = [];

        switch (type) {
          case 'pickup':
            filteredResults = result
              .filter(r => r.isPickupToday)
              .sort(function (a, b) {
                var c: any = new Date(a.pickupTime);
                var d: any = new Date(b.pickupTime);
                return c - d;
              });
            break;

          case 'freighted': filteredResults = result.filter(r => r.isFreight); break;

          case 'all':
          default: filteredResults = result; break;
        }

        this.updateWebOrdersList(filteredResults);
      })
      .catch(e => console.log(e));
  };

  updateWebOrdersList(filteredResults: any[]) {
    const orderMap = filteredResults.map(s => {
      return {
        ...s,
        formattedTime: formatDate(s.time),
        formattedCustomerName: s.customerFirstName + ' ' + s.customerLastName,
        formattedPaymentMethod: s.paymentMethod,
        formattedStatus: s.status,
        formattedShippingMethod: s.shippingMethod,
        formattedContainers: s.containers,
        imageClass: calculateOrderProgressClass(s.suppliedItemCount, s.orderedItemCount),
        progressIcon: "icon/battery-full",
        paidIcon: s.paymentReceivedTime != null ? 'icon/paid' : 'icon/empty-space',
        formattedPickupTime: s.isPickup ? s.isPickupToday ? formatTime(s.pickupTime) : formatDateTime(s.pickupTime) : ''
      }
    });

    this.weborders(orderMap);
  }

  goto = {
    orderDetails: (webOrderId: number): void => router.goto(routes.webordersDetails.interpolate({ id: webOrderId, type: 'all' })),
  };

  actions = {
    createOrder: (): void => {
      const message = ko.observable<string>('');

      const userPinField: FieldType<string> = {
        title: 'User PIN',
        type: 'userPin',
        value: ko.observable(''),
        setFocus: true
      };

      const customerDisplayField: FieldType<string> = {
        title: 'Customer Details',
        type: 'display',
        value: ko.observable('Select customer or create a new customer'),
        setFocus: true
      };

      const customerIdField: FieldType<IdInfo> = {
        title: 'Select Customer',
        type: 'searchable-dropdown',
        value: ko.observable(),
        options: this.customerList,
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        filterText: ko.observable(''),
        optionsCaption: ko.observable('-- Select --')
      };

      let newCustomer: any = null;

      const newCustomerField: FieldType<string> = {
        title: ko.observable('New Customer'),
        type: 'button',
        iconName: 'icon/add-circle',
        value: ko.observable('Create new customer'),
        action: () => {
          this.actions.createNewCustomer(
            customerIdField,
            newCustomer && newCustomer.firstName,
            newCustomer && newCustomer.lastName,
            newCustomer && newCustomer.emailAddress,
            newCustomer && newCustomer.phoneNumber,
            newCustomer && newCustomer.phoneNumberTypeId,
            (model: any) => { newCustomer = model; });
        }
      };

      customerIdField.value.subscribe((id) => {
        if (id == -1) {
          ko.isObservable(newCustomerField.title) && newCustomerField.title('Edit Customer');
        } else {
          ko.isObservable(newCustomerField.title) && newCustomerField.title('New Customer');
        }
      });

      const paymentDisplayField: FieldType<string> = {
        title: 'Payment and Shipping Details',
        type: 'display',
        value: ko.observable('Select payment and shipping details')
      };

      const paymentMethodIdField: FieldType<IdInfo> = {
        title: 'Select Payment Method',
        type: 'dropdown',
        value: ko.observable(),
        options: this.paymentMethodList,
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      const shippingMethodIdField: FieldType<IdInfo> = {
        title: 'Select Shipping Method',
        type: 'dropdown',
        value: ko.observable(),
        options: this.shippingMethodList,
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      const addressDisplayField: FieldType<string> = {
        title: 'Shipping Address',
        type: 'display',
        visible: ko.observable(false),
        value: ko.observable('Select the shipping address, or create a new address for this customer.')
      };

      const addressIdField: FieldType<IdInfo> = {
        title: 'Select Address',
        type: 'dropdown',
        value: ko.observable(),
        visible: ko.observable(false),
        options: this.userAddressList,
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      let newAddress: any = null;

      const newAddressField: FieldType<string> = {
        title: ko.observable('New Address'),
        type: 'button',
        visible: ko.observable(false),
        iconName: 'icon/add-circle',
        value: ko.observable('Create New Customer Address'),
        action: () => {
          this.actions.createNewAddress(
            addressIdField,
            newAddress && newAddress.streetAddress,
            newAddress && newAddress.suburb,
            newAddress && newAddress.city,
            newAddress && newAddress.postCode,
            (model: any) => { newAddress = model; });
        }
      };

      addressIdField.value.subscribe((id) => {
        if (id == -1) {
          ko.isObservable(newAddressField.title) && newAddressField.title('Edit Address');
        } else {
          ko.isObservable(newAddressField.title) && newAddressField.title('New Address');
        }
      });

      ko.computed(() => {
        const shippingMethod: IdInfo & ShippingMethodResponse = this.shippingMethodList().filter(c => c.Id == shippingMethodIdField.value())[0];

        const addressDisplayFieldVisible = addressDisplayField.visible;
        const addressIdFieldVisible = addressIdField.visible;
        const newAddressFieldVisible = newAddressField.visible;

        if (addressDisplayFieldVisible) { addressDisplayFieldVisible(shippingMethod && shippingMethod.isFreightOption); }
        if (newAddressFieldVisible) { newAddressFieldVisible(shippingMethod && shippingMethod.isFreightOption); }
        if (addressIdFieldVisible) { addressIdFieldVisible(shippingMethod && this.userAddressList().length > 0 && shippingMethod.isFreightOption); }
      });

      dialog({
        title: 'Create Order',
        message: message,
        fields: ko.observableArray([userPinField, customerDisplayField, newCustomerField, customerIdField, paymentDisplayField, paymentMethodIdField, shippingMethodIdField, addressDisplayField, addressIdField, newAddressField]),
        submitText: 'Next',
        cancelText: 'Cancel',
        submitAction: () => {
          let errors = '';

          const userPin = userPinField.value();
          const customerId = customerIdField.value();
          const paymentMethodId = paymentMethodIdField.value();
          const shippingMethodId = shippingMethodIdField.value();


          if (userPin === '') {
            errors += 'User PIN is required for this action. <br />';
          }

          if (customerId == null) {
            errors += 'A customer is required for this order. <br />';
          }

          if (paymentMethodId == null) {
            errors += 'A payment method is required for this order. <br />';
          }

          if (shippingMethodId == null) {
            errors += 'A shipping method is required for this order. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);
            return;
          }

          webOrdersService.createOrder(
            userPin!,
            customerId,
            newCustomer && newCustomer.firstName,
            newCustomer && newCustomer.lastName,
            newCustomer && newCustomer.emailAddress,
            newCustomer && newCustomer.phoneNumber,
            newCustomer && newCustomer.phoneNumberTypeId,
            paymentMethodId,
            shippingMethodId,
            addressIdField.value(),
            newAddress && newAddress.streetAddress,
            newAddress && newAddress.suburb,
            newAddress && newAddress.city,
            newAddress && newAddress.postCode,
          ).then((result: any) => {
            if (result.success) {

              dialog(null);
              this.goto.orderDetails(result.webOrderId);

            } else {
              message(`<div class="error"><ul>${result.message}</ul></div>`);
            }
          });


        }
      });
    },

    createNewCustomer: (
      customerIdField: FieldType<IdInfo>,
      firstName: string,
      lastName: string,
      emailAddress: string,
      phoneNumber: string,
      phoneNumberTypeId: number,
      callback?: (model: any) => void): void => {

      const firstNameField: FieldType<string> = {
        title: 'First Name',
        type: 'text',
        value: ko.observable(firstName),
        setFocus: true
      };

      const lastNameField: FieldType<string> = {
        title: 'Last Name',
        type: 'text',
        value: ko.observable(lastName),
      };

      const emailAddressField: FieldType<string> = {
        title: 'Email address',
        type: 'text',
        value: ko.observable(emailAddress),
      };

      const phoneNumberField: FieldType<string> = {
        title: 'Phone Number',
        type: 'text',
        value: ko.observable(phoneNumber),
      };

      const phoneNumberTypeIdField: FieldType<IdInfo> = {
        title: 'Select phone number type',
        type: 'dropdown',
        value: ko.observable(phoneNumberTypeId),
        options: ko.observableArray([{ Id: 0, Name: 'Landline' }, { Id: 1, Name: 'Cellphone' }]),
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        optionsCaption: ko.observable('-- Select --')
      };


      const newDataMessage = ko.observable('');

      this.newDataDialog({
        dialog: this.newDataDialog,
        title: 'Create new customer',
        message: newDataMessage,
        fields: ko.observableArray([firstNameField, lastNameField, emailAddressField, phoneNumberField, phoneNumberTypeIdField]),
        submitText: 'Continue',
        cancelText: 'Cancel',
        cancelAction: () => {
          this.newDataDialog(null);
        },
        submitAction: () => {
          let errors = '';

          const firstName = firstNameField.value();
          const lastName = lastNameField.value();
          const emailAddress = emailAddressField.value();
          const phoneNumber = phoneNumberField.value();
          const phoneNumberTypeId = phoneNumberTypeIdField.value();

          if (firstName === null) {
            errors += 'First name is required. <br />';
          }

          if (lastName === null) {
            errors += 'Last name is required. <br />';
          }

          if (emailAddress === null) {
            errors += 'Email address is required. <br />';
          }

          if (phoneNumber !== null && phoneNumberTypeId == null) {
            errors += 'If you are adding a phone number, you must select the type of number it is. <br />';
          }

          if (errors !== '') {
            newDataMessage(`<div class="error"><ul>${errors}</ul></div>`);

            return null;
          }

          //TODO: Do a quick DB call to check that email address doesn't already exist.
          usersService.doesEmailExist((emailAddress as string))
            .then((result) => {
              if (result.exists) {
                newDataMessage(`<div class="error"><ul>Email address is already in use by ${result.name}</ul></div>`);

                return null;
              }
              else {
                this.customerList.splice(0, 0, { Id: -1, Name: `${firstName} ${lastName} - ${emailAddress}` });

                customerIdField.value(-1);

                this.newDataDialog(null);

                callback && callback({ firstName, lastName, emailAddress, phoneNumber, phoneNumberTypeId });
              }
            });
        }
      });
    },

    createNewAddress: (
      addressIdField: FieldType<IdInfo>,
      streetAddress: string,
      suburb: string,
      city: string,
      postCode: string,
      callback?: (model: any) => void): void => {

      const streetAddressField: FieldType<string> = {
        title: 'Street Number and Name',
        type: 'text',
        value: ko.observable(streetAddress),
        setFocus: true
      };

      const suburbField: FieldType<string> = {
        title: 'Suburb',
        type: 'text',
        value: ko.observable(suburb),
      };

      const cityField: FieldType<string> = {
        title: 'City',
        type: 'text',
        value: ko.observable(city),
      };

      const postCodeField: FieldType<string> = {
        title: 'Post Code',
        type: 'text',
        value: ko.observable(postCode),
      };

      const newDataMessage = ko.observable('');

      this.newDataDialog({
        dialog: this.newDataDialog,
        title: 'Add new customer address',
        message: newDataMessage,
        fields: ko.observableArray([streetAddressField, suburbField, cityField, postCodeField]),
        submitText: 'Continue',
        cancelText: 'Cancel',
        cancelAction: () => {
          this.newDataDialog(null);
        },
        submitAction: () => {
          let errors = '';

          const streetAddress = streetAddressField.value();
          const suburb = suburbField.value();
          const city = cityField.value();
          const postCode = postCodeField.value();

          if (streetAddress === null) {
            errors += 'Street name and number is required. <br />';
          }

          if (suburb === null) {
            errors += 'Suburb is required. <br />';
          }

          if (city === null) {
            errors += 'City is required. <br />';
          }

          if (errors !== '') {
            newDataMessage(`<div class="error"><ul>${errors}</ul></div>`);
            return null;
          }

          this.userAddressList.splice(0, 0, { Id: -1, Name: `${streetAddress}, ${suburb}` });

          addressIdField.value(-1);

          this.newDataDialog(null);

          callback && callback({ streetAddress, suburb, city, postCode });

        }
      });
    },

  };


}

export default {
  name: 'bp-weborders',
  viewModel: WebordersDashboard,
  template: require('./dashboard.html')
};