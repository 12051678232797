import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { batchesService } from '../../../api/service.batches';
import { productsService } from '../../../api/service.products';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatBatchNumber, formatDate, formatPrice, formatWeight } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';

class BatchesDashboard {
  readonly title: Observable<string>;
  readonly dialog: Observable<DialogParams | null>;

  readonly batches: ObservableArray<any>;
  readonly productList: ObservableArray<IdInfo>;

  constructor(params: any) {
    this.title = ko.observable('Batches / Recent Batches');
    this.dialog = ko.observable(null);

    this.batches = ko.observableArray();
    this.productList = ko.observableArray();

    this.loadBatches();

    if (this.productList().length == 0) {
      this.loadProducts();
    }
  }

  loadProducts = () => {
    productsService.getAll()
      .then(products => {
        this.productList(products.map(c => { return ({ ...c, Id: c.productId, Name: c.name }) }));
      });
  };

  loadBatches = () => {
    batchesService.getAll()
      .then(result => {
        const batchMap = result.map(b => {
          return {
            ...b,
            formattedTime: formatDate(b.time),
            formattedWeight: formatWeight(b.manufacturedWeight),
            formattedBatchNumber: formatBatchNumber(b.batchId)
          }
        });

        this.batches(batchMap);
      })
      .catch(e => console.log(e));
  };

  goto = {
    //batchDetails: (batchId: number): void => router.goto(routes.batchDetails.interpolate({ id: batchId })),
    batchCreate: (productId: number, quantity: number, userPin: string): void => router.goto(routes.batchCreate.interpolate({ id: productId, quantity: quantity, userPin: userPin })),
  };

  actions = {
    createBatch: (): void => {
      const message = ko.observable('');

      const model = {
        userPin: ko.observable(''),
        productId: ko.observable(0),
        quantity: ko.observable(0)
      };

      const userPinField: FieldType<string> = {
        title: 'User PIN',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      const productIdField: FieldType<IdInfo> = {
        title: 'Select Product',
        type: 'searchable-dropdown',
        value: model.productId,
        setFocus: true,
        filterText: ko.observable(''),
        options: this.productList,
        optionsText: (o) => o && o.Name,
        optionsValue: (o) => o && o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      const quantity: FieldType<string> = {
        title: 'Kgs Manufactured',
        type: 'number',
        value: model.quantity,
      };

      dialog({
        title: 'Create Batch',
        message: message,
        fields: ko.observableArray([userPinField, productIdField, quantity]),
        submitText: 'Submit',
        cancelText: 'Cancel',
        cancelAction: () => {
          dialog(null);
        },
        submitAction: () => {
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User PIN is required. <br />';
          }

          if (model.productId() === 0) {
            errors += 'A product is required for this batch <br />';
          }

          if (model.quantity() === 0) {
            errors += 'Quantity (Kgs) is required and must be greater than 0Kgs <br />';
          }

          if (errors !== '') {
            message(`< div class= "error" > <ul>${errors} </ul></div > `);

            return;
          }

          this.goto.batchCreate(model.productId(), model.quantity(), model.userPin());
          dialog(null);
        }
      });

    },

  };
}

export default {
  name: 'bp-batches',
  viewModel: BatchesDashboard,
  template: require('./dashboard.html')
};
