import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import router from '../../routing/router';
import routes from '../../routing/routes';
import { dialog } from '../app';
import { DialogParams, FieldType } from '../elements/bp-dialog';
import webordersCreateInvoice from '../views/weborders/create-invoice';
import weborders from '../views/weborders/dashboard';
import details from '../views/weborders/details';
import webordersInvoiceDetails from '../views/weborders/invoice-details';
import webordersInvoices from '../views/weborders/invoices';

class Weborders {
  readonly view: Observable<any>;
  readonly links: ObservableArray<any>;

  readonly viewType: Observable<string>;

  readonly dialog: Observable<DialogParams | null>;

  constructor(params: any) {
    this.view = ko.observable('');
    this.viewType = ko.observable('all');

    this.dialog = ko.observable(null);

    switch (params.view) {
      case weborders.name:
        console.log('weborder.name', params);
        this.view({ name: details.name, params: params });
        break;

      case webordersCreateInvoice.name:
        this.view({ name: webordersCreateInvoice.name, params: params });
        break;

      case webordersInvoices.name:
        this.view({ name: webordersInvoices.name, params: params });
        break;

      case webordersInvoiceDetails.name:
        this.view({ name: webordersInvoiceDetails.name, params: params });
        break;

      default:
        this.view({ name: weborders.name, params: params }); break;
    }

    this.links = ko.observableArray();

    //this.links.push({
    //  action: () => {
    //    history.pushState({}, '', routes.webordersDashboard.interpolate({ type: 'all' }));
    //    this.view({ name: weborders.name, params: params });
    //  },
    //  label: 'Open Orders',
    //  icon: 'icon/servers-secure',
    //  description: 'View all open orders'
    //});

    this.links.push({
      action: () => { this.viewType('all'); router.goto(routes.webordersDashboard.interpolate({ type: this.viewType() })) },
      label: 'Open Orders',
      icon: 'icon/servers-secure',
      description: 'View all open orders'
    });

    this.links.push({
      action: () => { this.viewType('pickup'); router.goto(routes.webordersDashboard.interpolate({ type: this.viewType() })) },
      label: 'Today\'s Pickups',
      icon: 'icon/servers-secure',
      description: 'View orders for pickup today'
    });

    this.links.push({
      action: () => { this.viewType('freighted'); router.goto(routes.webordersDashboard.interpolate({ type: this.viewType() })) },
      label: 'Freight Orders',
      icon: 'icon/servers-secure',
      description: 'View orders for freighting'
    });

    this.links.push({
      action: () => this.actions.searchDialog(),
      label: 'Search Orders',
      icon: 'icon/servers-secure',
      description: 'Search for order'
    });

  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({})),
  };

  actions = {
    searchDialog: () => {
      const nameField: FieldType<string> = {
        title: 'Customer Name',
        type: 'text',
        value: ko.observable<string>(''),
        setFocus: true
      };

      dialog({
        title: 'Search for orders',
        message: ko.observable(''),
        fields: ko.observableArray([nameField]),
        submitText: 'Search',
        cancelText: 'Cancel',
        submitAction: () => {
          // Do the search.
          dialog(null);

          router.goto(routes.webordersDashboardSearch.interpolate({ type: 'search', terms: 'q=' + nameField.value() }));
        }
      });
    }
  }

}
export default {
  name: 'section-weborders',
  viewModel: Weborders,
  template: require('./weborders.html')
};
