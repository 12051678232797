import * as ko from 'knockout';
import { Observable, PureComputed } from 'knockout';
import { breakpoint } from '..';
import router from '../routing/router';
import { DialogParams } from './elements/bp-dialog';
import { QuickDialogParams } from './elements/bp-quick-dialog';

export const component: Observable = ko.observable();
export const tabHidden: Observable<boolean> = ko.observable(false);
export const dialog: Observable<DialogParams | null | undefined> = ko.observable(null);
export const quickDialog: Observable<QuickDialogParams | null | undefined> = ko.observable(null);

class App {
  readonly component: Observable<any>;
  readonly breakpoint: Observable<any>;
  readonly dialog: Observable<DialogParams | null | undefined>;
  readonly quickDialog: Observable<QuickDialogParams | null | undefined>;
  readonly dialogComponent: PureComputed<{ name: string, params?: any }>;
  readonly quickDialogComponent: PureComputed<{ name: string, params?: any }>;

  constructor() {
    this.component = component;
    this.breakpoint = breakpoint;
    this.dialog = dialog;
    this.quickDialog = quickDialog;

    this.dialogComponent = ko.pureComputed(() => {
      const dialogConst = this.dialog();
      if (!dialogConst) {
        return ({ name: 'bp-null' });
      }

      return ({
        name: 'bp-dialog',
        params:
        {
          title: dialogConst.title,
          message: dialogConst.message,
          fields: dialogConst.fields,
          cancelText: dialogConst.cancelText,
          submitText: dialogConst.submitText,
          submitAction: () => dialogConst.submitAction(),
          cancelAction: () => dialogConst.cancelAction ? dialogConst.cancelAction() : this.dialog(null)
        }
      });
    });

    this.quickDialogComponent = ko.pureComputed(() => {
      const quickDialogConst = this.quickDialog();
      if (!quickDialogConst) {
        return ({ name: 'bp-null' });
      }

      return ({
        name: 'bp-quick-dialog',
        params:
        {
          title: quickDialogConst.title,
          message: quickDialogConst.message,
          fields: quickDialogConst.fields,
        }
      });
    });

    this.attachResizeHandler();
    this.attachBackgroundTabHandler();

    router.goto(window.location.pathname + window.location.search, false);
  }

  

  private attachBackgroundTabHandler(): void {
    if (typeof window.document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      window.document.addEventListener('visibilitychange', () => tabHidden(window.document.hidden), false);
    } else if (typeof (document as any).msHidden !== 'undefined') {
      window.document.addEventListener('msvisibilitychange', () => tabHidden((window as any).document['msHidden']), false);
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      window.document.addEventListener('webkitvisibilitychange', () => tabHidden((window as any).document['webkitHidden']), false);
    }
  }

  private attachResizeHandler(): void {
    const addEvent = (element: any, eventHandler: any) => {
      if (element == null) { return; }

      if (element.addEventListener) {
        element.addEventListener(`resize`, eventHandler, false);
      } else if (element.attachEvent) {
        element.attachEvent(`onresize`, eventHandler);
      } else {
        element[`onresize`] = eventHandler;
      }
    };

    addEvent(window, () => this.updateBreakpoint());

    this.updateBreakpoint();
  }

  private updateBreakpoint(): void {
    this.breakpoint(this.getBreakpoint(window.document.documentElement.clientWidth));
  }

  private getBreakpoint(width: number): string {
    if (width <= 640) { return 'mobile m1'; }

    if (width <= 960) { return 'mobile m2'; }

    if (width <= 1400) { return 'tablet'; }

    return 'desktop';
  }
}

export default {
  name: 'app',
  viewModel: App,
  template: require('./app.html'),
  checkBeforeNavigation: ko.observable(false)
}
