import * as ko from 'knockout';
import bpFooter from './bp-footer';
import bpHeader from './bp-header';
import bpSidebar from './bp-sidebar';

export default function () {
  ko.components.register(bpFooter.name, bpFooter);
  ko.components.register(bpHeader.name, bpHeader);
  ko.components.register(bpSidebar.name, bpSidebar);
}
