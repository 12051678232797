import * as ko from 'knockout';
import { Observable } from 'knockout';

class BpLink {
  action: () => void;
  label: string;
  class?: string;
  enable: Observable<boolean>;
  iconName: string;

  constructor(params: any) {
    this.iconName = 'icon/chevron-right';

    this.action = params.action;
    this.label = params.label;
    this.class = params.class;

    this.enable = ko.isObservable(params.enable)
      ? params.enable : ko.observable(true);
  }
}

export default {
  name: 'bp-link',
  viewModel: BpLink,
  template: require('./bp-link.html')
};
