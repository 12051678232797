import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { fetchHandler, ApiResponse } from './fetchHandler';

export const usersService = {
  getAll,
  getAllAdmins,
  getAllCashiers,
  getById,
  getByIdPhoneNumbers,
  getByIdAddresses,
  getActions,
  isPinValid,
  create,
  doesEmailExist,
  getUserTimesheets,
  setUserTimesheet,
  updateUserTimesheet,
  deleteUserTimesheet,
  resetUserPassword
};

type UserResponse = {
  userId: number;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isAdmin: boolean;
  hasAccount: boolean;
  isCashier: boolean;
  isCashRegister: boolean;
  lastLoginTime: string;
  lastTransactionTime: string;
  emailAddress: string;
}

type UserPhoneNumbersResponse = {
  userPhoneNumberId: number;
  phoneNumberType: string;
  phoneNumberTypeId: number;
  phoneNumber: string;
};

type AddressResponse = {
  userAddressId: number;
  streetAddress: string;
  suburb: string;
  city: string;
  postCode: number;
  isDefault: boolean;
};

type UserDetailsResponse = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  hasAccount: boolean;
  accountLocked: boolean;
  isActive: boolean;
  isAdmin: boolean;
  isCashier: boolean;
  isCashRegister: boolean;
  lastLoginTime: string;
  lastTransactionTime: string;
  loyaltyPointsGained: number;
  emailReceipts: boolean;
  emailGeneral: boolean;
  userPin: number;
  createdTime: number;
}

type UserActionResponse = {
  time: number;
  action: string;
  actionedBy: string;
  posTransactionId: number | null;
  webOrderId: number | null;
}

function isPinValid(userPin: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/user/isPinValid`, authPostDataRequest({ userPin }))
    .then((results) => { return results; });
}

function doesEmailExist(emailAddress: string): Promise<{ exists: boolean, name: string }> {
  return fetchHandler(`${config.apiUrl}/user/doesEmailExist`, authPostDataRequest({ emailAddress }))
    .then((result) => { return result; });
}

function getAll(): Promise<UserResponse[]> {
  return fetchHandler(`${config.apiUrl}/user/getUsers`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getAllAdmins(): Promise<UserResponse[]> {
  return fetchHandler(`${config.apiUrl}/user/getUsers`, authPostDataRequest({ Type: 'Admins' }))
    .then((results) => { return results; });
}

function getAllCashiers(): Promise<UserResponse[]> {
  return fetchHandler(`${config.apiUrl}/user/getUsers`, authPostDataRequest({ Type: 'Cashiers' }))
    .then((results) => { return results; });
}

function getById(userId: number): Promise<UserDetailsResponse> {
  return fetchHandler(`${config.apiUrl}/user/details`, authPostDataRequest({ userId }))
    .then(result => { return result; });
}

function getByIdPhoneNumbers(userId: number): Promise<UserPhoneNumbersResponse[]> {
  return fetchHandler(`${config.apiUrl}/user/getPhoneNumbers`, authPostDataRequest({ userId }))
    .then(result => { return result; });
}

function getByIdAddresses(userId: number): Promise<AddressResponse[]> {
  return fetchHandler(`${config.apiUrl}/user/getAddresses`, authPostDataRequest({ userId }))
    .then(result => { return result; });
}

function getActions(userId: number): Promise<UserActionResponse[]> {
  return fetchHandler(`${config.apiUrl}/user/getActions`, authPostDataRequest({ userId, limit: 10 }))
    .then(result => { return result; });
}

function create(firstName: string, lastName: string, emailAddress: string, phoneNumber: string, isAdmin: boolean, isCashier: boolean,
  userPin: string, tilUserPin: string, emailReceipts: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/user/create`, authPostDataRequest({
    firstName, lastName, emailAddress, phoneNumber,
    tilUserPin, isAdmin, isCashier, isCashRegister: false, userPin, emailReceipts, createAccount: false
  }))
    .then(result => { return result; });
}

function resetUserPassword(userId: number, password: string, confirmPassword: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/user/setUserPassword`, authPostDataRequest({ userId, password, confirmPassword }))
    .then(result => { return result; });
}

type TimesheetResponse = {
  userId: number;
  userName: string;
  periods: TimesheetPeriod[];
}

type TimesheetPeriod = {
  periodStart: string;
  periodEnd: string;
  processedTime: string;
  totalHours: number;
  entries: TimesheetEntry[];
}

type TimesheetEntry = {
  timesheetId: number;
  dayOfWeek: string;
  startTime: string;
  endTime: string;
  notes: string;
  isPublicHoliday: boolean;
  totalHours: number;
}

function getUserTimesheets(userPin: string, today: string): Promise<TimesheetResponse> {
  return fetchHandler(`${config.apiUrl}/timesheet/getForUser`, authPostDataRequest({ userPin, today }))
    .then(result => { return result; });
}

function setUserTimesheet(userPin: string, startTime: string, endTime: string, isPublicHoliday: boolean, notes: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/timesheet/setForUser`, authPostDataRequest({ userPin, startTime, endTime, isPublicHoliday, notes }))
    .then(result => { return result; });
}

function updateUserTimesheet(userPin: string, timesheetId: number, startTime: string, endTime: string, notes: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/timesheet/update`, authPostDataRequest({ userPin, timesheetId, startTime, endTime, notes }))
    .then(result => { return result; });
}

function deleteUserTimesheet(userPin: string, timesheetId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/timesheet/delete`, authPostDataRequest({ userPin, timesheetId }))
    .then(result => { return result; });
}
