import registerAdmin from './admin/_index';
import registerAuth from './auth/_index';
import registerBatches from './batches/_index';
import registerError from './error/_index';
import registerMain from './main/_index';
import registerShipments from './shipments/_index';
import registerWeborders from './weborders/_index';

export default function () {
  registerAdmin();
  registerAuth();
  registerBatches();
  registerError();
  registerMain();
  registerShipments();
  registerWeborders();
}
