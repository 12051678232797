import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { fetchHandler } from './fetchHandler';

export const idInfosService = {
  getPaymentMethods,
  getShippingMethods,
  getFreightProviders,
  getProducts,
};

type GetIdInfoResponse = {
  id: number;
  name: string;
  isActive: boolean;
}

function getPaymentMethods(): Promise<GetIdInfoResponse[]> {
  return fetchHandler(`${config.apiUrl}/idInfo/paymentMethod`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getShippingMethods(): Promise<GetIdInfoResponse[]> {
  return fetchHandler(`${config.apiUrl}/idInfo/shippingMethod`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getFreightProviders(): Promise<GetIdInfoResponse[]> {
  return fetchHandler(`${config.apiUrl}/idInfo/freightProvider`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getProducts(): Promise<GetIdInfoResponse[]> {
  return fetchHandler(`${config.apiUrl}/idInfo/product`, authPostDataRequest({}))
    .then((results) => { return results; });
}
