import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatPrice, formatWeight } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

type IdInfo = {
  Id: number | null;
  Name: string;
}

class AdminProducts {
  title: Observable<string>;
  products: ObservableArray<any>;
  allProducts: ObservableArray<any>;
  categoryList: ObservableArray<IdInfo>;
  groupingList: ObservableArray<IdInfo>;
  selectedGroupId = ko.observable(0);

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;
  readonly storedValueName = 'SelectedCategoryId';

  constructor(params: any) {

    this.title = ko.observable('Admin / All Products');
    this.products = ko.observableArray();
    this.allProducts = ko.observableArray();
    this.categoryList = ko.observableArray();
    this.groupingList = ko.observableArray();

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        dialog(null);
      }
    });

    this.loadProducts();

    this.selectedGroupId.subscribe(() => {

      localStorage.setItem(this.storedValueName, this.selectedGroupId().toString());

      if (this.selectedGroupId() > 0) {
        this.products(this.allProducts().sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
          .filter(p => p.categoryId == this.selectedGroupId()));
      }
      else {
        this.products(this.allProducts().sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
      }
    });

    productsService
      .getProductCategories(true)
      .then(categories => {

        this.categoryList(categories.map(c => { return ({ Id: c.productCategoryId, Name: c.name }) }));
      })
      .catch(e => console.log(e));

    productsService
      .getProductGroupings()
      .then(groupings => {

        this.groupingList(groupings.map(c => { return ({ Id: c.productGroupId, Name: c.name }) }));

      })
      .catch(e => console.log(e));
  }

  loadProducts = () => {
    productsService
      .getAll()
      .then(products => this.updateProducts(products))
      .catch(e => console.log(e));
  }

  updateProducts = (products: any[]) => {

    const productsMap = products.map(a => {
      const icon = a.isActive ? 'icon/circle-tick' : 'icon/cancel-circle';

      return ({
        ...a,
        icon: icon,
        iconclass: a.isActive ? 'verify-icon' : 'cancel-icon',
        formattedUnitWeight: formatWeight(a.unitWeight),
        formattedPrice: formatPrice(a.retailRRP),
        formattedPricePerKg: a.unitWeight > 1 ? `${formatPrice(((a.retailRRP) / (a.unitWeight)))}/Kg` : '',
        formattedManufactureCost: formatPrice(a.totalManufactureCost),
        formattedMargin: a.totalMargin.toFixed(0) + '%',
        formattedIdealRRP: formatPrice(a.idealRRP),
        formattedBooleans: `${a.isLoyaltyItem ? 'L' : '-'} ${a.isBulk ? 'B' : '-'} ${a.isWebsite ? 'W' : '-'} ${a.applyWastage ? 'S' : '-'}`,
        formattedSalePrice: `On Special: ${formatPrice(a.salePrice)}`,
        isOnSale: a.salePrice > 0
      });
    });

    this.products(productsMap.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
    this.allProducts(productsMap.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));

    if (localStorage.getItem(this.storedValueName) === null) {
      localStorage.setItem(this.storedValueName, this.selectedGroupId().toString());
    } else {
      const storedValue = localStorage.getItem(this.storedValueName);
      this.selectedGroupId(Number(storedValue));
    }
  };

  addProduct = () => {

    const origMessage = 'Add product';
    const message = ko.observable(origMessage);

    const model = {
      name: ko.observable(''),
      unitWeight: ko.observable(0),
      retailRRP: ko.observable(0),
      categoryId: ko.observable(),
      groupingId: ko.observable(),
      isRetail: ko.observable(true),
      isWebsite: ko.observable(true),
      isLoyaltyItem: ko.observable(false),
      isBulk: ko.observable(false),
      applyWastage: ko.observable(true),
      wastageCalc: ko.observable(4),
      criticalLevelMin: ko.observable(20),
      warmingLevelMin: ko.observable(35),
      packagingDetails: ko.observable(''),
      description: ko.observable(''),
      isCat: ko.observable(false),
      isDog: ko.observable(true)
    }

    ko.pureComputed(() => model.applyWastage() ? model.wastageCalc(4) : model.wastageCalc(0));

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const unitWeightField: FieldType<string> = {
      title: 'Unit Weight (Kgs)',
      type: 'number',
      value: model.unitWeight,
    };

    const retailRRPField: FieldType<string> = {
      title: 'Retail Price',
      type: 'price',
      value: model.retailRRP,
    };

    const categoryIdField: FieldType<IdInfo> = {
      title: 'Sale Category',
      type: 'dropdown',
      value: model.categoryId,
      options: this.categoryList(),
      optionsText: (o: any) => o && o.Name,
      optionsValue: (o: any) => o && o.Id,
      optionsCaption: ko.observable('-- Select --')
    };

    const groupingIdField: FieldType<IdInfo> = {
      title: '"The Board" Grouping',
      type: 'dropdown',
      value: model.groupingId,
      options: this.groupingList(),
      optionsText: (o: any) => o && o.Name,
      optionsValue: (o: any) => o && o.Id,
      optionsCaption: ko.observable('-- Select --')
    }

    const isRetailField: FieldType<boolean> = {
      title: 'Retail Product (Allow sales through the till)?',
      type: 'checkbox',
      value: model.isRetail
    };

    const isWebsiteField: FieldType<boolean> = {
      title: 'Website Product (Allow sales through the website)?',
      type: 'checkbox',
      value: model.isWebsite
    };

    const isLoyaltyItemField: FieldType<boolean> = {
      title: 'Loyalty Item (Can customer earns points on this item)?',
      type: 'checkbox',
      value: model.isLoyaltyItem
    };

    const isBulkField: FieldType<boolean> = {
      title: 'Bulk Product (Customer must NOT earn loyalty points on this item)?',
      type: 'checkbox',
      value: model.isBulk
    };

    const isCatField: FieldType<boolean> = {
      title: 'Is suitable for cats?',
      type: 'checkbox',
      value: model.isCat
    };

    const isDogField: FieldType<boolean> = {
      title: 'Is suitable for dogs?',
      type: 'checkbox',
      value: model.isDog
    };

    const criticalLevelField: FieldType<string> = {
      title: 'Minimum stock level before becoming critical',
      type: 'number',
      value: model.criticalLevelMin,
    };

    const warningLevelField: FieldType<string> = {
      title: 'Minimum stock level before warning that stock is low',
      type: 'number',
      value: model.warmingLevelMin,
    };

    const applyWastageField: FieldType<boolean> = {
      title: 'Apply a wastage calculation to this product',
      type: 'checkbox',
      value: model.applyWastage
    };

    const wastageCalcField: FieldType<string> = {
      title: 'Wastage Calculation (percentage of product wasted due to bandsawing and/or mincing) ',
      type: 'number',
      value: model.wastageCalc,
    };

    const packagingDetailsField: FieldType<string> = {
      title: 'Packaging Details (eg: frozen in portion sized blocks, flat packed in 1Kg bags)',
      type: 'text',
      value: model.packagingDetails,
    };

    const dsecriptionField: FieldType<string> = {
      title: 'Product Description',
      type: 'textarea',
      value: model.description,
    };

    dialog({
      title: `Create product`,
      message: message,
      fields: ko.observableArray([nameField, categoryIdField, groupingIdField, dsecriptionField, packagingDetailsField, unitWeightField, retailRRPField, isCatField, isDogField, isRetailField, isWebsiteField, isLoyaltyItemField, isBulkField, criticalLevelField, warningLevelField, applyWastageField, wastageCalcField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty \r\n';
        }

        if (model.unitWeight() == null) {
          errors += 'Weight cannot be empty \r\n';
        }

        if (model.unitWeight() == NaN) {
          errors += `Weight can only contain numeric characters and a single \r\n`;
        }

        if (model.retailRRP() == null) {
          errors += 'Purchase price cannot be empty \r\n';
        }

        if (model.categoryId() == null) {
          errors += 'Sale category cannot be empty \r\n';
        }

        if (model.isBulk() && model.isLoyaltyItem()) {
          errors += 'You cannot set loyalty on a bulk product \r\n';
        }

        if (model.criticalLevelMin() > model.warmingLevelMin()) {
          errors += 'You may have the critical and warning levels the wrong way around.  The warning should happen on the higher value while the critical activates when there is less stock. \r\n';
        }

        if (model.applyWastage() && model.wastageCalc() == 0) {
          errors += 'You have specified that wastage is to be calculated, so you must provide a percentage for the calculation.  Standard wastage percentage is 4%';
        }

        if ((model.isWebsite() || model.isRetail()) && model.packagingDetails() == null) {
          errors += 'You must specify details on how this product is packed';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"> - ${errors} .</div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        productsService.createProduct(model.categoryId(), model.groupingId(), model.name(), model.isRetail(), model.isWebsite(),
          model.isLoyaltyItem(), model.isBulk(), model.applyWastage(), model.wastageCalc(), model.unitWeight(), model.retailRRP(),
          model.criticalLevelMin(), model.warmingLevelMin(), model.packagingDetails(), model.description(), model.isCat(), model.isDog())
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Product created.');

              this.loadProducts();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to create product.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  selectGrouping = (groupingId: number) => {
    this.selectedGroupId(groupingId);
  }

  goto = {
    productDetails: (id: number): void => router.goto(routes.adminProductDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-products',
  viewModel: AdminProducts,
  template: require('./products.html')
};
