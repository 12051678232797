import * as ko from 'knockout';
import { Observable } from 'knockout';
import { accountsService } from '../../../api/service.accounts';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { localFromUtc } from '../../../utils/datetime';

class AdminAccounts {
  title: Observable<string>;
  accounts: ko.ObservableArray<any>;

  constructor(params: any) {
    this.title = ko.observable('Admin / All User Accounts');
    this.accounts = ko.observableArray();

    accountsService
      .getAll()
      .then(accounts => this.updateAccounts(accounts))
      .catch(e => console.log(e));
  }

  updateAccounts = (accounts: any[]) => {
    const accountsMap = accounts.map(a => {
      const created = localFromUtc(a.createdUtc);

      const icon = a.isVerified ? 'icon/circle-tick' : '';

      return ({ ...a, created: created, icon: icon });
    });
    this.accounts(accountsMap);
  };

  goto = {
    accountDetails: (id: number): void => router.goto(routes.adminAccountDetails.interpolate({ id: id }))
  }
}

export default {
  name: 'bp-admin-accounts',
  viewModel: AdminAccounts,
  template: require('./accounts.html')
};
