import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webOrdersService } from '../../../api/service.weborders';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatTime } from '../../../utils/format';
import app from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class WebOrdersInvoiceDetails {
  readonly title: ko.PureComputed<string>;
  readonly webOrderId = ko.observable<number>();
  readonly webOrderInvoiceId: Observable<number>;

  readonly dialog: Observable<DialogParams | null>;

  readonly emailMessage = ko.observable<string>();
  readonly success = ko.observable<string>();
  readonly recipientEmailAddress = ko.observable<string>();
  readonly lastAttemptTime = ko.observable<string>();

  constructor(params: any) {
    app.checkBeforeNavigation(false);

    this.webOrderInvoiceId = ko.observable(params.id);

    this.title = ko.pureComputed(() => 'Latest Invoice for Order #' + this.webOrderId());

    this.dialog = ko.observable(null);

    webOrdersService.getInvoice(this.webOrderInvoiceId())
      .then(invoice => {
        this.webOrderId(invoice.webOrderId);
        this.emailMessage(invoice.fullMessageBody);
        this.success(invoice.succeeded ? 'Succeeded' : 'Failed');
        this.recipientEmailAddress(invoice.recipientEmailAddress);
        this.lastAttemptTime(formatDate(invoice.lastAttemptTime) + ' ' + formatTime(invoice.lastAttemptTime));
      });
  }

  goto = {
    //  resendInvoice: (): void => {
    //    const origMessage = '<span style="color: red;">Creating a new invoice will void all previous invoices and reverse any loyalty credits that have been applied.  Are you sure you want to do this?</span>';
    //    const message = ko.observable(origMessage);

    //    const model = {
    //      userPin: ko.observable<string>(''),
    //      sendEmail: ko.observable<boolean>(false)
    //    };

    //    const userPinField: FieldType = {
    //      title: 'User Pin',
    //      type: 'userPin',
    //      value: model.userPin,
    //      setFocus: true
    //    };

    //    const emailField: FieldType = {
    //      title: 'Send cancelled invoice to customer',
    //      type: 'checkbox',
    //      value: model.sendEmail
    //    }

    //    dialog({
    //      title: `Please confirm this action.`,
    //      message: message,
    //      fields: ko.observableArray<FieldType>([userPinField, emailField]),
    //      cancelText: 'Cancel',
    //      submitText: 'Confirm',
    //      submitAction: () => {
    //        let errors = '';

    //        //Check fields
    //        if (model.userPin() === '') {
    //          errors += '<br />User  PIN cannot be empty. <br />';
    //        }

    //        if (errors !== '') {
    //          message(`${origMessage}<br /> <div class="error"><ul>${errors}</ul></div>`);

    //          return;
    //        }

    //        webOrdersService.voidInvoices(this.webOrderId(), model.userPin()!, model.sendEmail())
    //          .then(result => {
    //            if (result.success) {

    //              return router.goto(routes.webordersCreateInvoice.interpolate({ id: this.webOrderId() }));
    //            } else {
    //              message(`${origMessage} <br /><br><div class="error"> - ${result.message}.</div>`);
    //            }
    //          });

    //      }
    //    });
    //  },
  }
}

export default {
  name: 'bp-weborders-invoice-detailss',
  viewModel: WebOrdersInvoiceDetails,
  template: require('./invoice-details.html')
};
