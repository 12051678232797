import * as ko from 'knockout';
import { Observable } from 'knockout';

class AdminDashboard {
  content: Observable<string>;

  constructor(params: any) {
    this.content = ko.observable('Admin dashboard');
  }
}

export default {
  name: 'bp-admin',
  viewModel: AdminDashboard,
  template: require('./dashboard.html')
};
