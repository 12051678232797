import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import router from '../../routing/router';
import routes from '../../routing/routes';
import supplierPurchaseOrder from '../views/shipments/supplier-purchase-order';
import createPurchaseOrder from '../views/shipments/create-purchase-order';
import details from '../views/shipments/details';
import receive from '../views/shipments/receive';
import { DialogParams, FieldType, IdInfo } from '../elements/bp-dialog';
import { suppliersService } from '../../api/service.suppliers';
import { shipmentsService } from '../../api/service.shipments';

class Shipments {
  readonly view: Observable<any>;
  readonly links: ObservableArray<any>;
  readonly dialog: Observable<DialogParams | null>;
  readonly suppliers: ObservableArray;
  readonly supplierInvoiceId: Observable<number>;

  constructor(params: any) {
    this.view = ko.observable();
    this.dialog = ko.observable(null);
    this.suppliers = ko.observableArray();
    this.supplierInvoiceId = ko.observable(0);

    switch (params.view) {

      case supplierPurchaseOrder.name: this.view({ name: supplierPurchaseOrder.name, params: params }); break;
      case createPurchaseOrder.name: this.view({ name: createPurchaseOrder.name, params: params }); break;
      case details.name: this.view({ name: details.name, params: params }); break;
      case receive.name: this.view({ name: receive.name, params: params }); break;

      default: this.view('bp-shipments'); break;
    }

    this.links = ko.observableArray(params.links);
    this.links.push({ action: this.goto.supplierPurchaseOrder, label: 'Create Purchase Order', icon: 'icon/supplier', description: 'Order products from supplier' });
    this.links.push({ action: this.actions.createShipment, label: 'Create Shipment', icon: 'icon/location', description: 'Receive without P/O' });
  }

  loadSuppliers() {
    if (this.suppliers().length > 0) {
      return;
    }

    suppliersService.getAll()
      .then(results => {
        this.suppliers(results.map(r => ({ Id: r.supplierId, Name: r.name })));
      });
    return;
  }

  actions = {
    createShipment: () => {

      const initMessage = 'Enter invoice details from supplier.';
      const message = ko.observable('');
      let errors = '';

      this.loadSuppliers();

      const supplierIdField: FieldType<IdInfo> = {
        title: 'Supplier',
        type: 'dropdown',
        value: ko.observable(0),
        options: this.suppliers,
        optionsText: (o: any) => o.Name,
        optionsValue: (o: any) => o.Id,
        optionsCaption: ko.observable('Select')
      };

      const invoiceNumberField: FieldType<string> = {
        title: 'Invoice Number',
        type: 'text',
        value: ko.observable('')
      };

      this.dialog({
        title: 'Create manual shipment',
        message: message,
        cancelText: 'Cancel',
        cancelAction: () => this.dialog(null),
        fields: ko.observableArray([supplierIdField, invoiceNumberField]),
        submitText: 'Next',
        submitAction: () => {

          // Create supplierInvoice and return supplierInvoiceId

          const supplierId = supplierIdField.value() as number;
          const invoiceNumber = invoiceNumberField.value() as string;

          if (supplierId == 0) {
            errors += `You must select a supplier <br />`;
          }

          if (invoiceNumber == '') {
            errors += `You must enter the invoice number for this order <br />`;
          }

          if (errors !== '') {
            message('<br />' + initMessage + '<span style="color: red;"><br />' + errors + '</span>');
            return;
          }

          suppliersService.createSupplierInvoice(supplierId, invoiceNumber)
            .then(result => {

              if (!result) {
                message('<br />' + initMessage + '<span style="color: red;"><br />Unable to create invoice.</span>');
                return;
              }

              router.goto(routes.shipmentsReceive.interpolate({ supplierInvoiceId: result, invoiceNumber }));
              this.dialog(null);
            });
        }
      });

    }
  }

  goto = {
    supplierPurchaseOrder: (): void => router.goto(routes.shipmentsSupplierPurchaseOrder.interpolate({})),
    receiveProduct: (): void => router.goto(routes.shipmentsReceive.interpolate({})),
    createManualShipment: (): void => { }
  }
}

export default {
  name: 'section-shipments',
  viewModel: Shipments,
  template: require('./shipments.html')
};
