import * as ko from 'knockout';
import { Observable } from 'knockout';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class ShipmentsSupplierPurchaseOrder {
  title: Observable<string>;
  activeSuppliers: ko.ObservableArray<any>;

  constructor(params: any) {
    this.title = ko.observable('Shipments / Create Purchase Order');

    this.activeSuppliers = ko.observableArray();

    this.loadSuppliers();
  }

  loadSuppliers = () => {
    suppliersService
      .getActive()
      .then(suppliers => {
        const supplierMap = suppliers.map(s => {
          return {
            ...s,

          }
        });

        this.activeSuppliers(supplierMap);
      })
      .catch(e => console.log(e));
  }

  createPurchaseOrder = (id: number): void => router.goto(routes.shipmentsCreatePurchaseOrder.interpolate({ id: id }));

}

export default {
  name: 'bp-shipments-supplier-purchase-order',
  viewModel: ShipmentsSupplierPurchaseOrder,
  template: require('./supplier-purchase-order.html')
};
