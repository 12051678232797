import * as ko from 'knockout';
import { Observable, Computed } from 'knockout';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class AdminCreateSupplier {
  title: Observable<string>;

  name: Observable<string>;
  orderEmailAddress: Observable<string>;
  contactName: Observable<string>;
  contactPhoneNumber: Observable<string>;
  orderNotes: Observable<string>;

  errors: {
    response: Observable<string>;
    name: Observable<string>;
    orderEmailAddress: Observable<string>;
    contactName: Observable<string>;
    hasResponse: Computed<boolean>;
    hasName: Computed<boolean>;
    hasEmailAddress: Computed<boolean>;
    hasContactName: Computed<boolean>;
    failedValidation: Computed<boolean>;
  };

  constructor(params: any) {
    this.title = ko.observable('Admin create supplier');

    this.name = ko.observable('');
    this.orderEmailAddress = ko.observable('');
    this.contactName = ko.observable('');
    this.contactPhoneNumber = ko.observable('');
    this.orderNotes = ko.observable('');

    this.errors = {
      response: ko.observable(''),
      name: ko.observable(''),
      orderEmailAddress: ko.observable(''),
      contactName: ko.observable(''),
      hasResponse: ko.pureComputed(() => this.errors.response().length > 0),
      hasName: ko.pureComputed(() => this.errors.name().length > 0),
      hasEmailAddress: ko.pureComputed(() => this.errors.orderEmailAddress().length > 0),
      hasContactName: ko.pureComputed(() => this.errors.contactName().length > 0),
      failedValidation: ko.pureComputed(() => this.errors.hasName()
        || this.errors.hasEmailAddress() || this.errors.hasContactName())
    };
  }

  actions = {
    createSupplier: (): void => {
      this.errors.name(this.name() ? '' : 'Supplier name is required.');

      this.errors.orderEmailAddress(this.orderEmailAddress() ? '' : 'Order Email Address is required.');

      this.errors.contactName(this.contactName() ? '' : 'Contact name is required.');

      if (this.errors.failedValidation()) {
        return;
      }

      suppliersService.createSupplier(this.name(), this.orderEmailAddress(), this.contactName(), this.contactPhoneNumber(), this.orderNotes())
        .then((supplier) => {
          this.errors.response('');

          if (supplier.supplierId) {
            this.goto.supplierDetails(supplier.supplierId);
          }
        })
        .catch((e) => {
          this.errors.response(`Create supplier failed. ${e.message}`);
        });
    }
  }

  goto = {
    cancel: (): void => router.goto(routes.adminSuppliers.interpolate({})),
    supplierDetails: (id: number): void => router.goto(routes.adminSupplierDetails.interpolate({ id: id }))
  }
}

export default {
  name: 'bp-admin-supplier-create',
  viewModel: AdminCreateSupplier,
  template: require('./create-supplier.html')
};
