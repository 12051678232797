import * as ko from 'knockout';
import { Observable } from 'knockout';
import { reportService } from '../../../api/service.reports';


class AdminAdhocEmail {
  title: Observable<string>;
  emailRecipients = ko.observable<string>('chriss@silverspider.net.nz, uglymagnolia@gmail.com, shane@gourmetpetfood.co.nz');
  emailWtihCreditRecipients = ko.observable<string>('chriss@silverspider.net.nz, uglymagnolia@gmail.com, shane@gourmetpetfood.co.nz');

  constructor(params: any) {
    this.title = ko.observable('Admin / Send Adhoc Email to Customers');
  }

  sendEmails = () => {
    // Send to server.

    reportService.sendAdhocEmail(this.emailRecipients()!, this.emailWtihCreditRecipients()!)
      .then(result => {
        if (result.success) {
          alert('Emails have been scheduled.');
        }
      }).finally(() => {
        this.emailRecipients('');
        this.emailWtihCreditRecipients('');
      });
  }

}

export default {
  name: 'bp-admin-adhoc-email',
  viewModel: AdminAdhocEmail,
  template: require('./adhoc-email.html')
};
