import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { suppliersService } from '../../api/service.suppliers';
import router from '../../routing/router';
import routes from '../../routing/routes';
//import supplier from '../views/admin/supplier';
//import suppliersCreate from '../views/admin/create-supplier';
import suppliers from '../views/admin/suppliers';

type Supplier = {
  supplierId: number;
  name: string;
  orderEmailAddress: string;
  contactName: string;
  contactPhoneNumber: string;
  isActive: boolean;
  orderNotes: string;
}

class Suppliers {
  view: Observable<any>;
  links: ObservableArray<any>;

  constructor(params: any) {
    this.view = ko.observable('');

    switch (params.view) {
      //case supplier.name:
      //  this.view({ name: supplier.name, params: params });
      //  break;
      //case suppliersCreate.name:
      //  this.view({ name: suppliersCreate.name, params: params });
      //  break;
      default:
        this.view(suppliers.name); break;
    }

    this.links = ko.observableArray();

    //this.links.push({
    //  action: () => this.goto.create(),
    //  label: 'Create Supplier',
    //  icon: 'icon/servers-add',
    //  description: 'Create a new supplier'
    //});
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({})),
    //create: (): void => router.goto(routes.suppliersCreate.interpolate({})),
    //server: (supplierId: number): void => router.goto(routes.supplier.interpolate({ supplierId: supplierId }))
  }
}

export default {
  name: 'section-suppliers',
  viewModel: Suppliers,
  template: require('./suppliers.html')
};
