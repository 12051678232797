import * as ko from 'knockout';
import { Computed, Observable, ObservableArray } from 'knockout';
import { shipmentsService } from '../../../api/service.shipments';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatDateTime, formatPrice, formatShipmentNumber, zeroPad } from '../../../utils/format';
import { dialog } from '../../app';
import { FieldType } from '../../elements/bp-dialog';

class ShipmentsDetails {
  readonly title: Observable<string>;

  readonly invoiceItems: ObservableArray<any>;

  readonly supplierInvoiceId: Observable<number>;
  readonly receivedTime: Observable<number | null>;
  readonly supplierId = ko.observable<number>();
  readonly supplier = ko.observable<string>();
  readonly totalCost: Computed<string>;
  readonly itemCount: Computed<number>;
  readonly emailLogId = ko.observable<number | null>();
  readonly time = ko.observable<number>();
  readonly user = ko.observable<string>();
  readonly invoiceNumber = ko.observable<string>();
  readonly purchaseOrderNotes = ko.observable<string>();
  readonly isReceived = ko.observable<boolean>();

  readonly formattedTime = ko.observable<string>();
  readonly formattedReceivedTime = ko.observable<string>();
  readonly formattedInvoiceNumber = ko.observable<string>();
  readonly formattedShipmentNumber = ko.observable<string>();

  constructor(params: any) {
    this.title = ko.observable('Shipments / Details');

    this.supplierInvoiceId = ko.observable(params.id);

    this.receivedTime = ko.observable(null);

    this.itemCount = ko.pureComputed(() => this.invoiceItems().map((t: any) => t.quantity).reduce((p, n) => p + n, 0));
    this.totalCost = ko.pureComputed(() => formatPrice(this.invoiceItems().map((t: any) => t.lineTotal).reduce((p, n) => p + n, 0)));

    this.invoiceItems = ko.observableArray();

    this.loadDetails();

    this.loadInvoiceItems();
  }

  loadDetails = () => {
    shipmentsService
      .getById(this.supplierInvoiceId())
      .then(supplierInvoice => {
        this.supplierId(supplierInvoice.supplierId);
        this.supplier(supplierInvoice.supplier);
        this.emailLogId(supplierInvoice.emailLogId);
        this.time(supplierInvoice.time);
        this.user(`${supplierInvoice.firstName} ${supplierInvoice.lastName}`);
        this.invoiceNumber(supplierInvoice.invoiceNumber);
        this.receivedTime(supplierInvoice.receivedTime);
        this.purchaseOrderNotes(supplierInvoice.purchaseOrderNotes);
        this.isReceived(supplierInvoice.receivedTime != null);
        this.formattedTime(formatDate(supplierInvoice.time));
        this.formattedReceivedTime(formatDateTime(supplierInvoice.receivedTime) || 'Not Received');

        this.formattedInvoiceNumber(supplierInvoice.invoiceNumber || 'Not Received');
        this.formattedShipmentNumber(formatShipmentNumber(this.supplierInvoiceId()));
      })
      .catch(e => console.log(e));
  }

  loadInvoiceItems = () => {
    shipmentsService
      .getInvoiceItems(this.supplierInvoiceId())
      .then(items => {
        const itemMaps = items.map(i => {

          const unitPrice = (i.unitWeight * i.pricePerKg);
          const lineTotal = unitPrice * i.quantity;

          return {
            ...i,
            formattedUnitPrice: formatPrice(unitPrice),
            formattedLineTotal: formatPrice(lineTotal),
            lineTotal: lineTotal
          }
        });

        this.invoiceItems(itemMaps);
      })
      .catch(e => console.log(e));
  }

  createPurchaseOrder = (id: number): void => router.goto(routes.shipmentsCreatePurchaseOrder.interpolate({ id: id }));

  receiveOrder = (): void => {
    const initMessage = `Receive shipment from ${this.supplier()}`;
    const message = ko.observable(initMessage);

    const invoiceNumberField: FieldType<string> = {
      title: 'Invoice Number',
      type: 'text',
      value: ko.observable(''),
    };

    dialog({
      title: 'Enter the invoice number or s/o number of the delivery.',
      message: message,
      fields: ko.observableArray([invoiceNumberField]),
      cancelText: 'Cancel',
      submitText: 'Next',
      submitAction: () => {
        let errors = '';

        const invoiceNumber = invoiceNumberField.value() as string;

        if (invoiceNumber === null || invoiceNumber.length === 0) {
          errors += 'You must enter the invoice number.<br />';
        }

        if (errors !== '') {
          message(initMessage + '<span style="color: red;"><br />' + errors + '</span>');
          return;

        } else {
          // Add to items list.
          router.goto(routes.shipmentsReceive.interpolate({ supplierInvoiceId: this.supplierInvoiceId(), invoiceNumber }));
          dialog(null);

        }
      }, cancelAction: () => {
        dialog(null);
      }
    })
  }
}

export default {
  name: 'bp-shipments-details',
  viewModel: ShipmentsDetails,
  template: require('./details.html')
};
