import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webOrdersService } from '../../../api/service.weborders';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminPaymentMethod {
  title: Observable<string>;
  methods: ko.ObservableArray<any>;

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  constructor(params: any) {
    this.title = ko.observable('Admin / Payment Methods');
    this.methods = ko.observableArray();

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadMethods();
  }

  loadMethods = () => {
    webOrdersService.getPaymentMethods()
      .then(methods => {
        const methodMap = methods.map(m => {

          const icon = m.isActive ? 'icon/circle-tick' : 'icon/cancel-circle';
          const iconClass = m.isActive ? 'verify-icon' : 'cancel-icon';

          return {
            ...m,
            icon: icon,
            iconClass: iconClass,
          }
        });

        this.methods(methodMap);
      })
      .catch(e => console.log(e));
  }

  createPaymentOption = () => {

  }

  editMethod = (methodId: number) => {
    const origMessage = 'Edit payment option';
    const message = ko.observable(origMessage);

    const method = this.methods().filter(m => m.id == methodId)[0];

    const model = {
      userPin: ko.observable<string>(''),
      name: ko.observable<string>(method.name),
      webOrderInstructions: ko.observable<string>(method.webOrderInstructions),
      invoiceInstructions: ko.observable<string>(method.invoiceInstructions),
      isActive: ko.observable<boolean>(method.isActive)
    };

    const userPinField: FieldType<string> = {
      title: 'User Pin',
      type: 'userPin',
      value: model.userPin,
    };

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'display',
      value: model.name,
    };

    const isActiveField: FieldType<boolean> = {
      title: 'Is Active?',
      type: 'checkbox',
      value: model.isActive,
    };

    const webOrderInstructionsField: FieldType<string> = {
      title: 'Web Order Instructions',
      type: 'textarea',
      value: model.webOrderInstructions,
    };

    const invoiceInstructionsField: FieldType<string> = {
      title: 'Invoice Instructions',
      type: 'textarea',
      value: model.invoiceInstructions,
    };

    dialog({
      title: `Edit Payment Option`,
      message: message,
      fields: ko.observableArray([userPinField, nameField, isActiveField, webOrderInstructionsField, invoiceInstructionsField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.userPin() === '') {
          errors += 'Pin cannot be empty. <br />';
        }

        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        webOrdersService.setPaymentMethod(model.userPin(), model.name()!, model.webOrderInstructions() ?? '', model.invoiceInstructions() ?? '', model.isActive() ?? true, method.id)
          .then(results => {
            if (results.success) {
              this.loadMethods();
              dialog(null);
            }
            else {
              message(`${origMessage} <br><div class="error"><ul>${results.message}</ul></div>`);
            }
          });

      }
    });

  }

  createMethod = () => {
    const origMessage = 'Create payment option';
    const message = ko.observable(origMessage);

    const model = {
      userPin: ko.observable<string>(''),
      name: ko.observable<string>(''),
      webOrderInstructions: ko.observable<string>(''),
      invoiceInstructions: ko.observable<string>(''),
      isActive: ko.observable<boolean>(true)
    };

    const userPinField: FieldType<string> = {
      title: 'User Pin',
      type: 'userPin',
      value: model.userPin,
    };

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const isActiveField: FieldType<boolean> = {
      title: 'Is Active?',
      type: 'checkbox',
      value: model.isActive,
    };

    const webOrderInstructionsField: FieldType<string> = {
      title: 'Web Order Instructions',
      type: 'textarea',
      value: model.webOrderInstructions,
    };

    const invoiceInstructionsField: FieldType<string> = {
      title: 'Invoice Instructions',
      type: 'textarea',
      value: model.invoiceInstructions,
    };

    dialog({
      title: `Create Payment Option`,
      message: message,
      fields: ko.observableArray([userPinField, nameField, isActiveField, webOrderInstructionsField, invoiceInstructionsField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.userPin() === '') {
          errors += 'Pin cannot be empty. <br />';
        }

        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        webOrdersService.createPaymentMethod(model.userPin(), model.name()!, model.webOrderInstructions() ?? '', model.invoiceInstructions() ?? '', model.isActive())
          .then(results => {
            if (results.success) {
              this.loadMethods();
              dialog(null);
            }
            else {
              message(`${origMessage} <br><div class="error"><ul>${results.message}</ul></div>`);
            }
          });

      }
    });
  }

  goto = {
    //productDetails: (id: number): void => router.goto(routes.adminProductDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-payment-methods',
  viewModel: AdminPaymentMethod,
  template: require('./payment-methods.html')
};
