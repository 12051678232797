import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webOrdersService } from '../../../api/service.weborders';
import { dialog } from '../../app';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';

class AdminShippingMethods {
  title: Observable<string>;
  methods: ko.ObservableArray<any>;

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  readonly shippingTypes = ["Delivery", "Freighted", "Instore Pickup"];

  constructor(params: any) {
    this.title = ko.observable('Admin / Shipping Methods');
    this.methods = ko.observableArray();

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadMethods();
  }

  loadMethods = () => {
    webOrdersService.getShippingMethods()
      .then(methods => {
        const methodMap = methods.map(m => {

          const icon = m.isActive ? 'icon/circle-tick' : 'icon/cancel-circle';
          const iconClass = m.isActive ? 'verify-icon' : 'cancel-icon';
          const type = m.isDeliveryOption ? 'Delivery' : m.isFreightOption ? 'Freighted' : 'Instore Pickup';

          return {
            ...m,
            icon: icon,
            iconClass: iconClass,
            type: type
          }
        });

        this.methods(methodMap);
      })
      .catch(e => console.log(e));
  }

  editMethod = (methodId: number) => {
    const origMessage = 'Edit shipping option';
    const message = ko.observable(origMessage);

    const method = this.methods().filter(m => m.id == methodId)[0];

    let shippingTypeName = "Instore Pickup";

    if (method.isDeliveryOption) {
      shippingTypeName = "Delivery";
    } else if (method.isFreightOption) {
      shippingTypeName = "Freighted";
    } else if (method.isPickupOption) {
      shippingTypeName = "Instore Pickup";
    }

    const model = {
      userPin: ko.observable<string>(''),
      name: ko.observable<string>(method.name),
      webOrderInstructions: ko.observable<string>(method.webOrderInstructions),
      invoiceInstructions: ko.observable<string>(method.invoiceInstructions),
      description: ko.observable<string>(method.description),
      isActive: ko.observable<boolean>(method.isActive),
      shippingType: ko.observable<string>(shippingTypeName),
      sendToTrelloListId: ko.observable<string>(method.sendToTrelloListId),
    };

    const userPinField: FieldType<string> = {
      title: 'User Pin',
      type: 'userPin',
      value: model.userPin,
    };

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const isActiveField: FieldType<boolean> = {
      title: 'Is Active?',
      type: 'checkbox',
      value: model.isActive,
    };

    const webOrderInstructionsField: FieldType<string> = {
      title: 'Web Order Instructions',
      type: 'textarea',
      value: model.webOrderInstructions,
    };

    const invoiceInstructionsField: FieldType<string> = {
      title: 'Invoice Instructions',
      type: 'textarea',
      value: model.invoiceInstructions,
    };

    const descriptionField: FieldType<string> = {
      title: 'Usage Description',
      type: 'textarea',
      value: model.description,
    };

    console.log(this.shippingTypes);

    const shippingTypeField: FieldType<string> = {
      title: 'Type',
      type: 'dropdown',
      value: model.shippingType,
      options: this.shippingTypes,
      optionsText: (o: any) => o,
      optionsValue: (o: any) => o,
      optionsCaption: ko.observable('Select')
    }

    const sendToTrelloListIdField: FieldType<string> = {
      title: 'Associated Trello ListId',
      type: 'text',
      value: model.sendToTrelloListId,
    };

    dialog({
      title: `Edit Shipping Option`,
      message: message,
      fields: ko.observableArray([userPinField, nameField, isActiveField, shippingTypeField, webOrderInstructionsField, invoiceInstructionsField, descriptionField, sendToTrelloListIdField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.userPin() === '') {
          errors += 'Pin cannot be empty. <br />';
        }

        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        let isDeliveryOption = false;
        let isFreightOption = false;
        let isPickupOption = false;

        switch (model.shippingType()) {
          case "Delivery": isDeliveryOption = true; break;
          case "Freighted": isFreightOption = true; break;
          case "Instore Pickup":
          default: isPickupOption = true; break;
        }

        webOrdersService.setShippingMethod(
          model.userPin(),
          model.name()!,
          model.webOrderInstructions() ?? '',
          model.invoiceInstructions() ?? '',
          model.description() ?? '',
          model.sendToTrelloListId() ?? '',
          model.isActive() ?? true,
          isDeliveryOption,
          isFreightOption,
          isPickupOption,
          method.id)
          .then(results => {
            if (results.success) {
              this.loadMethods();
              dialog(null);
            }
            else {
              message(`${origMessage} <br><div class="error"><ul>${results.message}</ul></div>`);
            }
          });
      }
    });
  }

  createMethod = () => {
    const origMessage = 'Create payment option';
    const message = ko.observable(origMessage);

    let shippingTypeName = "Instore Pickup";

    const model = {
      userPin: ko.observable<string>(''),
      name: ko.observable<string>(''),
      webOrderInstructions: ko.observable<string>(''),
      invoiceInstructions: ko.observable<string>(''),
      isActive: ko.observable<boolean>(true),
      shippingType: ko.observable<string>(shippingTypeName)
    };

    const userPinField: FieldType<string> = {
      title: 'User Pin',
      type: 'userPin',
      value: model.userPin,
    };

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const isActiveField: FieldType<boolean> = {
      title: 'Is Active?',
      type: 'checkbox',
      value: model.isActive,
    };

    const webOrderInstructionsField: FieldType<string> = {
      title: 'Web Order Instructions',
      type: 'textarea',
      value: model.webOrderInstructions,
    };

    const invoiceInstructionsField: FieldType<string> = {
      title: 'Invoice Instructions',
      type: 'textarea',
      value: model.invoiceInstructions,
    };

    const shippingTypeField: FieldType<string> = {
      title: 'Type',
      type: 'dropdown',
      value: model.shippingType,
      options: this.shippingTypes,
      optionsText: (o: any) => o,
      optionsValue: (o: any) => o,
      optionsCaption: ko.observable('Select')
    }

    dialog({
      title: `Create Shipping Option`,
      message: message,
      fields: ko.observableArray([userPinField, nameField, isActiveField, shippingTypeField, webOrderInstructionsField, invoiceInstructionsField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.userPin() === '') {
          errors += 'Pin cannot be empty. <br />';
        }

        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        let isDeliveryOption = false;
        let isFreightOption = false;
        let isPickupOption = false;

        switch (model.shippingType()) {
          case "Delivery": isDeliveryOption = true; break;
          case "Freighted": isFreightOption = true; break;
          case "Instore Pickup":
          default: isPickupOption = true; break;
        }

        webOrdersService.createShippingMethod(model.userPin(), model.name()!, model.webOrderInstructions() ?? '', model.invoiceInstructions() ?? '', model.isActive() ?? true, isDeliveryOption, isFreightOption, isPickupOption)
          .then(results => {
            if (results.success) {
              this.loadMethods();
              dialog(null);
            }
            else {
              message(`${origMessage} <br><div class="error"><ul>${results.message}</ul></div>`);
            }
          });

      }
    });
  }

  goto = {
    //productDetails: (id: number): void => router.goto(routes.adminProductDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-shipping-methods',
  viewModel: AdminShippingMethods,
  template: require('./shipping-methods.html')
};
