import * as ko from 'knockout';
import createInvoice from './create-invoice';
import dashboard from './dashboard';
import details from './details';
import invoiceDetails from './invoice-details';
import invoices from './invoices';

export default function () {
  ko.components.register(dashboard.name, dashboard);
  ko.components.register(details.name, details);
  ko.components.register(createInvoice.name, createInvoice);
  ko.components.register(invoices.name, invoices);
  ko.components.register(invoiceDetails.name, invoiceDetails);
}
