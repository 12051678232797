import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webOrdersService } from '../../../api/service.weborders';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatPrice, formatTime } from '../../../utils/format';
import app, { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class WebOrdersInvoices {
  readonly title: Observable<string>;
  readonly webOrderId: Observable<number>;

  readonly dialog: Observable<DialogParams | null>;

  readonly webOrderInvoices: ko.ObservableArray<any>;

  constructor(params: any) {
    app.checkBeforeNavigation(false);

    this.webOrderId = ko.observable(params.id);
    this.title = ko.observable('Invoices for Order #' + this.webOrderId());

    this.dialog = ko.observable(null);

    this.webOrderInvoices = ko.observableArray();
    this.loadInvoices();
  }

  loadInvoices = () => {
    webOrdersService.getInvoices(this.webOrderId())
      .then(invoices => {

        const invoiceMaps = invoices.map(i => {
          return {
            ...i,
            formattedTime: formatDate(i.time) + ' ' + formatTime(i.time) + (i.isVoid ? ' (Cancelled)' : ''),
            formattedOrderTotal: formatPrice(i.orderTotal),
            formattedFreightTotal: formatPrice(i.freightTotal),
            formattedCreditTotal: formatPrice(i.creditTotal),
            formattedInvoiceTotal: formatPrice(i.invoiceTotal),
            isVoid: i.isVoid
          }
        });

        this.webOrderInvoices(invoiceMaps);

      })
      .catch(e => console.log(e));
  }

  goto = {
    cancel: (): void => router.goto(routes.webordersDetails.interpolate({ id: this.webOrderId() })),

    invoiceDetails: (webOrderInvoiceId: number): void => router.goto(routes.webordersInvoiceDetails.interpolate({ id: webOrderInvoiceId })),

    createInvoice: (): void => {

      if (this.webOrderInvoices().some(i => i.isVoid == 0)) {
        //Open dialog
        const origMessage = '<span style="color: red;">Creating a new invoice will void all previous invoices and reverse any loyalty credits that have been applied.  Are you sure you want to do this?</span>';
        const message = ko.observable(origMessage);

        const model = {
          userPin: ko.observable<string>(''),
          sendEmail: ko.observable<boolean>(false)
        };

        const userPinField: FieldType<string> = {
          title: 'User Pin',
          type: 'userPin',
          value: model.userPin,
          setFocus: true
        };

        const emailField: FieldType<boolean> = {
          title: 'Send cancelled invoice to customer',
          type: 'checkbox',
          value: model.sendEmail
        }

        dialog({
          title: `Please confirm this action.`,
          message: message,
          fields: ko.observableArray<any>([userPinField, emailField]),
          cancelText: 'Cancel',
          submitText: 'Confirm',
          submitAction: () => {
            let errors = '';

            //Check fields
            if (model.userPin() === '') {
              errors += '<br />User  PIN cannot be empty. <br />';
            }

            if (errors !== '') {
              message(`${origMessage}<br /> <div class="error"><ul>${errors}</ul></div>`);

              return;
            }

            webOrdersService.voidInvoices(this.webOrderId(), model.userPin()!, model.sendEmail())
              .then(result => {
                if (result.success) {

                  return router.goto(routes.webordersCreateInvoice.interpolate({ id: this.webOrderId() }));
                } else {
                  message(`${origMessage} <br /><br><div class="error"> - ${result.message}.</div>`);
                }
              });

          }
        });

      } else {
        return router.goto(routes.webordersCreateInvoice.interpolate({ id: this.webOrderId() }))
      }
    },

  }

  voidInvoice = (webOrderInvoiceId: number) => {
    const message = ko.observable('<span style="color: red;">Voiding this invoice will also reverse any loyalty credits that have been applied.  Are you sure you want to do this?</span>');


    const model = {
      userPin: ko.observable<string>(''),
      sendEmail: ko.observable<boolean>(false)
    };

    const userPinField: FieldType<string> = {
      title: 'User Pin',
      type: 'userPin',
      value: model.userPin,
      setFocus: true
    };

    const emailField: FieldType<boolean> = {
      title: 'Send cancelled invoice to customer',
      type: 'checkbox',
      value: model.sendEmail
    }

    dialog({
      title: `Please confirm this action.`,
      message: message,
      fields: ko.observableArray<any>([userPinField, emailField]),
      cancelText: 'Cancel',
      submitText: 'Confirm',
      submitAction: () => {
        let errors = '';

        //Check fields
        if (model.userPin() === '') {
          errors += 'User  PIN cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);

          return;
        } else {
          message('');
        }

        webOrdersService.voidInvoice(this.webOrderId(), model.userPin()!, model.sendEmail(), webOrderInvoiceId)
          .then(result => {
            if (result.success) {

              dialog(null);
            } else {
              message(`<br><div class="error"> - ${result.message}.</div>`);
            }
          });
      }
    });
  };
}

export default {
  name: 'bp-weborders-invoices',
  viewModel: WebOrdersInvoices,
  template: require('./invoices.html')
};
