export function calculateOrderProgressClass(quantitySupplied: number, quantityOrdered: number): string {
  let imageClass = "progress0";

  const completed = (quantitySupplied / quantityOrdered) * 100;

  if (completed == 100) {
    imageClass = "progress100";
  } else if (completed >= 80 && completed < 100) {
    imageClass = "progress80";
  } else if (completed >= 40 && completed < 80) {
    imageClass = "progress40";
  } else if (completed > 0 && completed < 40) {
    imageClass = "progress20";
  } else {
    imageClass = "progress0";
  }

  return imageClass;
}