import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatPrice, formatTime } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminStocktakeDetails {
  readonly title: Observable<string>;
  readonly dialog: Observable<DialogParams | null>;
  readonly stocktakeItems: ObservableArray;
  readonly stocktakeId: Observable<number>;
  readonly isComplete: Observable<boolean | null>;

  constructor(params: any) {
    this.title = ko.observable('Admin / Stocktake Details');
    this.stocktakeId = ko.observable(params.id);
    this.dialog = ko.observable(null);
    this.stocktakeItems = ko.observableArray();
    this.isComplete = ko.observable(null);

    this.getStocktakeItems();

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        dialog(null);
      }
    });
  }

  getStocktakeItems = () => {
    console.log('stocktakeId', this.stocktakeId());

    productsService.getStocktakeById(this.stocktakeId())
      .then(stocktake => {
        console.log(stocktake.isComplete);
        this.isComplete(stocktake.isComplete);

        if (stocktake.list !== null) {

          const items = stocktake.list.map(s => {
            return ({
              ...s,
              formattedProduct: s.productId == null ? `${s.supplierName} | ${s.supplierProductName}` : s.productName,
              formattedUnitPrice: formatPrice(s.costPrice),
              count: s.count,
              formattedTotalValue: formatPrice(s.costPrice * s.count)
            });
          });

          this.stocktakeItems(items);
        }
      });
  };

  stocktakeUpdate = (stocktakeItemId: number, productName: string, count: number) => {
    // Open dialog with count prepopulated.

    const countField: FieldType<string> = {
      title: 'Count',
      type: 'number',
      value: ko.observable(count)
    };

    const origMessage = `Update the count for ${productName}`;
    const message = ko.observable(origMessage);

    dialog({
      title: 'Update count',
      message: message,
      fields: ko.observableArray([countField]),
      cancelText: 'Cancel',
      submitText: 'Save Count',
      submitAction: () => {
        // Save and refresh.
        var errors = '';

        const count = (countField.value() as number);
        if (count < 0) {
          errors += 'You must enter a number that is zero or greater.';
        }

        if (errors !== '') {
          message(origMessage + `<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        productsService.updateStocktakeItem(stocktakeItemId, count)
          .then(result => {
            if (result) {
              dialog(null);

              this.getStocktakeItems();
            } else {
              message(origMessage + '<br /><div class="error"> - Failed to create stocktake.</div>');
            }
          })
      }
    });
  };

  saveStocktake = () => {
    // Save and set isComplete = 0;
    dialog({
      title: 'Save stocktake and continue',
      message: '',
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: 'Save but DONT FINALISE',
      submitAction: () => {
        productsService
          .saveStocktake(this.stocktakeId(), false)
          .then(result => {
            this.getStocktakeItems();
            dialog(null);
          });
      }
    });
  };

  completeStocktake = () => {
    // Save and set isComplete = 1;
    dialog({
      title: 'Finalise and Complete Stocktake',
      message: 'Only do this if the stocktake is complete and you dont want to make further changes.',
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: 'FINALISE',
      submitAction: () => {
        productsService
          .saveStocktake(this.stocktakeId(), true)
          .then(result => {
            this.getStocktakeItems();
            dialog(null);
          });
      }
    });
  };

  //createStocktake = () => {
  //  // Dialog asking for stocktake date.

  //  const origMessage = 'Create new stocktake';
  //  const message = ko.observable(origMessage);

  //  var currentTime = Date.now();
  //  var localTime = formatDate(currentTime) + 'T' + formatTime(currentTime);

  //  const userPinField: FieldType<string> = {
  //    title: 'User PIN',
  //    type: 'userPin',
  //    value: ko.observable('')
  //  };

  //  const dateField: FieldType<string> = {
  //    title: 'Stocktake Date',
  //    type: 'datetime',
  //    step: 300,
  //    value: ko.observable(localTime.slice(0, 16))
  //  };

  //  dialog({
  //    title: 'Create stocktake',
  //    message: message,
  //    fields: ko.observableArray([userPinField, dateField]),
  //    cancelText: 'Cancel',
  //    submitText: 'Create',
  //    submitAction: () => {
  //      var errors = '';

  //      const userPin = (userPinField.value() as string);
  //      const date = (dateField.value() as string);

  //      if (userPin === '') {
  //        errors += 'You must enter your pin';
  //      }

  //      if (date === '') {
  //        errors += 'Date cannot be empty. <br />';
  //      }

  //      if (errors !== '') {
  //        message(origMessage + `<div class="error"><ul>${errors}</ul></div>`);

  //        return;
  //      }

  //      productsService.createStocktake(userPin, new Date(date).toUTCString())
  //        .then(result => {
  //          if (result) {
  //            dialog(null);

  //            // Redirect to details page.
  //            this.goto.stocktakeDetails(result.data);
  //          } else {
  //            message(origMessage + '<br /><div class="error"> - Failed to create stocktake.</div>');
  //          }
  //        })
  //    }
  //  });
  //}
}

export default {
  name: 'bp-admin-stocktake-details',
  viewModel: AdminStocktakeDetails,
  template: require('./stocktake-details.html')
};
