import { Observable, ObservableArray } from 'knockout';

class BpSidebar {
  links: ObservableArray<any>;
  history: ObservableArray<any>;

  constructor(params: any) {
    this.links = params.links;
    this.history = params.history;
  }
}

export default {
  name: 'bp-sidebar',
  viewModel: BpSidebar,
  template: require('./bp-sidebar.html')
};
