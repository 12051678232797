import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webContentService } from '../../../api/service.webcontent';
import { formatDate, formatDateTime } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminOpeningHours {
  title: Observable<string>;
  openingHours: ko.ObservableArray<any>;
  readonly dialog: Observable<DialogParams | null>;

  constructor(params: any) {
    this.title = ko.observable('Admin / Public Website Opening Hours');
    this.openingHours = ko.observableArray();
    this.dialog = ko.observable(null);
    this.contents();
  }

  contents = () => {
    webContentService.getOpeningHours()
      .then(result => {
        const contentMap = result.map(d => {

          return {
            ...d,
            formattedOpeningTime: d.isClosed ? 'Closed' : d.startTime,
            formattedClosingTime: d.isClosed ? 'Closed' : d.endTime,
            formattedIsActive: d.isActive ? 'Visible on website' : 'Not visible on website',
            formattedAllowPickup: d.allowPickup ? 'Pickups Allowed' : '-',
            formattedDay: d.day,
            zoneSpecialDate: d.specialDate,
            formattedPickupStart: d.formattedPickupStart,
            formattedPickupEnd: d.formattedPickupEnd
          };
        });

        this.openingHours(contentMap);
      })
      .catch(e => { console.log(e); });
  }

  actions = {
    createOpeningHours: () => {
      const message = ko.observable<string>('Set opening and closing times for this day.');

      const dayField: FieldType<string> = {
        title: 'Day Name',
        type: 'text',
        value: ko.observable<string>(''),
        setFocus: true
      };

      const startTimeField: FieldType<string> = {
        title: 'Opening Time',
        type: 'text',
        value: ko.observable<string>(''),
      };

      const endTimeField: FieldType<string> = {
        title: 'Closing Time',
        type: 'text',
        value: ko.observable<string>(''),
      };

      const isClosedField: FieldType<boolean> = {
        title: 'Is Closed?',
        type: 'checkbox',
        value: ko.observable<boolean>(true),
      };

      const isActiveField: FieldType<boolean> = {
        title: 'Is visible on website?',
        type: 'checkbox',
        value: ko.observable<boolean>(true),
      };

      dialog({
        title: `Create new opening hours`,
        message: message,
        fields: ko.observableArray([isActiveField, dayField, startTimeField, endTimeField, isClosedField]),
        cancelText: 'Cancel',
        submitText: 'Save',
        submitAction: () => {
          let errors = '';

          const isActive = (isActiveField.value() as boolean);
          const isClosed = (isClosedField.value() as boolean);
          const startTime = (startTimeField.value() as string);
          const endTime = (endTimeField.value() as string);
          const day = (dayField.value() as string);

          if (!isClosed && (startTime === '' || endTime === '')) {
            errors += 'You must select either [ ] Is Closed, or provide opening and closing times. <br />';
          }

          if (isClosed && (startTime !== '' || endTime !== '')) {
            errors += 'You must select either [ ] Is Closed, or provide opening and closing times. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);
            return;
          }

          webContentService.createOpeningHours(day, startTime, endTime, isClosed, isActive)
            .then(result => {
              if (result.success) {

                dialog(null);
                this.contents();
              } else {
                message(`<div class="error"><ul>${result.message}</ul></div>`);
                return;
              }
            })
            .catch((e: any) => { console.log(e); })
        }
      })
    },

    editOpeningHours: (openingHoursId: number, day: string, openingTime: string, closingTime: string, canBeDeleted: boolean, isClosed: boolean, isActive: boolean,
      allowPickup: boolean, pickupStart: string, pickupEnd: string, zoneSpecialDate: string): void => {
      const message = ko.observable<string>('Set opening and closing times for this day.');

      const dayField: FieldType<string> = {
        title: 'Day Name',
        type: 'text',
        value: ko.observable<string>(day),
        setFocus: true
      };

      const specialDateField: FieldType<string> = {
        title: 'Date (yyyy-mm-dd)',
        type: 'text',
        value: ko.observable(zoneSpecialDate),
        setFocus: false
      };

      const startTimeField: FieldType<string> = {
        title: 'Opening Time',
        type: 'text',
        value: ko.observable<string>(openingTime),
        setFocus: false
      };

      const endTimeField: FieldType<string> = {
        title: 'Closing Time',
        type: 'text',
        value: ko.observable<string>(closingTime),
        setFocus: false
      };

      const isClosedField: FieldType<boolean> = {
        title: 'Is Closed?',
        type: 'checkbox',
        value: ko.observable<boolean>(isClosed),
        setFocus: false
      };

      const isActiveField: FieldType<boolean> = {
        title: 'Is visible on website?',
        type: 'checkbox',
        value: ko.observable<boolean>(isActive),
        setFocus: false
      };

      const allowPickupField: FieldType<boolean> = {
        title: 'Allow Pickups?',
        type: 'checkbox',
        value: ko.observable<boolean>(allowPickup),
        setFocus: false
      };

      const pickupStartField: FieldType<string> = {
        title: 'Pickup Start {HH:mm}',
        type: 'text',
        value: ko.observable<string>(pickupStart),
        setFocus: false
      };

      const pickupEndField: FieldType<string> = {
        title: 'Pickup End {HH:mm}',
        type: 'text',
        value: ko.observable<string>(pickupEnd),
        setFocus: false
      };

      let fields = ko.observableArray();

      if (canBeDeleted) {
        // Include Day field.
        fields([isActiveField, dayField, specialDateField, startTimeField, endTimeField, isClosedField, allowPickupField, pickupStartField, pickupEndField]);
      } else {
        fields([isActiveField, startTimeField, endTimeField, isClosedField, allowPickupField, pickupStartField, pickupEndField]);
      }

      dialog({
        title: `Update opening hours for ${day}`,
        message: message,
        fields: fields,
        cancelText: 'Cancel',
        submitText: 'Save Changes',
        submitAction: () => {
          let errors = '';

          const isActive = (isActiveField.value() as boolean);
          const isClosed = (isClosedField.value() as boolean);
          const startTime = (startTimeField.value() as string);
          const endTime = (endTimeField.value() as string);
          const day = (dayField.value() as string);
          const allowPickup = (allowPickupField.value() as boolean);
          const pickupStart = (pickupStartField.value() as string);
          const pickupEnd = (pickupEndField.value() as string);

          if (canBeDeleted && day === '') {
            errors += 'You must supply a name for this day. <br />';
          }

          if (!isClosed && (startTime === '' || endTime === '')) {
            errors += 'You must select either [ ] Is Closed, or provide opening and closing times. <br />';
          }

          if (isClosed && (startTime !== '' || endTime !== '')) {
            errors += 'You must select either [ ] Is Closed, or provide opening and closing times. <br />';
          }

          if (allowPickup && (pickupStart === '' || pickupEnd === '')) {
            errors += 'You must either unselect [ \u2611 ] Allow pickups, or provide pickup start and end times. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);
            return;
          }

          webContentService.saveOpeningHours(openingHoursId, day, zoneSpecialDate, startTime, endTime, allowPickup, pickupStart, pickupEnd, isClosed, isActive)
            .then(result => {
              if (result.success) {

                dialog(null);
                this.contents();
              } else {
                message(`<div class="error"><ul>${result.message}</ul></div>`);
                return;
              }
            })
            .catch((e: any) => { console.log(e); })

        }
      })
    },

    deleteOpeningHours: (openingHoursId: number): void => {
      //Validate this row is deleteable

    },
  }

}

export default {
  name: 'bp-admin-opening-hours',
  viewModel: AdminOpeningHours,
  template: require('./opening-hours.html')
};
