import * as crossroads from 'crossroads';
import { component } from '../components/app';
import adminDashboard from '../components/sections/admin';
import batchesDashboard from '../components/sections/batches';
import main from '../components/sections/main';
import shipmentsDashboard from '../components/sections/shipments';
import webordersDashboard from '../components/sections/weborders';
import accountDetails from '../components/views/admin/account-details';
import adminAccounts from '../components/views/admin/accounts';
import createSupplier from '../components/views/admin/create-supplier';
import dashboard from '../components/views/admin/dashboard';
import adminLocations from '../components/views/admin/locations';
import adminOpeningHours from '../components/views/admin/opening-hours';
import adminReports from '../components/views/admin/reports';
import adminEmailLogs from '../components/views/admin/email-logs';
import adminAdhocEmail from '../components/views/admin/adhoc-email';
import adminStocktakeDetails from '../components/views/admin/stocktake-details';
import adminStocktakes from '../components/views/admin/stocktakes';
import adminPaymentMethods from '../components/views/admin/payment-methods';
import adminProductDetails from '../components/views/admin/product-details';
import adminProducts from '../components/views/admin/products';
import adminShippingMethods from '../components/views/admin/shipping-methods';
import adminSupplierDetails from '../components/views/admin/supplier-details';
import adminSuppliers from '../components/views/admin/suppliers';
import adminTimesheets from '../components/views/admin/timesheets';
import adminUserDetails from '../components/views/admin/user-details';
import adminUsers from '../components/views/admin/users';
import adminWebContent from '../components/views/admin/web-content';
import adminWebContentDetails from '../components/views/admin/web-content-details';
import adminDisplayBoard from '../components/views/admin/display-boards';
import login from '../components/views/auth/login';
import register from '../components/views/auth/register';
import resetPassword from '../components/views/auth/reset-password';
import resetPasswordInstructions from '../components/views/auth/reset-password-instructions';
import validateEmail from '../components/views/auth/validate-email';
import validateEmailInstructions from '../components/views/auth/validate-email-instructions';
import batchCreate from '../components/views/batches/create';
import code404 from '../components/views/error/404';
import shipmentsCreatePurchaseOrder from '../components/views/shipments/create-purchase-order';
import shipmentsDetails from '../components/views/shipments/details';
import shipmentReceive from '../components/views/shipments/receive';
import shipmentSupplierPurchaseOrder from '../components/views/shipments/supplier-purchase-order';
import webordersCreateInvoice from '../components/views/weborders/create-invoice';
import webordersDetails from '../components/views/weborders/dashboard';
import webordersInvoiceDetails from '../components/views/weborders/invoice-details';
import webordersInvoices from '../components/views/weborders/invoices';

const debug = false;

const publicRoute: any = (component: string) => (params: any) => ({ name: component, params: params || {} });
const secureRoute: any = (component: string) => (params: any) =>
  checkForToken()
    ? ({ name: component, params: params || {} })
    : ({ name: login.name, params: {} });

const checkForToken = (): boolean => {
  const storedUser = localStorage.getItem('user');

  if (!storedUser) { return false; }

  const user = JSON.parse(storedUser);

  if (!user.token) { return false; }

  return true;
}

const routes = {

  //public routes
  login: crossroads.addRoute('/login',
    () => { component(publicRoute(login.name)()); }
  ),

  register: crossroads.addRoute('/register',
    () => { component(publicRoute(register.name)()); }
  ),

  validateEmailInstructions: crossroads.addRoute('/validate-email-instructions',
    () => { component(publicRoute(validateEmailInstructions.name)()); }
  ),

  validateEmail: crossroads.addRoute('/validate-email?token={token}&email={email}',
    (token: string, email: string) => { component(publicRoute(validateEmail.name)({ token: token, email: email })); }
  ),

  forgotPassword: crossroads.addRoute('/forgot-password',
    () => { component(publicRoute(forgotPassword.name)()); }
  ),

  resetPasswordInstructions: crossroads.addRoute('/reset-password-instructions',
    () => { component(publicRoute(resetPasswordInstructions.name)()); }
  ),

  resetPassword: crossroads.addRoute('/reset-password?token={token}',
    (token: string) => { component(publicRoute(resetPassword.name)({ token: token })); }
  ),

  //secure routes
  home: crossroads.addRoute('/',
    () => { component(secureRoute(main.name)()); }
  ),

  //SUPPLIERS
  adminSuppliers: crossroads.addRoute('/admin/suppliers',
    () => { component(secureRoute(adminDashboard.name)({ view: adminSuppliers.name })); }
  ),

  adminCreateSupplier: crossroads.addRoute('/admin/supplier/create',
    () => { component(secureRoute(adminDashboard.name)({ view: createSupplier.name })); }),

  adminSupplierDetails: crossroads.addRoute('/admin/supplier/{id}',
    (id: number) => { component(secureRoute(adminDashboard.name)({ view: adminSupplierDetails.name, id: id })); }),

  //WEB CONTENTS
  adminWebContentDetails: crossroads.addRoute('/admin/website-content-details/{id}',
    (id: number) => { component(secureRoute(adminDashboard.name)({ view: adminWebContentDetails.name, id: id })); }),

  timesheetDetails: crossroads.addRoute('/admin/timesheets',
    () => { component(secureRoute(adminDashboard.name)({ view: adminTimesheets.name })); }),

  //PRODUCTS
  adminProducts: crossroads.addRoute('/admin/products',
    () => { component(secureRoute(adminDashboard.name)({ view: adminProducts.name })); }),

  adminProductDetails: crossroads.addRoute('/admin/product/{id}',
    (id: number) => { component(secureRoute(adminDashboard.name)({ view: adminProductDetails.name, id: id })); }),


  //LOCATIONS
  adminLocations: crossroads.addRoute('/admin/locations',
    () => { component(secureRoute(adminDashboard.name)({ view: adminLocations.name })); }),


  //USERS-Customers
  adminUsers: crossroads.addRoute('/admin/users',
    () => { component(secureRoute(adminDashboard.name)({ view: adminUsers.name })); }),

  adminUserDetails: crossroads.addRoute('/admin/user/{id}',
    (id: number) => { component(secureRoute(adminDashboard.name)({ view: adminUserDetails.name, id: id })); }),


  // ACCOUNTS
  adminAccountDetails: crossroads.addRoute('/admin/account/{id}',
    (id: number) => { component(secureRoute(adminDashboard.name)({ view: accountDetails.name, id: id })); }
  ),

  adminAccounts: crossroads.addRoute('/admin/accounts',
    () => { component(secureRoute(adminDashboard.name)({ view: adminAccounts.name })); }
  ),

  adminDashboard: crossroads.addRoute('/admin/',
    () => { component(secureRoute(adminDashboard.name)({ view: dashboard.name })); }
  ),

  // PAYMENT AND SHIPPING METHODS
  adminPaymentMethod: crossroads.addRoute('/admin/payment-methods',
    () => { component(secureRoute(adminDashboard.name)({ view: adminPaymentMethods.name })); }
  ),

  adminShippingMethod: crossroads.addRoute('/admin/shipping-methods',
    () => { component(secureRoute(adminDashboard.name)({ view: adminShippingMethods.name })); }
  ),

  // WEBSITE CONTENT
  adminWebContent: crossroads.addRoute('/admin/website-content',
    () => { component(secureRoute(adminDashboard.name)({ view: adminWebContent.name })); }
  ),

  adminOpeningHours: crossroads.addRoute('/admin/opening-hours',
    () => { component(secureRoute(adminDashboard.name)({ view: adminOpeningHours.name })); }
  ),

  // REPORTS
  adminReports: crossroads.addRoute('/admin/reports',
    () => { component(secureRoute(adminDashboard.name)({ view: adminReports.name })); }
  ),

  // EMAIL LOGS
  adminEmailLogs: crossroads.addRoute('/admin/email-logs',
    () => { component(secureRoute(adminDashboard.name)({ view: adminEmailLogs.name })); }
  ),

  // ADHOC EMAIL
  adminAdhocEmail: crossroads.addRoute('/admin/adhoc-emails',
    () => { component(secureRoute(adminDashboard.name)({ view: adminAdhocEmail.name })); }
  ),

  // STOCKTAKES
  adminStocktakes: crossroads.addRoute('/admin/stocktakes',
    () => { component(secureRoute(adminDashboard.name)({ view: adminStocktakes.name })); }
  ),

  adminStocktakeDetails: crossroads.addRoute('/admin/stocktake/{id}',
    (id: number) => { component(secureRoute(adminDashboard.name)({ view: adminStocktakeDetails.name, id: id })); }
  ),

  //SHIPMENTS
  shipmentsDashboard: crossroads.addRoute('/shipments/',
    () => { component(secureRoute(shipmentsDashboard.name)({ view: dashboard.name })); }
  ),

  shipmentsSupplierPurchaseOrder: crossroads.addRoute('/shipments/supplier-purchase-order',
    () => { component(secureRoute(shipmentsDashboard.name)({ view: shipmentSupplierPurchaseOrder.name })); }
  ),

  shipmentsCreatePurchaseOrder: crossroads.addRoute('/shipments/create-purchase-order/{id}',
    (id: number) => { component(secureRoute(shipmentsDashboard.name)({ view: shipmentsCreatePurchaseOrder.name, id: id })); }
  ),

  shipmentsReceive: crossroads.addRoute('/shipments/receive/{supplierInvoiceId}/{invoiceNumber}',
    (supplierInvoiceId: number, invoiceNumber: string) => { component(secureRoute(shipmentsDashboard.name)({ view: shipmentReceive.name, supplierInvoiceId: supplierInvoiceId, invoiceNumber: invoiceNumber })); }
  ),

  shipmentsDetails: crossroads.addRoute('/shipments/details/{id}',
    (id: number) => { component(secureRoute(shipmentsDashboard.name)({ view: shipmentsDetails.name, id: id })); }
  ),

  //BATCHES
  batchesDashboard: crossroads.addRoute('/batches/',
    () => { component(secureRoute(batchesDashboard.name)({ view: dashboard.name })); }
  ),

  batchCreate: crossroads.addRoute('/batches/create/{id}/{quantity}',
    (id: number, quantity: number) => { component(secureRoute(batchesDashboard.name)({ view: batchCreate.name, id: id, quantity: quantity })); }
  ),

  //WEBORDERS
  webordersDashboardSearch: crossroads.addRoute('/weborders/search?:terms:',
    (terms: string) => { component(secureRoute(webordersDashboard.name)({ view: dashboard.name, type: 'search', terms: terms })); }
  ),

  webordersDashboard: crossroads.addRoute('/weborders/:type:',
    (type: string) => { component(secureRoute(webordersDashboard.name)({ view: dashboard.name, type: type })); }
  ),

  webordersDetails: crossroads.addRoute('/weborders/details/{id}/:type:',
    (id: number, type: string) => { component(secureRoute(webordersDashboard.name)({ view: webordersDetails.name, id: id, type: type })); }
  ),

  webordersCreateInvoice: crossroads.addRoute('/weborders/create-invoice/{id}',
    (id: number) => { component(secureRoute(webordersDashboard.name)({ view: webordersCreateInvoice.name, id: id })); }
  ),

  webordersInvoices: crossroads.addRoute('/weborders/invoices/{id}',
    (id: number) => { component(secureRoute(webordersDashboard.name)({ view: webordersInvoices.name, id: id })); }
  ),

  webordersInvoiceDetails: crossroads.addRoute('/weborders/invoice-details/{id}',
    (id: number) => { component(secureRoute(webordersDashboard.name)({ view: webordersInvoiceDetails.name, id: id })); }
  ),

  // DISPLAY BOARDS
  adminDisplayBoards: crossroads.addRoute('/admin/display-boards',
    (id: number) => { component(secureRoute(adminDashboard.name)({ view: adminDisplayBoard.name })); }
  ),


}

// Defaults

crossroads.bypassed.add(() => { component(publicRoute(code404.name)()); });

if (debug) {
  crossroads.routed.add(console.log, console);
}

export default routes;
