import * as ko from 'knockout';
import { Observable } from 'knockout';
import { locationsService } from '../../../api/service.locations';
import { usersService } from '../../../api/service.users';
import { formatDate, formatFromNow, formatTime } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminTimesheets {
  title: Observable<string>;
  periods: ko.ObservableArray<any>;
  userPIN: ko.Observable<string>;

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  constructor(params: any) {
    this.title = ko.observable('Admin / Timesheets');
    this.periods = ko.observableArray();
    this.userPIN = ko.observable('');

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.userDialog();
  }

  userDialog = () => {
    const message = ko.observable('');

    const userPinField: FieldType<string> = {
      title: 'User PIN',
      type: 'userPin',
      value: ko.observable(''),
      setFocus: true
    };

    dialog({
      title: 'View timesheets',
      message: message,
      fields: ko.observableArray([userPinField]),
      cancelText: 'Cancel',
      submitText: 'Open',
      submitAction: () => {
        let errors = '';

        const userPin = (userPinField.value() as string);

        if (userPin === '') {
          errors += 'You must enter your pin';
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);
          return;
        }

        dialog(null);

        this.userPIN(userPin);

        this.getTimesheets(userPin);
      }
    });
  }

  getTimesheets = (userPin: string) => {
    var currentTime = Date.now();
    var localTime = formatDate(currentTime) + 'T' + formatTime(currentTime);

    var today = ko.pureComputed(() => localTime.slice(0, 16));

    usersService.getUserTimesheets(userPin, today())
      .then(result => {
        console.log(result);

        this.title(`Admin / Timesheets - ${result.userName}`);

        const resultMap = result.periods.map(p => {

          var entries = p.entries.map(e => {
            var formattedDate = formatDate(e.startTime);
            var formattedStart = formatTime(e.startTime);
            var formattedEnd = formatTime(e.endTime);
            var formattedTotalHours = e.totalHours.toFixed(1);

            return {
              ...e,
              formattedDay: e.dayOfWeek,
              formattedDate: formattedDate,
              formattedStart: formattedStart,
              formattedEnd: formattedEnd,
              formattedTotalHours: formattedTotalHours
            }
          });

          var periodEntries = ko.observableArray(entries);

          return {
            ...p,
            periodTitle: `Period Ending ${formatDate(p.periodEnd)}`,
            entries: periodEntries,
            formattedTotalHours: p.totalHours.toFixed(1)
          }
        });

        this.periods(resultMap);
      })
  }

  resetTimer = () => {

  }

  createTimesheet = (timesheetPeriodId: number) => {
    this.resetTimer();

    var currentTime = Date.now();
    var localTime = formatDate(currentTime) + 'T' + formatTime(currentTime);

    const origMessage = `Create new timesheet entry`;
    const message = ko.observable(origMessage);

    const userPinField: FieldType<string> = {
      title: 'User PIN',
      type: 'userPin',
      value: ko.observable(this.userPIN()),
    };

    const dateField: FieldType<string> = {
      title: 'Start Date',
      type: 'datetime',
      step: 300,
      value: ko.observable(localTime.slice(0, 16))
    };

    const endTimeField: FieldType<string> = {
      title: 'End Time',
      type: 'datetime',
      step: 300,
      value: ko.observable(localTime.slice(0, 16))
    };

    const isPublicHolidayField: FieldType<boolean> = {
      title: 'Public Holiday?',
      type: 'checkbox',
      value: ko.observable(false)
    }

    const notesField: FieldType<string> = {
      title: 'Notes',
      type: 'text',
      value: ko.observable('')
    };

    dialog({
      title: `Create new timesheet entry.`,
      message: message,
      fields: ko.observableArray([userPinField, dateField, endTimeField, isPublicHolidayField, notesField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        //Check fields
        let errors = '';

        const userPin = (userPinField.value() as string);
        const startDate = (dateField.value() as string);
        const endDate = (endTimeField.value() as string);
        const isPublicHoliday = (isPublicHolidayField.value() as boolean);
        const notes = (notesField.value() as string);

        if (userPin === '') {
          errors += 'User  PIN cannot be empty. <br />';
        }

        if (startDate === '') {
          errors += 'Date cannot be empty. <br />';
        }

        //if (startTime === '') {
        //  errors += 'Start time cannot be empty. <br />';
        //}

        if (endDate === '') {
          errors += 'End time cannot be empty. <br />';
        }

        if (errors !== '') {
          message(origMessage + `<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        console.log('startDate', startDate);

        //var start = `${date}T${startTime}`;
        //var end = `${date}T${endTime}`;

        //Call api to save data.
        usersService.setUserTimesheet(userPin, new Date(startDate).toUTCString(), new Date(endDate).toUTCString(), isPublicHoliday, notes)
          .then(result => {
            if (result.success) {
              this.getTimesheets(userPin);

              dialog(null);
            }
            else {
              message(origMessage + `<br /><div class="error"><ul>${result.message}</ul></div>`);
            }

          });
      }
    });
  };

  editTimesheet = (timesheetId: number, start: string, end: string, notes: string) => {
    this.resetTimer();

    var localStart = formatDate(start) + 'T' + formatTime(start);
    var localEnd = formatDate(end) + 'T' + formatTime(end);

    const origMessage = `Edit timesheet entry`;
    const message = ko.observable(origMessage);

    const userPinField: FieldType<string> = {
      title: 'User PIN',
      type: 'userPin',
      value: ko.observable(this.userPIN()),
    };

    const dateField: FieldType<string> = {
      title: 'Start Date',
      type: 'datetime',
      step: 300,
      value: ko.observable(localStart.slice(0, 16))
    };

    const endTimeField: FieldType<string> = {
      title: 'End Time',
      type: 'datetime',
      step: 300,
      value: ko.observable(localEnd.slice(0, 16))
    };

    const notesField: FieldType<string> = {
      title: 'Notes',
      type: 'text',
      value: ko.observable(notes)
    };

    dialog({
      title: `Create new timesheet entry.`,
      message: message,
      fields: ko.observableArray([userPinField, dateField, endTimeField, notesField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        //Check fields
        let errors = '';

        const userPin = (userPinField.value() as string);
        const startDate = (dateField.value() as string);
        const endDate = (endTimeField.value() as string);
        const notes = (notesField.value() as string);

        if (userPin === '') {
          errors += 'User  PIN cannot be empty. <br />';
        }

        if (startDate === '') {
          errors += 'Date cannot be empty. <br />';
        }

        //if (startTime === '') {
        //  errors += 'Start time cannot be empty. <br />';
        //}

        if (endDate === '') {
          errors += 'End time cannot be empty. <br />';
        }

        if (errors !== '') {
          message(origMessage + `<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        //Call api to save data.
        usersService.updateUserTimesheet(userPin, timesheetId, startDate, endDate, notes)
          .then(result => {
            if (result.success) {
              this.getTimesheets(userPin);

              dialog(null);
            }
            else {
              message(origMessage + `<br /><div class="error"><ul>${result.message}</ul></div>`);
            }
          });
      }
    });
  };

  deleteTimesheet = (timesheetId: number) => {
    this.resetTimer();

    const origMessage = `Delete timesheet entry`;
    const message = ko.observable(origMessage);

    const userPinField: FieldType<string> = {
      title: 'User PIN',
      type: 'userPin',
      value: ko.observable(this.userPIN()),
    };

    const confirmField: FieldType<boolean> = {
      title: 'Confirm Delete',
      type: 'checkbox',
      value: ko.observable(false)
    };

    dialog({
      title: `Create new timesheet entry.`,
      message: message,
      fields: ko.observableArray([userPinField, confirmField]),
      cancelText: 'Cancel',
      submitText: 'Delete',
      submitAction: () => {
        //Check fields
        let errors = '';

        const userPin = (userPinField.value() as string);
        const confirm = confirmField.value();

        if (userPin === '') {
          errors += 'User  PIN cannot be empty. <br />';
        }

        if (!confirm) {
          errors += 'You must confirm this action if you wish to proceed. <br />';
        }

        if (errors !== '') {
          message(origMessage + `<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        //Call api to save data.
        usersService.deleteUserTimesheet(userPin, timesheetId)
          .then(result => {
            if (result.success) {
              this.getTimesheets(userPin);

              dialog(null);
            }
            else {
              message(origMessage + `<br /><div class="error"><ul>${result.message}</ul></div>`);
            }
          });
      }
    });
  }

  goto = {

    //productDetails: (id: number): void => router.goto(routes.adminProductDetails.interpolate({ id: id })),
  }

}

export default {
  name: 'bp-admin-timesheets',
  viewModel: AdminTimesheets,
  template: require('./timesheets.html')
};
