import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import router from '../../routing/router';
import routes from '../../routing/routes';
import accountDetails from '../views/admin/account-details';
import accounts from '../views/admin/accounts';
import createSupplier from '../views/admin/create-supplier';
import productDetails from '../views/admin/product-details';
import products from '../views/admin/products';
import supplierDetails from '../views/admin/supplier-details';
import suppliers from '../views/admin/suppliers';
import locations from '../views/admin/locations';
import users from '../views/admin/users';
import reports from '../views/admin/reports';
import emailLogs from '../views/admin/email-logs';
import userDetails from '../views/admin/user-details';
import shippingMethods from '../views/admin/shipping-methods';
import paymentMethods from '../views/admin/payment-methods';
import webcontent from '../views/admin/web-content';
import webcontentdetails from '../views/admin/web-content-details';
import webcontentedit from '../views/admin/web-content-details';
import webcontentopeninghours from '../views/admin/opening-hours';
import timesheets from '../views/admin/timesheets';
import adhocEmail from '../views/admin/adhoc-email';
import stocktakes from '../views/admin/stocktakes';
import stocktakeDetails from '../views/admin/stocktake-details';
import displayBoards from '../views/admin/display-boards';

class Admin {
  view: Observable<any>;
  links: ObservableArray<any>;
  isChris = ko.observable<boolean>(false);

  constructor(params: any) {
    this.view = ko.observable();

    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);
      this.isChris(user.firstName === "Chris" && user.lastName === "Richardson");
    }

    switch (params.view) {
      case accounts.name: this.view(accounts.name); break;
      case accountDetails.name: this.view({ name: accountDetails.name, params: params }); break;

      //Suppliers
      case suppliers.name: this.view({ name: suppliers.name, params: params }); break;
      case createSupplier.name: this.view(createSupplier.name); break;
      case supplierDetails.name: this.view({ name: supplierDetails.name, params: params }); break;

      //Products
      case products.name: this.view({ name: products.name, params: params }); break;
      case productDetails.name: this.view({ name: productDetails.name, params: params }); break;

      //Locations
      case locations.name: this.view({ name: locations.name, params: params }); break;

      //Customers
      case users.name: this.view({ name: users.name, params: params }); break;
      case userDetails.name: this.view({ name: userDetails.name, params: params }); break;

      //PaymentMethods
      case paymentMethods.name: this.view({ name: paymentMethods.name, params: params }); break;

      //ShippingMethods
      case shippingMethods.name: this.view({ name: shippingMethods.name, params: params }); break;

      //Reports
      case reports.name: this.view({ name: reports.name, params: params }); break;

      //Email Logs
      case emailLogs.name: this.view({ name: emailLogs.name, params: params }); break;

      //Stocktakes
      case stocktakes.name: this.view({ name: stocktakes.name, params: params }); break;
      case stocktakeDetails.name: this.view({ name: stocktakeDetails.name, params: params }); break;

      //WebContent
      case webcontent.name: this.view({ name: webcontent.name, params: params }); break;
      case webcontentdetails.name: this.view({ name: webcontentdetails.name, params: params }); break;
      case webcontentedit.name: this.view({ name: webcontentedit.name, params: params }); break;
      case webcontentopeninghours.name: this.view({ name: webcontentopeninghours.name, params: params }); break;

      //Timesheets
      case timesheets.name: this.view({ name: timesheets.name, params: params }); break;

      //Timesheets
      case adhocEmail.name: this.view({ name: adhocEmail.name, params: params }); break;

      case displayBoards.name: this.view({ name: displayBoards.name, params: params }); break;

      default: this.view('bp-admin'); break;
    }

    this.links = ko.observableArray(params.links);
    this.links.push({ action: this.goto.suppliers, label: 'Suppliers', icon: 'icon/supplier', description: 'Manage suppliers' });
    this.links.push({ action: this.goto.products, label: 'Products', icon: 'icon/product', description: 'Manage products' });
    this.links.push({ action: this.goto.locations, label: 'Locations', icon: 'icon/location', description: 'Manage locations' });
    this.links.push({ action: this.goto.users, label: 'Customers', icon: 'icon/add-user', description: 'Manage customers' });
    this.links.push({ action: this.goto.paymentMethods, label: 'Payment Methods', icon: 'icon/supplier', description: 'Manage payment options' });
    this.links.push({ action: this.goto.shippingMethods, label: 'Shipping Methods', icon: 'icon/supplier', description: 'Manage shipping options' });
    this.links.push({ action: this.goto.reports, label: 'Reports', icon: 'icon/servers-add', description: 'Production reports' });

    this.links.push({ action: this.goto.emailLogs, label: 'Email Logs', icon: 'icon/servers-add', description: 'Outgoing email logs' });

    this.links.push({ action: this.goto.websiteContent, label: 'Web Content', icon: 'icon/servers-add', description: 'Manage website content' });
    this.links.push({ action: this.goto.websiteOpeningHours, label: 'Opening Hours', icon: 'icon/clock', description: 'Manage opening hours' });

    if (this.isChris()) {
      this.links.push({ action: this.goto.adhocEmail, label: 'Send Adhoc Email', icon: 'icon/servers-add', description: 'Send ' });
    }
    this.links.push({ action: this.goto.displayBoard, label: 'Display Board', icon: 'icon/clock', description: 'Manage display board' });

    this.links.push({ action: this.goto.stocktakes, label: 'Stocktakes', icon: 'icon/product', description: 'View stocktakes' });

  }

  goto = {
    suppliers: (): void => router.goto(routes.adminSuppliers.interpolate({})),
    products: (): void => router.goto(routes.adminProducts.interpolate({})),
    locations: (): void => router.goto(routes.adminLocations.interpolate({})),
    users: (): void => router.goto(routes.adminUsers.interpolate({})),
    paymentMethods: (): void => router.goto(routes.adminPaymentMethod.interpolate({})),
    shippingMethods: (): void => router.goto(routes.adminShippingMethod.interpolate({})),
    websiteContent: (): void => router.goto(routes.adminWebContent.interpolate({})),
    websiteOpeningHours: (): void => router.goto(routes.adminOpeningHours.interpolate({})),
    reports: (): void => router.goto(routes.adminReports.interpolate({})),
    emailLogs: (): void => router.goto(routes.adminEmailLogs.interpolate({})),
    adhocEmail: (): void => router.goto(routes.adminAdhocEmail.interpolate({})),
    stocktakes: (): void => router.goto(routes.adminStocktakes.interpolate({})),
    displayBoard: (): void => router.goto(routes.adminDisplayBoards.interpolate({})),

    accounts: (): void => router.goto(routes.adminAccounts.interpolate({})),
  }
}

export default {
  name: 'section-admin',
  viewModel: Admin,
  template: require('./admin.html')
};
