import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';

export type ConfirmDialogParams = {
  title: Observable<string> | string;
  message: Observable<string> | string;
  fields?: ObservableArray<any>;
  submitText: Observable<string> | string;
  submitAction: () => void;
  cancelText?: Observable<string> | string;
  cancelAction?: () => void;
  severityColor?: Observable<string> | string;
};

class BpConfirmDialog {
  readonly title: Observable<string>;
  readonly message: Observable<string>;
  readonly fields: ObservableArray<any>;
  readonly submitText: Observable<string>;
  readonly submitAction: () => void;
  readonly cancelText?: Observable<string>;
  readonly cancelAction?: () => void;
  readonly severityColor?: Observable<string>;

  constructor(params: ConfirmDialogParams) {
    this.title = ko.isObservable(params.title) ? params.title : ko.observable(params.title);
    this.message = ko.isObservable(params.message) ? params.message : ko.observable(params.message);
    this.fields = params.fields || ko.observableArray();
    this.submitText = ko.isObservable(params.submitText) ? params.submitText : ko.observable(params.submitText);
    this.cancelText = ko.isObservable(params.cancelText) ? params.cancelText : ko.observable(params.cancelText);
    this.submitAction = params.submitAction;
    this.cancelAction = params.cancelAction;

    //this.severityColor = params.severityColor != undefined ? ko.isObservable(params.severityColor) ? params.severityColor : ko.observable(params.severityColor) : ko.observable('steelblue');
    this.severityColor = ko.observable('red');
  }
}

export default {
  name: 'bp-confirm-dialog',
  viewModel: BpConfirmDialog,
  template: require('./bp-confirm-dialog.html')
};
