import * as ko from 'knockout';
import { Observable } from 'knockout';
import { shipmentsService } from '../../../api/service.shipments';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatPrice, formatShipmentNumber } from '../../../utils/format';

class ShipmentsDashboard {
  title: Observable<string>;
  shipments: ko.ObservableArray<any>;

  constructor(params: any) {
    this.title = ko.observable('Shipments / Recent Shipments');
    this.shipments = ko.observableArray();

    this.loadShipments();
  }

  loadShipments = () => {
    shipmentsService.getAll()
      .then(result => {
        const shipmentMap = result.map(s => {

          let itemString = '';
          
          s.items.forEach(c => { itemString += `${c.quantity} x ${c.productName}, ` });

          console.log(s);

          return {
            ...s,
            formattedReceived: s.receivedTime != null ? formatDate(s.receivedTime) : 'On Order',
            formattedTime: formatDate(s.time),
            formattedShipmentNumber: s.receivedTime != null ? formatShipmentNumber(s.supplierInvoiceId) : '',
            formattedInvoiceNumber: s.invoiceNumber != null && s.invoiceNumber != '' ? s.invoiceNumber : '-',
            itemString: itemString
          }
        });

        this.shipments(shipmentMap.sort((a, b) => a.receivedTime > b.receivedTime ? 1 : -1));
      })
      .catch(e => console.log(e));
  }

  goto = {
    shipmentDetails: (supplierInvoiceId: number): void => router.goto(routes.shipmentsDetails.interpolate({ id: supplierInvoiceId })),
  }

}

export default {
  name: 'bp-shipments',
  viewModel: ShipmentsDashboard,
  template: require('./dashboard.html')
};
