import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';

export type DialogParams = {
  dialog?: Observable<DialogParams | null>;
  title: Observable<string> | string;
  message: Observable<string> | string;
  fields?: ObservableArray<any>;
  cancelText: Observable<string> | string;
  submitText: Observable<string> | string;
  submitAction: () => void;
  cancelAction?: () => void;
};

export type IdInfo = {
  Id: number;
  Name: string;
}

export type FieldType<T> = {
  title: Observable<string> | string;
  type: 'text' | 'dropdown' | 'searchable-dropdown' | 'checkbox' | 'number' | 'password' | 'date' | string;
  value: Observable<T | number | string | boolean | undefined | null>;
  visible?: Observable<boolean> | undefined;
  setFocus?: boolean | undefined;
  min?: Observable<any> | undefined;
  max?: Observable<any> | undefined;
  step?: number | undefined;
  options?: Observable<T[]> | T[],
  optionsText?: (o: T | undefined) => string | undefined,
  optionsValue?: (o: T | undefined) => number | string | undefined,
  filterText?: Observable<string> | undefined;
  action?: () => void;
  optionsCaption?: Observable<string> | undefined | null;
  iconName?: string | null;
}

class BpDialog {
  readonly title: Observable<string>;
  readonly message: Observable<string>;
  readonly fields: ObservableArray<any>;
  readonly cancelText: Observable<string>;
  readonly submitText: Observable<string>;
  readonly errors: Observable<string>;
  readonly submitAction: () => void;
  readonly cancelAction?: () => void;

  constructor(params: DialogParams) {
    this.title = ko.isObservable(params.title) ? params.title : ko.observable(params.title);
    this.message = ko.isObservable(params.message) ? params.message : ko.observable(params.message);
    this.fields = params.fields || ko.observableArray();
    this.cancelText = ko.isObservable(params.cancelText) ? params.cancelText : ko.observable(params.cancelText);
    this.submitText = ko.isObservable(params.submitText) ? params.submitText : ko.observable(params.submitText);
    this.submitAction = params.submitAction;
    this.cancelAction = params.cancelAction ? params.cancelAction : params.dialog && params.dialog(null);

    this.errors = ko.observable('');
  }
}

export default {
  name: 'bp-dialog',
  viewModel: BpDialog,
  template: require('./bp-dialog.html')
};
