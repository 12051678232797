export type LocalDate = { local: string; date: string; time: string };

const pad2 = (n: number): string => ('' + n).length < 2 ? '0' + n : '' + n;

const localTimeFormat = (d: Date) => pad2(d.getHours()) + ':' + pad2(d.getMinutes()) + ':' + pad2(d.getSeconds());
const localDateFormat = (d: Date) => d.getFullYear() + '-' + pad2(d.getMonth() + 1) + '-' + pad2(d.getDate());
const localDateTimeFormat = (d: Date) => localDateFormat(d) + ' ' + localTimeFormat(d);

export const localFromUtc = (d: string): LocalDate => {
  const datetime = new Date(Date.parse(d + '+00:00'));

  return ({ local: localDateTimeFormat(datetime), date: localDateFormat(datetime), time: localTimeFormat(datetime) });
};

const sec = {
  week: 7 * 24 * 60 * 60,
  day: 24 * 60 * 60,
  hour: 60 * 60,
  minute: 60
};

export const timeDiff = (d: string): string => {
  const datetime = Math.floor(new Date(Date.parse(d + '+00:00')).getTime() / 1000);

  const now = Math.floor(new Date().getTime() / 1000);
  const diff = datetime - now;

  const abs = Math.abs(diff);

  if (abs < 2) { return 'now'; }

  const isPast = diff < 0;

  let t: string;
  if (abs > sec.week) {
    t = `${Math.abs(Math.floor(diff / sec.week * 10) / 10)} weeks`;
  } else if (abs > sec.day) {
    t = `${Math.abs(Math.floor(diff / sec.day * 10) / 10)} days`;
  } else if (abs > sec.hour) {
    t = `${Math.abs(Math.floor(diff / sec.hour * 10) / 10)} hours`;
  } else if (abs > sec.minute) {
    t = `${Math.abs(Math.floor(diff / sec.minute * 10) / 10)} minutes`;
  } else {
    t = `${abs} seconds`;
  }

  return (isPast ? '' : 'in ') + t + (isPast ? ' ago' : '');
};