import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webContentService } from '../../../api/service.webcontent';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDateTime } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';

class AdminWebContent {
  title: Observable<string>;
  contentPages: ko.ObservableArray<any>;

  constructor(params: any) {
    this.title = ko.observable('Admin / Public Website Content');
    this.contentPages = ko.observableArray();

    this.contents();
  }

  contents = () => {
    webContentService.getAll()
      .then(result => {
        const contentMap = result.map(c => {

          return {
            ...c,
            formattedLastUpdate: formatDateTime(c.updateTime),
            formattedUpdateable: c.allowEditing ? 'Editable' : 'Locked',
          };
        });

        this.contentPages(contentMap);
      })
      .catch(e => { console.log(e); });
  }

  goto = {
    contentDetails: (id: number): void => router.goto(routes.adminWebContentDetails.interpolate({ id: id })),
  }

}

export default {
  name: 'bp-admin-web-content',
  viewModel: AdminWebContent,
  template: require('./web-content.html')
};
