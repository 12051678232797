import * as ko from 'knockout';
import { Computed, Observable, ObservableArray } from 'knockout';
import { webOrdersService } from '../../../api/service.weborders';
import { productsService } from '../../../api/service.products';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatPrice } from '../../../utils/format';
import { calculateOrderProgressClass } from '../../../utils/helpers';
import app, { dialog } from '../../app';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';

class WebOrdersCreateInvoice {
  readonly title: Observable<string>;
  readonly webOrderId: Observable<number>;
  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  readonly customerFirstName = ko.observable<string>();
  readonly customerLastName = ko.observable<string>();
  readonly paymentMethodId = ko.observable<number>(0);
  readonly paymentMethod = ko.observable<string>();
  readonly shippingMethodId = ko.observable<number>(0);
  readonly shippingMethod = ko.observable<string>();
  readonly orderStatusId = ko.observable<number>(0);
  readonly orderStatus = ko.observable<string>();
  readonly orderNotes = ko.observable<string>();
  readonly progressIcon = ko.observable<string>();
  readonly imageClass = ko.observable<string>();
  readonly totalContainers = ko.observable<number>();
  readonly invoiceNotes = ko.observable<string>('');

  readonly formattedCustomerName = ko.observable<string>();
  readonly formattedOrderTotal: Computed<string>;
  readonly rawOrderTotal = ko.observable<number>(0);
  readonly formattedInvoiceTotal: Computed<string>;
  readonly formattedCreditTotal = ko.observable<string>();
  readonly rawCreditTotal = ko.observable<number>(0);
  readonly formattedFreightTotal = ko.observable<string>();
  readonly rawFreightTotal = ko.observable<number>(0);

  readonly invoiceItems: ko.ObservableArray<any>;
  readonly productList: ObservableArray<IdInfo>;

  constructor(params: any) {
    app.checkBeforeNavigation(true);

    this.title = ko.observable('Create Invoice');
    this.webOrderId = ko.observable(params.id);
    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.invoiceItems = ko.observableArray();
    this.productList = ko.observableArray();

    this.loadOrder();
    this.loadOrderItems();

    this.formattedOrderTotal = ko.pureComputed(() => {
      let orderTotal = 0;

      console.log(this.invoiceItems());

      this.invoiceItems().forEach(i => { orderTotal += i.lineTotal() });

      this.rawOrderTotal(orderTotal);
      return formatPrice(this.rawOrderTotal());
    });

    this.formattedInvoiceTotal = ko.pureComputed(() => {
      let invoiceTotal = 0;
      invoiceTotal += this.rawOrderTotal();
      invoiceTotal -= this.rawCreditTotal();
      invoiceTotal += this.rawFreightTotal();
      return formatPrice(invoiceTotal);
    });
  }

  loadOrder = () => {
    webOrdersService.getById(this.webOrderId())
      .then(order => {
        this.customerFirstName(order.customerFirstName);
        this.customerLastName(order.customerLastName);
        this.paymentMethodId(order.paymentMethodId);
        this.paymentMethod(order.paymentMethod);
        this.shippingMethodId(order.shippingMethodId);
        this.shippingMethod(order.shippingMethod);
        this.orderNotes(order.orderNotes);

        this.formattedCustomerName(`${this.customerFirstName()} ${this.customerLastName()}`);
        this.orderStatus(order.status);

        this.progressIcon('icon/battery-full');

        //Get freight total from tickets.
        let freight = 0;
        order.freightTickets?.forEach(f => { freight += f.charge });
        this.rawFreightTotal(freight);

        this.formattedFreightTotal(formatPrice(this.rawFreightTotal()));

        this.rawCreditTotal(order.creditTotal);

        //Get discounds total.
        this.formattedCreditTotal('-' + formatPrice(this.rawCreditTotal()));

      })
      .catch(e => console.log(e));
  }

  loadOrderItems = () => {
    webOrdersService.getOrderItems(this.webOrderId())
      .then(results => {

        let maxContainerNumber = 0;
        let totalSupplied = 0;
        let totalOrdered = 0;

        const orderItemMap = results.map(r => {

          const qty = r.quantitySupplied;
          const productId = r.productId;
          const product = r.product;

          const lineTotal = ko.pureComputed(() => r.quantitySupplied * r.unitPrice);

          totalSupplied += r.quantitySupplied;
          totalOrdered += r.quantityOrdered;

          return {
            quantity: qty,
            productId: productId,
            product: product,
            formattedUnitPrice: formatPrice(r.unitPrice),
            formattedLineTotal: formatPrice(lineTotal()),
            lineTotal: lineTotal,
          }
        });

        this.invoiceItems(orderItemMap);

        this.imageClass(calculateOrderProgressClass(totalSupplied, totalOrdered));
        this.totalContainers(maxContainerNumber);
      })
      .catch(e => console.log(e));
  }

  goto = {
    cancel: (): void => router.goto(routes.webordersDashboard.interpolate({})),
    backToOrder: (): void => router.goto(routes.webordersInvoices.interpolate({ id: this.webOrderId() })),
  }

  actions = {

    createInvoice: (): void => {

      let errors = '';

      if (this.invoiceItems().length == 0) {
        errors += 'You cannot create an invoice with no line items. <br />';
      }

      if (errors != '') {
        dialog({
          title: 'Error creating invoice',
          message: errors,
          fields: ko.observableArray(),
          cancelText: 'Cancel',
          submitText: 'Ok!',
          submitAction: () => {
            dialog(null);

            return;
          }
        });
      } else {
        // Save invoice and queue for sending.
        const message = ko.observable('');

        const model = {
          userPin: ko.observable<string>('')
        };

        const confirmField: FieldType<string> = {
          title: 'Confirm',
          type: 'display',
          value: ko.observable('You are about to save and send this invoice to the customer.')
        };

        const userPinField: FieldType<string> = {
          title: 'User Pin',
          type: 'userPin',
          value: model.userPin,
          setFocus: true
        };

        dialog({
          title: 'Create and send invoice',
          message: '',
          fields: ko.observableArray([confirmField, userPinField]),
          cancelText: 'Cancel',
          submitText: 'Submit',
          submitAction: () => {
            //Check fields
            let errors = '';

            if (model.userPin() === '') {
              errors += 'You must provide your user pin for this action. <br />';
            }

            if (errors !== '') {
              message(`<div class="error"><ul>${errors}</ul></div>`);

              return;
            }

            console.log(this.invoiceNotes());

            webOrdersService.createInvoice(model.userPin()!, this.webOrderId(), this.invoiceNotes(), this.rawCreditTotal())
              .then(result => {
                if (result.success) {

                  dialog(null);

                  // Go back to order.
                  app.checkBeforeNavigation(false);

                  this.goto.backToOrder();

                } else {
                  message(`<div class="error"> - Failed to create invoice. ${result.message}</div>`);
                }
              });
          }
        });
      }
    },
  }
}

export default {
  name: 'bp-weborders-create-invoice',
  viewModel: WebOrdersCreateInvoice,
  template: require('./create-invoice.html')
};
