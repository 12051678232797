import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { fetchHandler } from './fetchHandler';

export const dashboardService = {
  getPhysicalLocations,
  getTheBoard,
  saveMovement,
  saveAdjustment,
  getRecentMovements
};

type LocationResponse = {
  locationId: number;
  name: string;
  isPhysicalStore: boolean;
  isCanPickFrom: boolean;
  isBulkStore: boolean;
}

type ProductLocationResponse = {
  groupId: number;
  groupName: string;
  proudctId: number;
  productName: string;
  warningLevelMin: number;
  criticalLevelMin: number;
  isCritical: boolean;
  isWarning: boolean;
  locationCounts: LocationCount[];
  total: number;
}

type LocationCount = {
  locationId: number;
  locationName: string;
  count: number;
}

type RecentMovementResponse = {
  time: string;
  fromLocation: string;
  toLocation: string;
  quantity: number;
  unitWeight: number;
  productName: string;
}

function getPhysicalLocations(): Promise<LocationResponse[]> {
  return fetchHandler(`${config.apiUrl}/dashboard/getPhysicalLocations`, authPostDataRequest({}))
    .then((results) => { return results.locations; });
}

function getTheBoard(): Promise<ProductLocationResponse[]> {
  return fetchHandler(`${config.apiUrl}/dashboard/getTheBoard`, authPostDataRequest({}))
    .then((results) => { return results.boardRows; });
}

function saveMovement(userPin: any, productId: number, fromLocationId: number, toLocationId: number, quantity: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/movement/create`, authPostDataRequest({ userPin, productId, fromLocationId, toLocationId, quantity }))
    .then((result) => { return result; });
}

export type ApiResponse = {
  success: boolean;
  message: string;
}

function saveAdjustment(userPin: any, productId: number, locationId: number, quantity: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/movement/adjust`, authPostDataRequest({ userPin, productId, locationId, quantity }))
    .then((result) => { return result; });
}

function getRecentMovements(): Promise<RecentMovementResponse[]> {
  return fetchHandler(`${config.apiUrl}/movement/getRecent`, authPostDataRequest({}))
    .then((results) => { return results; });
}