import { authGetRequest } from './auth-get-request';
import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { fetchHandler } from './fetchHandler';

export const locationsService = {
  getAll,
  getById,
  createLocation,
  updateLocation,
  deleteLocation,
};

type LocationResponse = {
  locationId: number;
  name: string;
  isPhysicalStore: boolean;
  isCanPickFrom: boolean;
  isBulkStore: boolean;
}

type LocationDetailsResponse = {
  name: string;
  isPhysicalStore: boolean;
  isCanPickFrom: boolean;
  isBulkStore: boolean;
}

function getAll(): Promise<LocationResponse[]> {
  return fetchHandler(`${config.apiUrl}/location/get`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getById(locationId: number): Promise<LocationDetailsResponse> {
  return fetchHandler(`${config.apiUrl}/location/details`, authPostDataRequest({ locationId }))
    .then(result => { return result; });
}

function createLocation(name: string, isPhysicalStore: boolean, isCanPickFrom: boolean, isBulkStore: boolean): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/location/create`, authPostDataRequest({ name, isPhysicalStore, isCanPickFrom, isBulkStore }))
    .then(result => { return result; });
}

function updateLocation(locationId: number, name: string, isPhysicalStore: boolean, isCanPickFrom: boolean, isBulkStore: boolean): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/location/update`, authPostDataRequest({ locationId, name, isPhysicalStore, isCanPickFrom, isBulkStore }))
    .then(result => { return result; });
}

function deleteLocation(locationId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/location/delete`, authPostDataRequest({ locationId }))
    .then(result => { return result; });
}
