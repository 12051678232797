import * as ko from 'knockout';
import accountDetails from './account-details';
import accounts from './accounts';
import adhocEmail from './adhoc-email';
import createSupplier from './create-supplier';
import dashboard from './dashboard';
import emailLogs from './email-logs';
import locations from './locations';
import webContentOpeningHours from './opening-hours';
import paymentMethods from './payment-methods';
import productDetails from './product-details';
import products from './products';
import reports from './reports';
import shippingMethods from './shipping-methods';
import stocktakeDetails from './stocktake-details';
import stocktakes from './stocktakes';
import supplierDetails from './supplier-details';
import suppliers from './suppliers';
import timesheets from './timesheets';
import userDetails from './user-details';
import users from './users';
import webContent from './web-content';
import webContentDetails from './web-content-details';
import displayBoards from './display-boards';


export default function () {
  ko.components.register(accountDetails.name, accountDetails);
  ko.components.register(accounts.name, accounts);

  ko.components.register(adhocEmail.name, adhocEmail);
  ko.components.register(dashboard.name, dashboard);
  ko.components.register(suppliers.name, suppliers);
  ko.components.register(supplierDetails.name, supplierDetails);
  ko.components.register(createSupplier.name, createSupplier);
  ko.components.register(products.name, products);
  ko.components.register(productDetails.name, productDetails);
  ko.components.register(locations.name, locations);
  ko.components.register(users.name, users);
  ko.components.register(reports.name, reports);
  ko.components.register(emailLogs.name, emailLogs);
  ko.components.register(userDetails.name, userDetails);
  ko.components.register(shippingMethods.name, shippingMethods);
  ko.components.register(stocktakeDetails.name, stocktakeDetails);
  ko.components.register(stocktakes.name, stocktakes);
  ko.components.register(paymentMethods.name, paymentMethods);
  ko.components.register(webContent.name, webContent);
  ko.components.register(webContentDetails.name, webContentDetails);
  ko.components.register(webContentOpeningHours.name, webContentOpeningHours);
  ko.components.register(timesheets.name, timesheets);
  ko.components.register(displayBoards.name, displayBoards);
}
