import * as ko from 'knockout';
import { Observable } from 'knockout';
import { accountsService } from '../../../api/service.accounts';
import { LocalDate, localFromUtc } from '../../../utils/datetime';
import { roleList } from '../../../utils/const';
import { DialogParams, FieldType } from "../../elements/bp-dialog";
import { usersService } from '../../../api/service.users';
import { formatDate, formatFromNow, formatPhoneNumber } from '../../../utils/format';

type PhoneNumber = {};
type Address = {};
type Action = {};

class AdminUserDetails {
  readonly title: Observable<string>;
  readonly id: Observable<number>;
  readonly firstName = ko.observable<string>();
  readonly lastName = ko.observable<string>();
  readonly emailAddress = ko.observable<string>();
  readonly isActive = ko.observable<boolean>();
  readonly userPin = ko.observable<number>();
  readonly loyaltyPointsGained = ko.observable<number>();
  readonly emailReceipts = ko.observable<boolean>();
  readonly emailGeneral = ko.observable<boolean>();

  readonly formattedName = ko.observable<string>();
  readonly formattedCreatedTime = ko.observable<string>();
  readonly formattedAccountInfo = ko.observable<string>();
  readonly formattedAccess = ko.observable<string>();
  readonly formattedLastLogin = ko.observable<string>();
  readonly formattedLastTransaction = ko.observable<string>();

  readonly phoneNumbers = ko.observableArray<PhoneNumber>();
  readonly addresses = ko.observableArray<Address>();
  readonly actions = ko.observableArray<Action>();

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  constructor(params: any) {
    this.id = ko.observable(params.id);
    this.title = ko.observable('User Details');
    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadUser();
    this.loadPhoneNumbers();
    this.loadAddresses();
    this.loadEvents();
  }

  resetPassword = () => {
    const message = ko.observable('');

    const passwordField: FieldType<string> = {
      title: 'New Password',
      type: 'password',
      value: ko.observable(''),
      setFocus: true
    };

    const confirmPasswordField: FieldType<string> = {
      title: 'Confirm Password',
      type: 'password',
      value: ko.observable(''),
      setFocus: true
    };

    this.dialog({
      title: 'Set user password',
      message: message,
      fields: ko.observableArray([passwordField, confirmPasswordField]),
      cancelText: 'Cancel',
      submitText: 'Reset',
      submitAction: () => {
        let errors = '';

        const password = (passwordField.value() as string);
        const confirm = (confirmPasswordField.value() as string);

        if (password !== confirm) {
          errors += 'Passwords dont match!';
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);
          return;
        }

        usersService.resetUserPassword(this.id(), password, confirm)
          .then(result => {
            if (!result.success) {
              errors = 'Failed to reset user password ' + result.message;
              return;
            }

            this.dialog(null);
            return;
          });
      }
    });
  }

  private loadUser() {
    usersService
      .getById(this.id())
      .then(user => {
        this.isActive(user.isActive);
        this.firstName(user.firstName);
        this.lastName(user.lastName);
        this.emailAddress(user.emailAddress);
        this.formattedCreatedTime('Created: ' + formatDate(user.createdTime));
        this.loyaltyPointsGained(user.loyaltyPointsGained);

        this.formattedName(`${user.firstName} ${user.lastName}`);

        const formattedAccountInfo = user.hasAccount ? user.accountLocked ? 'Account Locked' : 'Has User Account' : 'No account';
        this.formattedAccountInfo(formattedAccountInfo);

        let access = [];
        if (user.isAdmin) {
          access.push('Admin');
        }
        if (user.isCashier) {
          access.push('Cashier');
        }
        if (user.isCashRegister) {
          access.push('Till');
        }

        const formattedAccess = access.length > 0 ? access.join(', ') : 'Customer';
        this.formattedAccess(formattedAccess);

        const formattedLastLogin = user.hasAccount ? formatFromNow(user.lastLoginTime) : '-';
        this.formattedLastLogin(formattedLastLogin);

        const formattedLastTransaction = formatFromNow(user.lastTransactionTime);
        this.formattedLastTransaction(formattedLastTransaction);

      })
      .catch(e => console.log(e));
  }

  private loadPhoneNumbers() {
    usersService
      .getByIdPhoneNumbers(this.id())
      .then(phoneNumbers => {
        const userPhoneNumbers = phoneNumbers.map(u => {
          return {
            ...u,
            formattedPhoneNumber: formatPhoneNumber(u.phoneNumber),
            isSmsCapable: u.phoneNumberTypeId == 1
          }
        });

        this.phoneNumbers(userPhoneNumbers);

        console.log(this.phoneNumbers);
      })
      .catch(e => console.log(e));
  }

  private loadAddresses() {
    usersService
      .getByIdAddresses(this.id())
      .then(addresses => {

        const userAddresses = addresses.map(u => {
          return {
            ...u,
            formattedAddress: ''
          }
        });

        this.addresses(addresses);
      })
      .catch(e => console.log(e));
  }

  private loadEvents() {
    usersService
      .getActions(this.id())
      .then(events => {
        const actions = events.map(e => {
          return {
            ...e,
            formattedTime: formatDate(e.time),
          }
        });

        this.actions(actions);
      })
      .catch(e => console.log(e));
  }
}

export default {
  name: 'bp-admin-user-details',
  viewModel: AdminUserDetails,
  template: require('./user-details.html')
};
