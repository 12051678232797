import * as ko from 'knockout';
import { Observable } from 'knockout';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatPrice } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminSuppliers {
  title: Observable<string>;
  suppliers: ko.ObservableArray<any>;
  consumables: ko.ObservableArray<any>;
  readonly toast: Observable<string | null>;
  readonly dialog: Observable<DialogParams | null>;

  constructor(params: any) {
    this.title = ko.observable('Admin / All Suppliers');
    this.suppliers = ko.observableArray();
    this.consumables = ko.observableArray();
    this.toast = ko.observable(null);
    this.dialog = ko.observable(null);

    suppliersService
      .getAll()
      .then(suppliers => this.updateSuppliers(suppliers))
      .catch(e => console.log(e));

    this.loadConsumables();
  }

  updateSuppliers = (suppliers: any[]) => {

    const suppliersMap = suppliers.map(a => {
      const icon = a.isActive ? 'icon/circle-tick' : 'icon/cancel-circle';
      const iconClass = a.isActive ? 'verify-icon' : 'cancel-icon';

      return ({
        ...a,
        icon: icon,
        iconclass: iconClass,
      });
    });
    this.suppliers(suppliersMap);
  };

  loadConsumables = () => {
    suppliersService
      .getConsumableProducts()
      .then(products => {

        const productMap = products.map(p => ({
          ...p,
          formattedPrice: formatPrice(p.price)
        }));
        this.consumables(productMap);

      }).catch(e => console.log(e));
  }

  updateProductConsumable = (productConsumableId: number) => {
    const origMessage = 'Edit consumable';
    const message = ko.observable(origMessage);

    const consumables = this.consumables().filter(c => c.productConsumableId == productConsumableId);

    if (consumables.length < 1) {
      setTimeout(() => { this.toast('Unable to find product'); }, 5000);
      return;
    }

    const consumable = consumables[0];

    const model = {
      name: ko.observable(consumable.name),
      price: ko.observable(consumable.price),
      quantity: ko.observable(consumable.quantity)
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const priceField: FieldType<string> = {
      title: 'Unit Price',
      type: 'number',
      value: model.price,
    };

    const quantityField: FieldType<string> = {
      title: 'Quantity to apply',
      type: 'number',
      value: model.quantity,
    };

    dialog({
      title: `Edit consumable details`,
      message: message,
      fields: ko.observableArray([nameField, priceField, quantityField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (model.price() === '') {
          errors += 'Price cannot be empty. <br />';
        }

        if (model.quantity() === '') {
          errors += 'Quantity cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        suppliersService.updateProductConsumable(productConsumableId, model.name()!, model.price()!, model.quantity()!)
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Supplier updated.');

              this.loadConsumables();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to update consumable.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  createProductConsumable = () => {
    const origMessage = 'Create consumable';
    const message = ko.observable(origMessage);

    const model = {
      name: ko.observable(''),
      price: ko.observable(''),
      quantity: ko.observable('')
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const priceField: FieldType<string> = {
      title: 'Unit Price',
      type: 'number',
      value: model.price,
    };

    const quantityField: FieldType<string> = {
      title: 'Quantity to apply',
      type: 'number',
      value: model.quantity,
    };

    dialog({
      title: `Create consumable details`,
      message: message,
      fields: ko.observableArray([nameField, priceField, quantityField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (model.price() === '') {
          errors += 'Price cannot be empty. <br />';
        }

        if (model.quantity() === '') {
          errors += 'Quantity cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        suppliersService.createProductConsumable(model.name()!, Number(model.price()!), Number(model.quantity()!))
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Product created.');

              this.loadConsumables();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to create consumable.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  goto = {
    supplierDetails: (id: number): void => router.goto(routes.adminSupplierDetails.interpolate({ id: id })),
    createSupplier: (): void => router.goto(routes.adminCreateSupplier.interpolate({})),
  }
}

export default {
  name: 'bp-admin-suppliers',
  viewModel: AdminSuppliers,
  template: require('./suppliers.html')
};
