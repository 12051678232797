import * as ko from 'knockout';
import { Observable } from 'knockout';
import { userService } from '../../../api/service.auth';
import router from '../../../routing/router';
import routes from '../../../routing/routes';

class Register {
  username: Observable<string>;
  email: Observable<string>;
  password: Observable<string>;
  confirm: Observable<string>;
  submittingText: Observable<string>;

  errors = {
    response: ko.observable(''),
    username: ko.observable(''),
    email: ko.observable(''),
    password: ko.observable(''),
    confirm: ko.observable(''),
    hasResponse: ko.pureComputed(() => false),
    hasUsername: ko.pureComputed(() => false),
    hasEmail: ko.pureComputed(() => false),
    hasPassword: ko.pureComputed(() => false),
    hasConfirm: ko.pureComputed(() => false),
    failedValidation: ko.pureComputed(() => false)
  };

  constructor() {
    this.username = ko.observable('');
    this.email = ko.observable('');
    this.password = ko.observable('');
    this.confirm = ko.observable('');
    this.submittingText = ko.observable('');

    this.errors.hasResponse = ko.pureComputed(() => this.errors.response().length > 0);
    this.errors.hasUsername = ko.pureComputed(() => this.errors.username().length > 0);
    this.errors.hasEmail = ko.pureComputed(() => this.errors.email().length > 0);
    this.errors.hasPassword = ko.pureComputed(() => this.errors.password().length > 0);
    this.errors.hasConfirm = ko.pureComputed(() => this.errors.confirm().length > 0);
    this.errors.failedValidation = ko.pureComputed(() => this.errors.hasUsername() || this.errors.hasEmail()
      || this.errors.hasPassword() || this.errors.hasConfirm());
  }

  actions = {
    register: (): void => {
      this.errors.response('');

      this.errors.username(this.username() ? '' : 'Username is required.');
      this.errors.email(this.email() ? '' : 'Email is required.');
      this.errors.password(this.password() ? '' : 'Password is required.');
      this.errors.confirm(this.confirm() ? '' : 'Confirm password is required.');
      this.errors.confirm(this.password() === this.confirm() ? '' : 'Confirm password must match.');

      if (this.errors.failedValidation()) { return; }

      this.submittingText('Registering details and sending verification email...');

      var user = {
        username: this.username(),
        email: this.email(),
        password: this.password(),
        confirmPassword: this.confirm()
      };

      userService.register(user)
        .then(() => {
          this.submittingText('');

          router.goto(routes.validateEmailInstructions.interpolate({}));
        })
        .catch(() => {
          this.errors.response('Registration failed. Please try again.');
        });
    }
  }

  goto = {
    cancel: (): void => router.goto(routes.home.interpolate({}))
  }
}

export default {
  name: 'register',
  viewModel: Register,
  template: require('./register.html')
};
