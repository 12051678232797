import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { ApiResponse } from '../../../api/fetchHandler';
import { productsService } from '../../../api/service.products';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatPrice, formatWeight } from '../../../utils/format';
import { dialog } from '../../app';
import { ConfirmDialogParams } from '../../elements/bp-confirm-dialog';
import { DialogParams, FieldType } from "../../elements/bp-dialog";

type Action = {
  action: () => void;
  icon: string;
  label: string;
  description: string;
}

type RecipeInfo = {
  supplierProduct: string;
  supplier: string;
  quantity: number;
  isDefault: boolean;
  unitPrice: number;
}

type IdInfo = {
  Id: number | null;
  Name: string;
}

const imagePath = 'https://woofles-images.s3.ap-southeast-2.amazonaws.com/products/';

class AdminProductDetails {
  readonly title: Observable<string>;
  readonly categoryList: ObservableArray<IdInfo>;
  readonly groupingList: ObservableArray<IdInfo>;
  readonly supplierProductList: ObservableArray<IdInfo>;
  readonly productImages = ko.observableArray();
  readonly productBarcodes = ko.observableArray<string>();
  readonly productRecipes = ko.observableArray<RecipeInfo>();
  readonly totalManufactureCost = ko.observable<number>(0);
  readonly formattedTotalManufactureCost = ko.observable<string>('');
  readonly totalMargin = ko.observable<number>(0);
  readonly formattedMargin = ko.observable<string>('');
  readonly idealRRP = ko.observable<number>(0);
  readonly formattedIdealRRP = ko.observable<string>('');
  readonly freightTypeList: ObservableArray<IdInfo>;

  readonly actions: ObservableArray<Action>;
  readonly dialog: Observable<DialogParams | null>;
  readonly confirmDialog: Observable<ConfirmDialogParams | null>;
  readonly toast: Observable<string | null>;

  readonly productId: Observable<number>;
  readonly name = ko.observable<string>();
  readonly isActive = ko.observable<boolean>();
  readonly productCategoryId = ko.observable<number>();
  readonly categoryName = ko.observable<string>();
  readonly productGroupId = ko.observable<number>();
  readonly groupingName = ko.observable<string>();
  readonly unitWeight = ko.observable<number>();
  readonly retailRRP = ko.observable<number>();
  readonly salePrice = ko.observable<number>();
  readonly saleQuantity = ko.observable<number>();
  readonly saleStartTime = ko.observable<string>();
  readonly saleEndTime = ko.observable<string>();
  readonly isWebsite = ko.observable<boolean>();
  readonly isRetail = ko.observable<boolean>();
  readonly isLoyaltyItem = ko.observable<boolean>();
  readonly isBulk = ko.observable<boolean>();
  readonly isCat = ko.observable<boolean>(false);
  readonly isDog = ko.observable<boolean>(false);
  readonly applyWastage = ko.observable<boolean>();
  readonly applyConsumables = ko.observable<boolean>();
  readonly wastageCalc = ko.observable<number>();
  readonly criticalLevelMin = ko.observable<number>();
  readonly warningLevelMin = ko.observable<number>();
  readonly packagingDetails = ko.observable<string>();
  readonly formattedWeight = ko.observable<string>();
  readonly formattedPrice = ko.observable<string>();
  readonly formattedSalePrice = ko.observable<string>();
  readonly isOnSale = ko.observable<boolean>();
  readonly formattedWastageCalc = ko.observable<string>();
  readonly description = ko.observable<string>();
  readonly line1 = ko.observable<string>();
  readonly line2 = ko.observable<string>();
  readonly line3 = ko.observable<string>();
  readonly freightTypeId = ko.observable<number>(0);
  readonly freightType = ko.observable<string>();

  readonly previewSrc = ko.observable<string | ArrayBuffer | null | undefined>();
  readonly fileName = ko.observable<string | null>();

  readonly getFile = (_: any, e: any) => {
    let file = e.target.files[0];

    let reader = new FileReader();

    reader.onloadend = () => {
      this.previewSrc(reader.result);
    }

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  readonly uploadProductImage = () => {
    const fileSrc = this.previewSrc();

    if (!fileSrc) {
      return;
    }

    productsService.uploadImage(this.productId(), fileSrc, true)
      .then((result) => {
        if (result) {
          this.loadImageTable();
          this.previewSrc(null);
          this.fileName(null);
        }
      });
  };

  constructor(params: any) {
    this.productId = ko.observable(params.id);
    this.title = ko.observable('Admin / Product Details');
    this.categoryList = ko.observableArray();
    this.groupingList = ko.observableArray();
    this.supplierProductList = ko.observableArray();
    this.freightTypeList = ko.observableArray();

    this.actions = ko.observableArray();
    this.dialog = ko.observable(null);
    this.confirmDialog = ko.observable(null);
    this.toast = ko.observable(null);

    const empty: IdInfo = { Id: null, Name: '-- Select --' };

    this.loadProduct();

    this.loadImageTable();
    this.loadRecipesTable();
    this.loadBarcodesTable();

    productsService
      .getProductCategories(true)
      .then(categories => {

        this.categoryList(categories.map(c => { return ({ Id: c.productCategoryId, Name: c.name }) }));
      })
      .catch(e => console.log(e));

    productsService
      .getProductGroupings()
      .then(groupings => {

        this.groupingList(groupings.map(c => { return ({ Id: c.productGroupId, Name: c.name }) }));

      })
      .catch(e => console.log(e));

    suppliersService
      .getSupplierProducts()
      .then(supplierProducts => {
        const maxLength = Math.max.apply(Math, supplierProducts.map(function (o) { return o.supplierName.length; }))

        this.supplierProductList(supplierProducts.map(c => { return ({ Id: c.supplierProductId, Name: `${c.supplierName.padEnd(maxLength + 2)} : ${c.name}` }) }));

      })
      .catch(e => console.log(e));

    suppliersService
      .getFreightTypes()
      .then(result => {
        this.freightTypeList(result);
      })
      .catch(e => console.log(e));
  }

  loadProduct = () => {
    productsService
      .getById(this.productId())
      .then(product => {
        this.name(product.name);
        this.isActive(product.isActive);
        this.productCategoryId(product.categoryId);
        this.categoryName(product.categoryName);
        this.productGroupId(product.groupingId);
        this.groupingName(product.groupingName);
        this.unitWeight(product.unitWeight);
        this.retailRRP(product.retailRRP);
        this.salePrice(product.salePrice);
        this.saleQuantity(product.saleQuantity);
        this.saleStartTime(product.startTime);
        this.saleEndTime(product.endTime);
        this.isWebsite(product.isWebsite);
        this.isRetail(product.isRetail);
        this.isLoyaltyItem(product.isLoyaltyItem);
        this.isBulk(product.isBulk);
        this.isCat(product.isCat);
        this.isDog(product.isDog);
        this.applyWastage(product.applyWastage);
        this.wastageCalc(product.wastageCalc);
        this.applyConsumables(product.applyConsumables);
        this.criticalLevelMin(product.criticalLevelMin);
        this.warningLevelMin(product.warningLevelMin);
        this.packagingDetails(product.packagingDetails);
        this.description(product.description);
        this.isCat(product.isCat);
        this.isDog(product.isDog);

        this.setTilButtonLines(product);

        this.formattedWeight(formatWeight(product.unitWeight));
        this.formattedPrice(formatPrice(product.retailRRP));
        this.formattedSalePrice(formatPrice(product.salePrice));
        this.isOnSale(product.salePrice > 0);
        this.formattedWastageCalc((product.wastageCalc) + '%');

        this.freightTypeId(product.freightTypeId);
        this.freightType(product.freightType);

      })
      .catch(e => console.log(e));
  }

  setTilButtonLines = (product: any) => {
    this.line1('[DEFAULT]');
    this.line2('[DEFAULT]');
    this.line3('[DEFAULT]');

    if (product.line1 != null && product.line1 !== '') {
      this.line1(product.line1);
    }

    if (product.line2 != null && product.line2 !== '') {
      this.line2(product.line2);
    }

    if (product.line3 != null && product.line3 !== '') {
      this.line3(product.line3);
    }
  }

  loadImageTable = () => {
    productsService
      .getProductImages(this.productId())
      .then(results => this.updateImages(results))
      .catch(e => console.log(e));
  }

  loadBarcodesTable = () => {
    productsService
      .getProductBarcodes(this.productId())
      .then(results => this.productBarcodes(results))
      .catch(e => console.log(e));
  };

  loadRecipesTable = () => {
    productsService
      .getProductRecipes(this.productId())
      .then(results => this.updateRecipes(results))
      .catch(e => console.log(e));
  }

  updateRecipes = (recipe: any) => {
    const items: any[] = recipe.list;

    const recipeMap = items.map(r => {
      return ({
        ...r,
        icon: 'icon/circle-tick',
        iconClass: 'verify-icon',
        formattedQuantity: r.quantity,
        formattedPricePerKg: formatPrice(r.unitPrice * r.quantity),
        formattedPrice: formatPrice(r.unitPrice)
      });
    });

    this.productRecipes(recipeMap);
    this.formattedTotalManufactureCost(formatPrice(recipe.totalManufactureCost));
    this.formattedIdealRRP(formatPrice(recipe.idealRRP));
    this.formattedMargin(recipe.totalMargin.toFixed(0) + '%');
  }

  updateImages = (images: any[]) => {

    const imagesMap = images.map(p => {
      return ({
        ...p,
        formattedImageUrl: `${imagePath}${p.imageUrl}`,
      });
    });

    this.productImages(imagesMap);
  }

  activateProduct = () => {
    const origMessage = `Are you sure you want to ${this.isActive() ? 'deactivate' : 'activate'} this product?`;
    const message = ko.observable(origMessage);

    //Change to confirm dialog.
    dialog({
      title: `${this.isActive() ? 'Deactivate' : 'Activate'} Product`,
      message: message,
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: `${this.isActive() ? 'Deactivate' : 'Activate'}`,
      submitAction: () => {

        if (!this.isActive()) {
          productsService.reactivateProduct(this.productId())
            .then(() => {
              this.loadProduct();
              dialog(null);
            });
        } else {
          productsService.deactivateProduct(this.productId())
            .then(() => {
              // Redirect to products.
              this.goto.adminProducts();
            });
        }
      },
    });
  }

  setDefault = (productImageId: number): void => {
    productsService.setPrimaryImage(this.productId(), productImageId)
      .then(() => this.loadImageTable());
  }

  deleteImage = (productImageId: number) => {
    const origMessage = 'Are you sure you want to delete this image?';
    const message = ko.observable(origMessage);
    //TODO: Change to ConfirmDialog.
    dialog({
      title: `Delete Image`,
      message: message,
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: 'Delete',
      submitAction: () => {
        productsService.deleteImage(productImageId)
          .then((result) => {
            if (!result) {
              message(`${origMessage} <br><div class="error"> - Failed to delete image.</div>`);
              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }

            this.loadImageTable();
            dialog(null);
          });
      },
    });
  }

  createBarcode = () => {
    const origMessage = '';
    const message = ko.observable(origMessage);

    const model = {
      barcode: ko.observable(''),
    };

    const barcodeField: FieldType<string> = {
      title: 'Barcode - exactly as it will be on the label (eg: GPK123)',
      type: 'text',
      value: model.barcode,
    };

    dialog({
      title: `Create barcode`,
      message: message,
      fields: ko.observableArray([barcodeField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {

        var errors = '';
        //check for errors

        if (model.barcode() === '') {
          errors += 'Barcode cannot be empty.'
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"> - ${errors} .</div>`);

          console.log('errors', message());

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        productsService.createBarcode(this.productId(), model.barcode())
          .then((result) => {

            if (!result) {
              message(`${origMessage} <br><div class="error"> - Failed to create barcode.  Barcode already exists on another product.</div>`);

            } else {

              this.loadBarcodesTable();
              dialog(null);
            }
          });
      },
    });
  }

  updateSpecial = () => {
    const origMessage = '';
    const message = ko.observable(origMessage);

    const model = {
      salePrice: ko.observable(this.salePrice()),
      saleQuantity: ko.observable(this.saleQuantity()),
      startDate: ko.observable(this.saleStartTime()?.substr(0, 10)),
      endDate: ko.observable(this.saleEndTime()?.substr(0, 10))
    };

    const priceField: FieldType<number> = {
      title: 'Sale price',
      type: 'number',
      value: model.salePrice,
    };

    //const quantityField: FieldType<number> = {
    //  title: 'Quantity Required For Special Price',
    //  type: 'display',
    //  value: model.saleQuantity,
    //};

    const startTimeField: FieldType<string> = {
      title: 'Start Date',
      type: 'date',
      value: model.startDate,
    };

    const endTimeField: FieldType<string> = {
      title: 'End Date',
      type: 'date',
      value: model.endDate,
    };

    dialog({
      title: `Update Special`,
      message: message,
      fields: ko.observableArray([priceField, startTimeField, endTimeField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {

        var errors = '';
        //check for errors

        if (model.salePrice() && Number(model.salePrice()) <= 0) {
          errors += 'Sale price must be greater than 0.  If you want to stop the special, you need to use the delete option instead. <br />';
        }

        if (model.startDate() === '') {
          errors += 'You must set a start date even if it is today. <br />';
        }

        if (model.endDate() === '') {
          errors += 'You must set an end date. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"> - ${errors} .</div>`);

          console.log('errors', message());

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        if (model.saleQuantity() === 0) {
          model.saleQuantity(1);
        }

        productsService.updateSpecial(this.productId(), model.salePrice()!, model.saleQuantity()!, model.startDate()!, model.endDate()!)
          .then((result) => {

            if (!result.success) {
              message(`${origMessage} <br><div class="error"> - Failed to update special. ${result.message}</div>`);

            } else {
              this.loadProduct();

              dialog(null);
            }
          });
      },
    });
  }

  createSpecial = () => {
    const origMessage = '';
    const message = ko.observable(origMessage);

    var today = new Date();
    var endDate = new Date();
    endDate.setDate(endDate.getDate() + 7);

    const model = {
      salePrice: ko.observable(this.retailRRP()),
      saleQuantity: ko.observable(this.saleQuantity()),
      startDate: ko.observable(formatDate(today)),
      endDate: ko.observable(formatDate(endDate))
    };

    const priceField: FieldType<number> = {
      title: 'Sale price',
      type: 'number',
      value: model.salePrice,
    };

    //const quantityField: FieldType<number> = {
    //  title: 'Quantity Required For Special Price',
    //  type: 'display',
    //  value: model.saleQuantity,
    //};

    const startTimeField: FieldType<string> = {
      title: 'Start Date',
      type: 'date',
      value: model.startDate,
    };

    const endTimeField: FieldType<string> = {
      title: 'End Date',
      type: 'date',
      value: model.endDate,
    };

    dialog({
      title: `Create Special`,
      message: message,
      fields: ko.observableArray([priceField, startTimeField, endTimeField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {

        var errors = '';
        //check for errors

        if (model.salePrice() && Number(model.salePrice()) <= 0) {
          errors += 'Sale price must be greater than 0.  If you want to stop the special, you need to use the delete option instead. <br />';
        }

        if (model.startDate() === '') {
          errors += 'You must set a start date even if it is today. <br />';
        }

        if (model.endDate() === '') {
          errors += 'You must set an end date. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"> - ${errors} .</div>`);

          console.log('errors', message());

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        if (model.saleQuantity() === 0) {
          model.saleQuantity(1);
        }

        productsService.createSpecial(this.productId(), model.salePrice()!, model.saleQuantity()!, model.startDate(), model.endDate())
          .then((result) => {

            if (!result.success) {
              message(`${origMessage} <br><div class="error"> - Failed to create special. ${result.message}</div>`);

            } else {
              this.loadProduct();

              dialog(null);
            }
          });
      },
    });
  }

  deleteSpecial = () => {
    const origMessage = '';
    const message = ko.observable(origMessage);

    this.confirmDialog({
      title: 'Delete special',
      message: 'Are you sure you want to delete this special?',
      cancelText: 'Cancel',
      cancelAction: () => this.confirmDialog(null),
      submitText: 'Delete',
      submitAction: () => {
        productsService.deleteSpecial(this.productId())
          .then((result) => {

            if (!result) {
              message(`${origMessage} <br><div class="error"> - Failed to delete special.</div>`);

            } else {
              this.loadProduct();

              this.confirmDialog(null);
            }
          });
      }
    });
  }

  deleteBarcode = (barcode: string) => {
    const origMessage = `Are you sure you want to delete the barcode ${barcode}?`;
    const message = ko.observable(origMessage);
    //TODO: Change to confirm dialog
    dialog({
      title: `Delete Barcode`,
      message: message,
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: 'Delete',
      submitAction: () => {
        productsService.deleteBarcode(this.productId(), barcode)
          .then((result) => {

            console.log('result', result);

            if (!result) {
              message(`${origMessage} <br><div class="error"> - Failed to delete barcode.</div>`);

            } else {
              this.loadBarcodesTable();
              dialog(null);
            }
          });
      },
    });
  };

  addRecipeItem = () => {
    const origMessage = 'Add recipe item';
    const message = ko.observable(origMessage);

    const model = {
      supplierProductId: ko.observable(0),
      quantity: ko.observable(0),
      isDefault: ko.observable(true)
    }

    const supplierProductIdField: FieldType<IdInfo> = {
      title: 'Supplier Id',
      type: 'dropdown',
      value: model.supplierProductId,
      options: this.supplierProductList(),
      optionsText: (o: any) => o.Name,
      optionsValue: (o: any) => o.Id,
      optionsCaption: ko.observable('-- Select --')
    }

    //const quantityField: FieldType<string> = {
    //  title: 'Quantity (% Raw material contained in 1 unit of this product?)',
    //  type: 'number',
    //  value: model.quantity
    //};

    const quantityField: FieldType<string> = {
      title: 'Kgs Used',
      type: 'number',
      value: model.quantity
    };

    const isDefaultField: FieldType<boolean> = {
      title: 'Is Default Supplier',
      type: 'checkbox',
      value: model.isDefault
    }

    dialog({
      title: `Add product to recipe for ${this.name()} ${this.formattedWeight()}`,
      message: message,
      fields: ko.observableArray([supplierProductIdField, quantityField, isDefaultField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {
        var errors = '';

        console.log(model.supplierProductId());

        if (!model.supplierProductId()) {
          errors += '<li>Supplier product cannot be empty </li>';
        }

        if (model.quantity() == null || model.quantity() == 0) {
          errors += '<li>Kgs used must have a value greater than 0.  eg: 0.5 for 1/2Kg.</li>';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        productsService.createRecipeItem(Number(this.productId()), model.supplierProductId(), model.quantity(), model.isDefault())
          .then(result => {

            console.log('result', result);
            if (result) {
              dialog(null);

              this.toast('Recipe Item Added.');
              this.loadRecipesTable();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to add recipe item.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });

  }

  deleteRecipe = (supplierProductId: number, product: string) => {
    const origMessage = `You are about to delete <strong>${product}</strong> from the recipe.  Are you sure you want to do that?`;
    const message = ko.observable(origMessage);

    const model = {
      userPin: ko.observable(''),
    };

    const userPinField: FieldType<string> = {
      title: 'User PIN',
      type: 'userPin',
      setFocus: true,
      value: model.userPin
    }

    dialog({
      title: 'Confirm deletion of recipe item',
      message: message,
      fields: ko.observableArray([userPinField]),
      submitText: 'Confirm',
      cancelText: 'Cancel',
      submitAction: () => {
        let errors = '';

        if (model.userPin() === '') {
          errors += 'You must enter your user pin.';
        }

        if (errors !== '') {
          message(`${origMessage} <br /><div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        productsService.deleteRecipeItem(model.userPin(), this.productId(), supplierProductId)
          .then(result => {
            if (result.success) {

              this.loadRecipesTable();
              dialog(null);
            } else {
              message(`${origMessage}<br /><div class="error"> - ${result.message}</div>`);
            }
          });
      }
    });
  };

  editProduct = () => {

    const origMessage = '';
    const message = ko.observable(origMessage);

    const model = {
      name: ko.observable(this.name()!),
      unitWeight: ko.observable(this.unitWeight()!),
      retailRRP: ko.observable((this.retailRRP()!).toFixed(2)),
      categoryId: ko.observable(this.productCategoryId()!),
      groupingId: ko.observable<number | null>(this.productGroupId() ?? null),
      isRetail: ko.observable(this.isRetail()),
      isWebsite: ko.observable(this.isWebsite()),
      isLoyaltyItem: ko.observable(this.isLoyaltyItem()),
      isBulk: ko.observable(this.isBulk()),
      applyWastage: ko.observable(this.applyWastage()),
      wastageCalc: ko.observable(this.wastageCalc()),
      criticalLevelMin: ko.observable(this.criticalLevelMin()!),
      warmingLevelMin: ko.observable(this.warningLevelMin()!),
      packagingDetails: ko.observable(this.packagingDetails()!),
      isCat: ko.observable(this.isCat()),
      isDog: ko.observable(this.isDog()),
      description: ko.observable(this.description()),
      applyConsumables: ko.observable(this.applyConsumables()),
      freightTypeId: ko.observable<number | null>(this.freightTypeId() ?? null)
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const unitWeightField: FieldType<string> = {
      title: 'Unit Weight (Kgs)',
      type: 'number',
      value: model.unitWeight,
    };

    const retailRRPField: FieldType<string> = {
      title: 'Retail Price',
      type: 'price',
      value: model.retailRRP,
    };

    const categoryIdField: FieldType<IdInfo> = {
      title: 'Sale Category',
      type: 'dropdown',
      value: model.categoryId,
      options: this.categoryList(),
      optionsText: (o: any) => o.Name,
      optionsValue: (o: any) => o.Id,
      optionsCaption: ko.observable('-- Select --')
    }

    const groupingIdField: FieldType<IdInfo> = {
      title: '"The Board" Grouping',
      type: 'dropdown',
      value: model.groupingId,
      options: this.groupingList(),
      optionsText: (o: any) => o.Name,
      optionsValue: (o: any) => o.Id,
      optionsCaption: ko.observable('-- Select --')
    }

    const descriptionField: FieldType<string> = {
      title: 'Product Description',
      type: 'textarea',
      value: model.description,
    };

    const isRetailField: FieldType<boolean> = {
      title: 'Retail Product (Allow sales through the till)?',
      type: 'checkbox',
      value: model.isRetail
    };

    const isWebsiteField: FieldType<boolean> = {
      title: 'Website Product (Allow sales through the website)?',
      type: 'checkbox',
      value: model.isWebsite
    };

    const isLoyaltyItemField: FieldType<boolean> = {
      title: 'Loyalty Item (Can customer earns points on this item)?',
      type: 'checkbox',
      value: model.isLoyaltyItem
    };

    const isBulkField: FieldType<boolean> = {
      title: 'Bulk Product (Customer must NOT earn loyalty points on this item)?',
      type: 'checkbox',
      value: model.isBulk
    };

    const freightTypeIdField: FieldType<IdInfo> = {
      title: 'Freight Type',
      type: 'dropdown',
      value: model.freightTypeId,
      options: this.freightTypeList(),
      optionsText: (o: any) => o.name,
      optionsValue: (o: any) => o.id,
      optionsCaption: ko.observable('-- Select --')
    }

    const isCatField: FieldType<boolean> = {
      title: 'Is suitable for cats?',
      type: 'checkbox',
      value: model.isCat
    };

    const isDogField: FieldType<boolean> = {
      title: 'Is suitable for dogs?',
      type: 'checkbox',
      value: model.isDog
    };

    const criticalLevelField: FieldType<string> = {
      title: 'Minimum stock level before becoming critical',
      type: 'number',
      value: model.criticalLevelMin,
    };

    const warningLevelField: FieldType<string> = {
      title: 'Minimum stock level before warning that stock is low',
      type: 'number',
      value: model.warmingLevelMin,
    };

    const applyConsumablesField: FieldType<boolean> = {
      title: 'Apply costing for consumables',
      type: 'checkbox',
      value: model.applyConsumables
    };

    const applyWastageField: FieldType<boolean> = {
      title: 'Apply a wastage calculation to this product',
      type: 'checkbox',
      value: model.applyWastage
    };

    const wastageCalcField: FieldType<string> = {
      title: 'Wastage Calculation (percentage of product wasted due to bandsawing and/or mincing) ',
      type: 'number',
      value: model.wastageCalc,
    };

    const packagingDetailsField: FieldType<string> = {
      title: 'Packaging Details (eg: frozen in portion sized blocks, flat packed in 1Kg bags)',
      type: 'text',
      value: model.packagingDetails,
    };

    dialog({
      title: `Update product`,
      message: message,
      fields: ko.observableArray([nameField, categoryIdField, groupingIdField, descriptionField, packagingDetailsField, unitWeightField,
        retailRRPField, isCatField, isDogField, isRetailField, isWebsiteField, isLoyaltyItemField, isBulkField, freightTypeIdField,
        criticalLevelField, warningLevelField, applyConsumablesField, applyWastageField, wastageCalcField]),
      cancelText: 'Cancel',
      submitText: 'Update',
      submitAction: () => {
        var errors = '';

        if (model.name() === '') {
          errors += 'Name cannot be empty \r\n';
        }

        if (model.unitWeight() == null) {
          errors += 'Weight cannot be empty \r\n';
        }

        if (model.unitWeight() == NaN) {
          errors += `Weight can only contain numeric characters and a single \r\n`;
        }

        if (model.retailRRP() == null) {
          errors += 'Purchase price cannot be empty \r\n';
        }

        if (model.categoryId() == null) {
          errors += 'Sale category cannot be empty \r\n';
        }

        if (model.freightTypeId() == null) {
          errors += 'Freight type cannot be empty.  Select NONE instead.  \r\n';
        }

        if (model.isBulk() && model.isLoyaltyItem()) {
          errors += 'You cannot set loyalty on a bulk product \r\n';
        }

        if (model.criticalLevelMin() > model.warmingLevelMin()) {
          errors += 'You may have the critical and warning levels the wrong way around.  The warning should happen on the higher value while the critical activates when there is less stock. \r\n';
        }

        if (model.applyWastage() && model.wastageCalc() == 0) {
          errors += 'You have specified that wastage is to be calculated, so you must provide a percentage for the calculation.  Standard wastage percentage is 4%';
        }

        if ((model.isWebsite() || model.isRetail()) && model.packagingDetails() == null) {
          errors += 'You must specify details on how this product is packed';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"> - ${errors} .</div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        productsService.updateProduct(this.productId(), model.categoryId(), model.groupingId(), model.name(), model.isRetail()!, model.isWebsite()!,
          model.isLoyaltyItem()!, model.isBulk()!, model.applyWastage()!, model.wastageCalc()!, model.unitWeight(), Number(model.retailRRP()),
          model.criticalLevelMin(), model.warmingLevelMin(), model.packagingDetails(), model.isCat(), model.isDog(), model.description() ?? '',
          model.applyConsumables()!, model.freightTypeId())
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Product Updated.');

              this.loadProduct();
            } else {
              message(origMessage + '<br><div class="error"> - Failed to update product.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  editTillButton = () => {
    const origMessage = `<span style='color: red;'>Changing these values will override the default values for each line which are:</span> <br /><strong>Line 1: </strong>Name<br /><strong>Line 2: </strong>Size/Weight<br /><strong>Line 3: </strong>Price`;
    const message = ko.observable(origMessage);

    const model = {
      line1: ko.observable('[DEFAULT]'),
      line2: ko.observable('[DEFAULT]'),
      line3: ko.observable('[DEFAULT]')
    }

    if (this.line1() != null && this.line1() !== '') {
      model.line1(this.line1()!);
    }

    if (this.line2() != null && this.line2() !== '') {
      model.line2(this.line2()!);
    }

    if (this.line3() != null && this.line3() !== '') {
      model.line3(this.line3()!);
    }

    const messageField: FieldType<string> = {
      title: '',
      type: 'display',
      value: ko.observable('To set any line as the default text, ensure that [DEFAULT] is in the box.')
    };

    const message2Field: FieldType<string> = {
      title: '',
      type: 'display',
      value: ko.observable('Other available options are: [SIZE] and [PRICE]')
    };

    const line1Field: FieldType<string> = {
      title: 'Line 1',
      type: 'text',
      value: model.line1
    };

    const line2Field: FieldType<string> = {
      title: 'Line 2',
      type: 'text',
      value: model.line2
    };

    const line3Field: FieldType<string> = {
      title: 'Line 3',
      type: 'text',
      value: model.line3
    };

    dialog({
      title: 'Update Till Button',
      message: message,
      fields: ko.observableArray([messageField, message2Field, line1Field, line2Field, line3Field]),
      cancelText: 'Cancel',
      cancelAction: () => dialog(null),
      submitText: 'Update',
      submitAction: () => {

        // If any lines are set as [DEFAULT], then send to server as '' and let API handle it.
        if (model.line1().toUpperCase() === '[DEFAULT]') {
          model.line1('');
        }
        if (model.line2().toUpperCase() === '[DEFAULT]') {
          model.line2('');
        }
        if (model.line3().toUpperCase() === '[DEFAULT]') {
          model.line3('');
        }

        productsService.updateTilButton(this.productId(), model.line1(), model.line2(), model.line3())
          .then((result: ApiResponse) => {
            if (result.success == false) {

              message(`${origMessage} <span style='color: red';>${result.message}</span>`);
              return;
            }

            this.loadProduct();
            dialog(null);
          });
      }
    });

  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({})),
    adminProducts: (): void => router.goto(routes.adminProducts.interpolate({})),
  }
}

export default {
  name: 'bp-admin-product-details',
  viewModel: AdminProductDetails,
  template: require('./product-details.html')
};
