import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { DialogParams, FieldType } from "../../elements/bp-dialog";
import { formatFromNow, formatPrice, formatWeight } from '../../../utils/format';
import { dialog } from '../../app';

type Action = {
  action: () => void;
  icon: string;
  label: string;
  description: string;
}

class AdminSupplierDetails {
  readonly title: Observable<string>;

  readonly supplierId: Observable<number>;
  readonly name = ko.observable<string>();
  readonly orderEmailAddress = ko.observable<string>();
  readonly contactName = ko.observable<string>();
  readonly contactPhoneNumber = ko.observable<string>();
  readonly orderNotes = ko.observable<string>();
  readonly isActive = ko.observable<boolean>();

  readonly supplierProducts = ko.observableArray<any>();

  readonly actions: ObservableArray<Action>;
  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  constructor(params: any) {
    this.supplierId = ko.observable(params.id);
    this.title = ko.observable('Admin / Supplier Details');

    this.actions = ko.observableArray();
    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadSupplier();

    this.loadSupplierProducts();
  }

  loadSupplierProducts = () => {
    suppliersService
      .getProducts(this.supplierId())
      .then(products => this.updateProducts(products))
      .catch(e => console.log(e));
  }

  loadSupplier = () => {
    suppliersService
      .getById(this.supplierId())
      .then(supplier => {
        this.name(supplier.name);
        this.orderEmailAddress(supplier.orderEmailAddress);
        this.contactName(supplier.contactName);
        this.contactPhoneNumber(supplier.contactPhoneNumber);
        this.orderNotes(supplier.orderNotes);
        this.isActive(supplier.isActive);
      })
      .catch(e => console.log(e));
  }

  updateProducts = (products: any[]) => {
    const productsMap = products.map((p: any) => {

      const icon = p.isActive ? 'icon/circle-tick' : '';

      return ({
        ...p,
        icon: icon,
        unitWeightFormatted: formatWeight(p.unitWeight),
        lastPurchasePriceFormatted: formatPrice(p.lastPurchasePrice),
        priceChangeTimeFormatted: p.priceChangeTime ? `changed ${formatFromNow(p.priceChangeTime)}` : '',
        priceChangeFormatted: p.priceChangeTime ? `(was ${formatPrice(p.previousPurchasePrice)})` : '',
      });
    });
    this.supplierProducts(productsMap);
  };

  activateSupplier = () => {
    const origMessage = `Are you sure you want to ${this.isActive() ? 'deactivate' : 'activate'} this supplier?`;
    const message = ko.observable(origMessage);

    //TODO: Change this to a ConfirmDialog type.
    dialog({
      title: `${this.isActive() ? 'Deactivate' : 'Activate'} Supplier`,
      message: message,
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: `${this.isActive() ? 'Deactivate' : 'Activate'}`,
      submitAction: () => {

        if (!this.isActive()) {
          suppliersService.reactivateSupplier(this.supplierId())
            .then(() => {
              this.loadSupplier();
              dialog(null);
            });
        } else {
          suppliersService.deactivateSupplier(this.supplierId())
            .then(() => {
              // Redirect to products.
              this.goto.adminSuppliers();
            });
        }
      },
    });
  }

  editSupplier = () => {
    const origMessage = 'Edit supplier details';
    const message = ko.observable(origMessage);

    const model = {
      name: ko.observable(this.name()),
      orderEmailAddress: ko.observable(this.orderEmailAddress()),
      contactPhoneNumber: ko.observable(this.contactPhoneNumber()),
      contactName: ko.observable(this.contactName()),
      orderNotes: ko.observable(this.orderNotes())
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const contactField: FieldType<string> = {
      title: 'Contact Name',
      type: 'text',
      value: model.contactName,
    };

    const emailField: FieldType<string> = {
      title: 'Order Email Address',
      type: 'text',
      value: model.orderEmailAddress,
    };

    const phoneField: FieldType<string> = {
      title: 'Phone Number',
      type: 'text',
      value: model.contactPhoneNumber,
    };

    const notesField: FieldType<string> = {
      title: 'Order Notes',
      type: 'text',
      value: model.orderNotes,
    };

    dialog({
      title: `Edit supplier details`,
      message: message,
      fields: ko.observableArray([nameField, contactField, emailField, phoneField, notesField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (model.contactName() === '') {
          errors += 'Contact name cannot be empty. <br />';
        }

        if (model.orderEmailAddress() === '') {
          errors += 'Email address cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        const contactPhoneNumber = model.contactPhoneNumber() ?? null;
        const orderNotes = model.orderNotes() ?? null;

        suppliersService.updateSupplier(this.supplierId(), model.name()!, model.orderEmailAddress()!, model.contactName()!, contactPhoneNumber, orderNotes)
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Supplier updated.');

              this.loadSupplier();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to update supplier.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });


  }

  addProduct = () => {
    const origMessage = 'Add supplier product';
    const message = ko.observable(origMessage);

    const model = {
      name: ko.observable(''),
      unitWeight: ko.observable(0),
      unitType: ko.observable(''),
      supplierOrderCode: ko.observable(''),
      purchasePrice: ko.observable(0),
      isPackaging: ko.observable(false)
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const unitWeightField: FieldType<string> = {
      title: 'Unit Weight (Kgs)',
      type: 'number',
      value: model.unitWeight,
    };

    const unitTypeField: FieldType<string> = {
      title: 'Purchase Size (eg: 10Kg Box)',
      type: 'text',
      value: model.unitType,
    };

    const supplierOrderCode: FieldType<string> = {
      title: 'Suppliers Order Code',
      type: 'text',
      value: model.supplierOrderCode,
    };

    const priceField: FieldType<string> = {
      title: 'Price Per Kg',
      type: 'price',
      value: model.purchasePrice,
    };

    const isPackagingField: FieldType<boolean> = {
      title: 'Is Packaging',
      type: 'checkbox',
      value: model.isPackaging
    };


    dialog({
      title: `Create product`,
      message: message,
      fields: ko.observableArray([nameField, unitWeightField, unitTypeField, supplierOrderCode, priceField, isPackagingField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty. \n';
        }

        if (model.unitWeight() == null) {
          errors += 'Weight cannot be empty. \n';
        }

        if (model.unitWeight() == NaN) {
          errors += `Weight can only contain numeric characters and a single '.'  \n\n`;
        }

        if (model.purchasePrice() == null) {
          errors += 'Purchase price cannot be empty. \r\n';
        }

        if (model.purchasePrice() == NaN) {
          errors += `Purchase can only contain numeric characters and a single '.' \n\n`;
        }

        if (model.supplierOrderCode() === '') {
          errors += 'Supplier order code cannot be empty. \r\n';
        }

        if (errors !== '') {
          message(`${origMessage} '<br><div class="error"> - ${errors} .</div>`);

          return;
        }

        suppliersService.createSupplierProduct(this.supplierId(), model.name(), model.unitWeight(), model.unitType(), model.purchasePrice(), model.isPackaging(), model.supplierOrderCode())
          .then(result => {
            if (result) {
              dialog(null);

              this.loadSupplierProducts();

            } else {
              message(origMessage + '<br><div class="error"> - Failed to create supplier product.</div>');
            }
          });
      },
    });
  }

  editProduct = (productId: number) => {
    const origMessage = 'Update supplier product';
    const message = ko.observable(origMessage);

    const products = this.supplierProducts().filter(p => p.supplierProductId == productId);

    if (products.length < 1) {
      this.toast("Failed to find product details");
      return;
    }

    const product = products[0];

    console.log(product);

    const model = {
      name: ko.observable(product.name),
      unitWeight: ko.observable(product.unitWeight),
      unitType: ko.observable(product.unitType),
      supplierOrderCode: ko.observable(product.supplierOrderCode),
      purchasePrice: ko.observable(product.lastPurchasePrice),
      isPackaging: ko.observable(product.isPackaging)
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const unitWeightField: FieldType<string> = {
      title: 'Unit Weight (Kgs)',
      type: 'number',
      value: model.unitWeight,
    };

    const unitTypeField: FieldType<string> = {
      title: 'Purchase Size (eg: 10Kg Box)',
      type: 'text',
      value: model.unitType,
    };

    const supplierOrderCode: FieldType<string> = {
      title: 'Suppliers Order Code',
      type: 'text',
      value: model.supplierOrderCode,
    };

    const priceField: FieldType<string> = {
      title: 'Price Per Kg',
      type: 'price',
      value: model.purchasePrice,
    };

    const isPackagingField: FieldType<boolean> = {
      title: 'Is Packaging',
      type: 'checkbox',
      value: model.isPackaging
    };

    dialog({
      title: `Update supplier product`,
      message: message,
      fields: ko.observableArray([nameField, unitWeightField, unitTypeField, supplierOrderCode, priceField, isPackagingField]),
      cancelText: 'Cancel',
      submitText: 'Update',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty. \n';
        }

        if (model.unitWeight() == null) {
          errors += 'Weight cannot be empty. \n';
        }

        if (model.unitWeight() == NaN) {
          errors += `Weight can only contain numeric characters and a single '.'  \n\n`;
        }

        if (model.purchasePrice() == null) {
          errors += 'Purchase price cannot be empty. \r\n';
        }

        if (model.purchasePrice() == NaN) {
          errors += `Purchase can only contain numeric characters and a single '.' \n\n`;
        }

        if (model.supplierOrderCode() === '') {
          errors += 'Supplier order code cannot be empty. \r\n';
        }

        if (errors !== '') {
          message(`${origMessage} '<br><div class="error"> - ${errors} .</div>`);

          return;
        }

        suppliersService.updateSupplierProduct(this.supplierId(), productId, model.name(), model.unitWeight(), model.unitType(), model.purchasePrice(), model.isPackaging(), model.supplierOrderCode())
          .then(result => {
            if (result) {
              dialog(null);

              this.loadSupplierProducts();
            } else {
              message(origMessage + '<br><div class="error"> - Failed to update supplier product.</div>');
            }
          });
      },
    });
  }

  disableProduct = (productId: number, isActive: boolean) => {
    const origMessage = `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} this supplier product? ${!isActive ? 'This will also remove them from any recipes they are used in.' : ''}`;
    const message = ko.observable(origMessage);

    //TODO: Change this to a ConfirmDialog type.
    dialog({
      title: `${this.isActive() ? 'Deactivate' : 'Activate'} Supplier Product`,
      message: message,
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: `${this.isActive() ? 'Deactivate' : 'Activate'}`,
      submitAction: () => {
        if (!this.isActive()) {
          suppliersService.reactivateSupplierProduct(this.supplierId(), productId)
            .then(() => {
              this.loadSupplierProducts();
              dialog(null);
            });
        } else {
          suppliersService.deactivateSupplierProduct(this.supplierId(), productId)
            .then(() => {
              this.loadSupplierProducts();
              dialog(null);
            });
        }
      },
    });
  }

  goto = {
    home: (): void => router.goto(routes.home.interpolate({})),
    adminSuppliers: (): void => router.goto(routes.adminSuppliers.interpolate({})),
  }
}

export default {
  name: 'bp-admin-supplier-details',
  viewModel: AdminSupplierDetails,
  template: require('./supplier-details.html')
};
