import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatPrice, formatTime } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminStocktakes {
  readonly title: Observable<string>;
  readonly dialog: Observable<DialogParams | null>;
  readonly stocktakes: ObservableArray;

  constructor(params: any) {
    this.title = ko.observable('Admin / Stocktakes');
    this.dialog = ko.observable(null);
    this.stocktakes = ko.observableArray();

    this.getStocktakes()

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        dialog(null);
      }
    });
  }

  getStocktakes = () => {
    productsService.getStocktakes()
      .then(stocktakes => {

        const items = stocktakes.map(s => {

          return ({
            formattedDate: formatDate(s.date),
            formattedIsComplete: s.isComplete == null ? 'Pending Count' : s.isComplete ? 'Completed and Finalised' : 'In Progress',
            isComplete: s.isComplete,
            stocktakeId: s.stocktakeId,
            formattedTotalValue: formatPrice(s.totalValue)
          })
        });

        this.stocktakes(items);
      });
  };

  createStocktake = () => {
    // Dialog asking for stocktake date.

    const origMessage = 'Create new stocktake';
    const message = ko.observable(origMessage);

    var currentTime = Date.now();
    var localTime = formatDate(currentTime) + 'T' + formatTime(currentTime);

    const userPinField: FieldType<string> = {
      title: 'User PIN',
      type: 'userPin',
      value: ko.observable('')
    };

    const dateField: FieldType<string> = {
      title: 'Stocktake Date',
      type: 'datetime',
      step: 300,
      value: ko.observable(localTime.slice(0, 16))
    };

    dialog({
      title: 'Create stocktake',
      message: message,
      fields: ko.observableArray([userPinField, dateField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {
        var errors = '';

        const userPin = (userPinField.value() as string);
        const date = (dateField.value() as string);

        if (userPin === '') {
          errors += 'You must enter your pin';
        }

        if (date === '') {
          errors += 'Date cannot be empty. <br />';
        }

        if (errors !== '') {
          message(origMessage + `<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        productsService.createStocktake(userPin, new Date(date).toUTCString())
          .then(result => {
            if (result) {
              dialog(null);

              // Redirect to details page.
              this.goto.stocktakeDetails(result.data);
            } else {
              message(origMessage + '<br /><div class="error"> - Failed to create stocktake.</div>');
            }
          })
      }
    });
  }

  goto = {
    stocktakeDetails: (id: number): void => router.goto(routes.adminStocktakeDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-stocktakes',
  viewModel: AdminStocktakes,
  template: require('./stocktakes.html')
};
