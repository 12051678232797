import * as ko from 'knockout';
import { Observable } from 'knockout';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { DialogParams } from '../../elements/bp-dialog';

class AdminReports {
  title: Observable<string>;

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  filter = ko.observable('');

  constructor(params: any) {
    this.title = ko.observable('Admin / Reports');

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

  }

  goto = {
    userDetails: (id: number): void => router.goto(routes.adminUserDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-reports',
  viewModel: AdminReports,
  template: require('./reports.html')
};
