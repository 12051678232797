import { authGetRequest } from './auth-get-request';
import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { fetchHandler } from './fetchHandler';

export const accountsService = {
  getAll,
  getAllAvailable,
  getById,
  getCurrent,
  removeSteamId,
  removeAccountSteamId,
  addAccountSteamId,
  removeAccountRole,
  grantAccountRole,
};

type AccountResponse = {
  id: number;
  username: string;
  email: string;
  createdUtc: string;
  updatedUtc: string;
  roles: string[];
  steamIds: string[];
  isVerified: boolean;
}

type AccountIdResponse = {
  id: number;
  username: string;
}

function getAll(): Promise<AccountResponse[]> {
  return fetchHandler(`${config.apiUrl}/accounts/get-all`, authGetRequest())
    .then(accounts => { return accounts; });
}

function getAllAvailable(serverId: number): Promise<AccountIdResponse[]> {
  return fetchHandler(`${config.apiUrl}/accounts/get-all-available/${serverId}`, authGetRequest())
    .then(accounts => {
      return accounts;
    });
}

function getById(accountId: number): Promise<AccountResponse> {
  return fetchHandler(`${config.apiUrl}/accounts/${accountId}`, authGetRequest())
    .then(account => { return account; });
}

// current user
function getCurrent(): Promise<AccountResponse> {
  return fetchHandler(`${config.apiUrl}/accounts/current`, authGetRequest())
    .then(account => { return account; });
}

function removeSteamId(steamId: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/accounts/remove-steam-id/${steamId}`, authPostDataRequest({}));
}

// admin+ role only
function removeAccountSteamId(accountId: number, steamId: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/accounts/${accountId}/remove-account-steam-id/${steamId}`, authPostDataRequest({}));
}

function addAccountSteamId(accountId: number, steamId: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/accounts/${accountId}/add-account-steam-id/${steamId}`, authPostDataRequest({}));
}

function removeAccountRole(accountId: number, role: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/accounts/${accountId}/revoke-role/${role}`, authPostDataRequest({}));
}

function grantAccountRole(accountId: number, role: string): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/accounts/${accountId}/grant-role/${role}`, authPostDataRequest({}));
}
