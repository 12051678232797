import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import createBatch from '../views/batches/create';

class Batches {
  view: Observable<any>;
  links: ObservableArray<any>;

  constructor(params: any) {
    this.view = ko.observable();

    switch (params.view) {

      //case supplierPurchaseOrder.name: this.view({ name: supplierPurchaseOrder.name, params: params }); break;
      case createBatch.name: this.view({ name: createBatch.name, params: params }); break;
      //case details.name: this.view({ name: details.name, params: params }); break;
      //case receive.name: this.view({ name: receive.name, params: params }); break;

      default: this.view('bp-batches'); break;
    }

    this.links = ko.observableArray(params.links);
    //this.links.push({ action: this.goto.supplierPurchaseOrder, label: 'Create Purchase Order', icon: 'icon/supplier', description: 'Order products from supplier' });
    //this.links.push({ action: this.goto.receiveProduct, label: 'Receive Product', icon: 'icon/location', description: 'Receive inward goods' });
    //this.links.push({ action: this.goto.users, label: 'Customers', icon: 'icon/add-user', description: 'Manage customers' });
    //this.links.push({ action: this.goto.suppliers, label: 'Reports', icon: 'icon/servers-add', description: 'Production reports' });
  }

  goto = {
    //supplierPurchaseOrder: (): void => router.goto(routes.shipmentsSupplierPurchaseOrder.interpolate({})),
    //receiveProduct: (): void => router.goto(routes.shipmentsReceive.interpolate({})),
    //users: (): void => router.goto(routes.adminUsers.interpolate({})),
    //reports: (): void => router.goto(routes.adminReports.interpolate({})),
  }
}

export default {
  name: 'section-batches',
  viewModel: Batches,
  template: require('./batches.html')
};
