import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { DialogParams } from '../../elements/bp-dialog';
import { reportService } from '../../../api/service.reports';
import { formatDateTime } from '../../../utils/format';
import { dialog } from '../../app';


class AdminEmailLogs {
  title: Observable<string>;
  emailLogs = ko.observableArray();
  emailHeader = '';
  emailFooter = '';

  selectedEmail: Observable<{
    dateSent: string;
    recipient: string;
    emailAddress: string;
    subject: string;
    status: string;
    fullHtml: string;
  }>;
  selectedEmailMessageId = ko.observable<number>(0);

  filter = ko.observable('');

  constructor(params: any) {
    this.title = ko.observable('Admin / Email Logs');
    this.selectedEmail = ko.observable({
      dateSent: '',
      recipient: '',
      emailAddress: '',
      subject: '',
      status: '',
      fullHtml: ''
    });

    this.getEmailLogs();
  }

  getEmailLogs = () => {
    reportService
      .getEmailLogs()
      .then(result => {
        const messages = result.messages.map(m => {
          return {
            ...m,
            formattedTime: formatDateTime(m.time),
            recipient: `${m.firstName} ${m.lastName}`,
            formattedStatus: m.succeeded ? `Sent after ${m.attemptCount} attempt(s)` : m.completed ? 'Failed to send' : `Attempt ${m.attemptCount}`
          }
        });

        this.emailHeader = result.emailHeader;
        this.emailFooter = result.emailFooter;

        this.emailLogs(messages);
      });
  }

  selectEmailMessage = (outboundEmailMessageId: number) => {
    this.selectedEmailMessageId(outboundEmailMessageId);

    const selectLogs = this.emailLogs().filter(e => e.outboundEmailMessageId == outboundEmailMessageId);
    if (selectLogs.length == 0) {
      return;
    }

    const email = selectLogs[0];

    this.selectedEmail({
      emailAddress: email.recipientEmailAddress,
      dateSent: formatDateTime(email.time),
      recipient: email.recipient,
      subject: email.subject,
      status: email.formattedStatus,
      fullHtml: `${this.emailHeader}${email.messageBody}${this.emailFooter}`
    });
  }

  resendEmail = () => {
    // Open confirm dialog.
    console.log(this.selectedEmailMessageId());

    dialog({
      title: 'Resend Email Confirmation',
      message: 'You are about to resend this email message to the recipient.  You should only do this if the recipient requires a copy or did not receive it the first time.',
      cancelText: 'Cancel',
      cancelAction: () => { dialog(null); },
      submitText: 'Yes, Resend',
      submitAction: () => {
        reportService.resendEmail(this.selectedEmailMessageId())
          .then(result => {

            dialog(null);
          });
      }
    });
  }

  goto = {
    userDetails: (id: number): void => router.goto(routes.adminUserDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-email-logs',
  viewModel: AdminEmailLogs,
  template: require('./email-logs.html')
};
