import * as ko from 'knockout';
import { Computed, Observable, ObservableArray } from 'knockout';
import { InvoiceItemRequestModel, OrderItemResponse, shipmentsService, UnreceivedPurchaseOrderResponse } from '../../../api/service.shipments';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatPrice, formatPriceNumber, formatWeight, stringPadStart } from '../../../utils/format';
import { dialog } from '../../app';
import { ConfirmDialogParams } from '../../elements/bp-confirm-dialog';
import { DialogParams, FieldType } from '../../elements/bp-dialog';
import suppliers from '../admin/suppliers';

type IdInfo = {
  Id: number | null;
  Name: string;
}

class ShipmentsReceive {
  readonly dialog: Observable<DialogParams | null>;
  readonly confirmDialog: Observable<ConfirmDialogParams | null>;

  readonly title: Observable<string>;

  readonly supplierInvoiceId: Observable<number>;
  readonly invoiceNumber: Observable<string>;
  readonly supplierName: Observable<string | null>;
  readonly itemsHeader: Computed<string>;

  readonly shipment = ko.observable<{
    supplierId: number;
    supplier: string;
    invoiceDate: string;
    firstName: string;
    lastName: string;
    notes: string;
    emailLogId: number;
  }>();

  readonly supplierProducts = ko.observableArray<any>();
  readonly invoiceItems = ko.observableArray<any>();
  readonly formattedInvoiceTotal: Observable<string>;
  readonly invoiceTotal: Observable<number>;

  constructor(params: any) {
    this.dialog = ko.observable(null);
    this.confirmDialog = ko.observable(null);

    this.title = ko.observable('Shipments / Receive');

    this.supplierInvoiceId = ko.observable(params.supplierInvoiceId);
    this.invoiceNumber = ko.observable(params.invoiceNumber);
    this.supplierName = ko.observable(null);
    this.itemsHeader = ko.computed(() => `Items ordered from ${this.supplierName() ? this.supplierName() : 'supplier'} on invoice/sales order # ${this.invoiceNumber()}`);

    this.loadInvoice();
    this.formattedInvoiceTotal = ko.observable('');
    this.invoiceTotal = ko.observable(0);

    this.invoiceTotal.subscribe(() => {
      this.formattedInvoiceTotal(formatPrice(this.invoiceTotal()));
    });

    this.shipment.subscribe(() => {
      const shipment = this.shipment();

      if (shipment == null || shipment.supplierId == null) {
        return;
      }

      if (this.supplierProducts().length === 0) {
        this.loadSupplierProducts();
      }

    });

    this.invoiceItems.subscribe(() => {
      let invoiceItemsTotal = 0;

      this.invoiceItems().forEach(r => {
        const unitPrice = (r.unitWeight * r.pricePerKg);
        const lineTotal = unitPrice * r.quantity;

        invoiceItemsTotal += lineTotal;
      });

      this.formattedInvoiceTotal(`Invoice Total: ${formatPrice(invoiceItemsTotal)}`);
    });
  }

  loadInvoice = () => {
    shipmentsService.getById(this.supplierInvoiceId())
      .then(result => {
        this.shipment({
          supplierId: result.supplierId,
          supplier: result.supplier,
          firstName: result.firstName,
          lastName: result.lastName,
          invoiceDate: formatDate(result.time),
          notes: result.purchaseOrderNotes,
          emailLogId: result.emailLogId
        });
      });

    shipmentsService.getInvoiceItems(this.supplierInvoiceId())
      .then(results => {
        const orderItems = results.map(r => {

          const formattedPrice = ko.observable(formatPriceNumber(r.pricePerKg));
          const originalPrice = formatPriceNumber(r.pricePerKg);

          const priceChange = ko.pureComputed(() => {
            if (formattedPrice() !== originalPrice) {
              //Price changed

              const diff = formattedPrice() - originalPrice;

              return (diff < 0 ? 'DOWN ' : 'UP ') + (Math.abs(diff) / originalPrice * 100).toFixed(2) + '%';
            }
            return '';
          });

          return {
            ...r,
            supplierProductId: r.supplierProductId,
            productName: r.supplierProduct,
            originalQuantity: r.quantity,
            formattedPrice: formattedPrice().toFixed(2),
            priceChange: priceChange,
            formattedOriginalPrice: formatPrice(r.pricePerKg),
            originalPrice: originalPrice,
            unitType: r.unitType,
            formattedUnitWeight: formatWeight(r.unitWeight)
          };
        });

        this.invoiceItems(orderItems);
      });
  }

  loadSupplierProducts = () => {
    const shipment = this.shipment();

    if (shipment == null || shipment.supplierId == null) {
      return;
    }

    suppliersService.getSupplierProductsById(shipment.supplierId)
      .then(results => {
        const products = results.map(r => ({ ...r }));
        this.supplierProducts(products);
      });
  }

  addProduct = () => {
    const initMessage = 'Enter the details of the additional products being added to the shipment';
    const message = ko.observable(initMessage);

    let errors = '';

    const availableIds = this.supplierProducts()
      .map(s => s.supplierProductId)
      .filter(p => !this.invoiceItems()
        .map(s => s.supplierProductId)
        .includes(p));

    const availableOptions = this.supplierProducts().filter(p => availableIds.includes(p.supplierProductId)).map(s => ({ Id: s.supplierProductId, Name: s.name }));

    const productIdField: FieldType<IdInfo> = {
      title: 'Product',
      type: 'dropdown',
      value: ko.observable(0),
      options: availableOptions,
      optionsText: (o: any) => o.Name,
      optionsValue: (o: any) => o.Id,
      optionsCaption: ko.observable('Select')
    };

    const quantityField: FieldType<number> = {
      title: 'Quantity received',
      type: 'number',
      value: ko.observable(0)
    };

    const priceField: FieldType<number> = {
      title: 'Price per Kg (exc GST)',
      type: 'number',
      value: ko.observable(0)
    };

    this.dialog({
      title: 'Add product to order',
      message: message,
      fields: ko.observableArray([productIdField, quantityField, priceField]),
      cancelText: 'Cancel',
      cancelAction: () => this.dialog(null),
      submitText: 'Add',
      submitAction: () => {

        const productId = productIdField.value() as number;
        const quantity = quantityField.value() as number;
        const price = priceField.value() as number;

        if (productId == null || productId === 0) {
          errors += 'You must select a product';
        }

        if (quantity == null || quantity === 0) {
          errors += 'You must enter a quantity';
        }

        if (price == null || price === 0) {
          errors += 'You must enter the purchase price of this product, excluding GST';
        }

        if (errors !== '') {
          message('<br />' + initMessage + '<span style="color: red;"><br />' + errors + '</span>');
          return;
        }

        const items = this.invoiceItems();

        const product = this.supplierProducts()
          .filter(p => p.supplierProductId === productId)
          .map(r => {
            
            const formattedPrice = ko.observable(formatPriceNumber(Number(price)));
            const originalPrice = formatPriceNumber(r.pricePerKg);

            console.log('r', r);

            return {
              ...r,
              supplierProductId: r.supplierProductId,
              productName: r.name,
              originalQuantity: 0,
              formattedPrice: formattedPrice().toFixed(2),
              priceChange: '',
              formattedOriginalPrice: formatPrice(r.pricePerKg),
              originalPrice: originalPrice,
              unitType: r.unitType,
              formattedUnitWeight: formatWeight(r.unitWeight),
              quantity: quantity
            };
          })[0];

        items.push(product);

        console.log(items);
        this.invoiceItems(items);

        this.dialog(null);
      }
    });

    productIdField.value.subscribe(() => {
      const productId = productIdField.value() ?? 0;

      if (productId > 0) {
        const product = this.supplierProducts().filter(s => s.supplierProductId === productId)[0];

        priceField.value(product.pricePerKg);
      }
    });
  }

  actions = {
    receiveOrder: (): void => {

      // Confirm saving.
      const invoiceNumber = decodeURI(this.invoiceNumber());

      this.dialog({
        title: 'Confirm receipt of order',
        message: `Invoice Number: ${invoiceNumber}`,
        fields: ko.observableArray<string>([]),
        cancelText: 'Cancel',
        cancelAction: () => this.dialog(null),
        submitText: 'Confirm receipt',
        submitAction: () => {
          var items: InvoiceItemRequestModel[] = [];

          let errors = '';

          this.invoiceItems().forEach(ii => {

            items.push({ supplierProductId: ii.supplierProductId, quantity: ii.quantity, pricePerKg: ii.formattedPrice });

            if (ii.formattedPrice === '0' || ii.formattedPrice === '' || ii.formattedPrice === 0) {
              errors += `Cost price for ${ii.productName} must have a value.<br />`;
            }
          });

          if (errors !== '') {

            this.confirmDialog({
              title: 'Failed to save invoice',
              message: errors,
              submitText: 'Ok',
              submitAction: () => this.confirmDialog(null)
            });
            return;
          }

          shipmentsService.saveSupplierInvoice(this.supplierInvoiceId(), this.invoiceNumber(), items)
            .then((result) => {

              if (!result) {
                this.confirmDialog({
                  title: 'Something went wrong when trying to save invoice',
                  message: errors,
                  submitText: 'Try again.',
                  submitAction: () => this.goto.cancel()
                });
                return;
              } else {

                this.goto.shipments();
                this.dialog(null);
              }
            })
            .catch(e => console.log(e));
        }
      });
    }
  }

  goto = {
    shipments: (): void => router.goto(routes.shipmentsDashboard.interpolate({})),
    cancel: (): void => router.goto(routes.shipmentsDashboard.interpolate({})),
  }
}

export default {
  name: 'bp-shipments-receive',
  viewModel: ShipmentsReceive,
  template: require('./receive.html')
};
