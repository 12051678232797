import * as ko from 'knockout';
import { Observable } from 'knockout';
import { shipmentsService } from '../../../api/service.shipments';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatPrice } from '../../../utils/format';

export type PurchaseOrderQuantities = {
  supplierProductId: number;
  quantity: number;
}

class ShipmentsCreatePurchaseOrder {
  readonly title: Observable<string>;

  readonly supplierId: Observable<number>;

  readonly supplierProducts: ko.ObservableArray<any>;
  readonly purchaseOrderNotes: ko.Observable<string>;
  readonly totalItems: ko.Observable<number>;
  readonly totalCost: ko.Computed<string>;

  readonly errors: {
    response: Observable<string>;
  }

  constructor(params: any) {
    this.title = ko.observable('Shipments / Create Purchase Order');

    this.supplierId = ko.observable(params.id);

    this.supplierProducts = ko.observableArray();
    this.purchaseOrderNotes = ko.observable('');

    this.totalItems = ko.observable(0);
    this.totalCost = ko.pureComputed(() => formatPrice(this.supplierProducts().map((t: any) => t.orderQty() * (t.lastPurchasePrice * t.unitWeight)).reduce((p, n) => p + n, 0)));

    this.errors = {
      response: ko.observable(''),
    };

    this.loadProducts();
  }

  loadProducts = () => {
    suppliersService
      .getProducts(this.supplierId())
      .then(result => {
        const productMap = result.map(s => {

          const qty = ko.observable(0);
          const perKgPrice = s.lastPurchasePrice * s.unitWeight;

          const lineTotal = ko.pureComputed(() => formatPrice(qty() * perKgPrice));
          const orderedProduct = ko.pureComputed(() => qty() > 0);

          return {
            ...s,
            orderQty: qty,
            orderedProduct: orderedProduct,
            lineTotal: lineTotal,
            formattedLastPurchasePrice: formatPrice(s.lastPurchasePrice)
          }
        });

        this.supplierProducts(productMap);
      })
      .catch(e => console.log(e));
  }

  actions = {
    createOrder: (): void => {
      const items: PurchaseOrderQuantities[] = [];

      this.supplierProducts().forEach(v => {

        if (v.orderQty() > 0) {
          items.push({ supplierProductId: v.supplierProductId, quantity: Number(v.orderQty()) });
        }
      });

      if (items.length == 0) {
        // Fail
        alert('You can not create a purchase order with no items in it.  Please check that you have correctly specified the quantities you require.');

        return;
      }

      shipmentsService.createOrder(this.supplierId(), this.purchaseOrderNotes(), items)
        .then((supplierId) => {
          this.errors.response('');

          if (supplierId) {
            this.goto.cancel();
          }
        })
        .catch((e) => {
          alert(`Create supplier failed. ${e}`);
          this.errors.response(`Create supplier failed. ${e}`);
        });
    }
  }

  goto = {
    cancel: (): void => router.goto(routes.shipmentsDashboard.interpolate({})),
  }

}

export default {
  name: 'bp-shipments-create-purchase-order',
  viewModel: ShipmentsCreatePurchaseOrder,
  template: require('./create-purchase-order.html')
};
