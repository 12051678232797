import * as ko from 'knockout';
import { Observable } from 'knockout';
import { DialogParams } from '../../elements/bp-dialog';

class MainIndex {

  readonly dialog: Observable<DialogParams | null>;

  readonly locations: ko.ObservableArray<any> = ko.observableArray();
  readonly productLocations: ko.ObservableArray<any> = ko.observableArray();

  constructor(params: any) {
    this.dialog = ko.observable(null);

    this.locations = params.locations;
    this.productLocations = params.productLocations;
  }

  goto = {
    //login: (): void => router.goto(routes.login.interpolate({})),
    //register: (): void => router.goto(routes.register.interpolate({}))
  }
}

export default {
  name: 'bp-main-index',
  viewModel: MainIndex,
  template: require('./index.html')
};
