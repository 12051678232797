import * as ko from 'knockout';
import adminDashboard from './admin';
import batches from './batches';
import main from './main';
import shipments from './shipments';
import suppliers from './suppliers';
import weborders from './weborders';

export default function () {
  ko.components.register(adminDashboard.name, adminDashboard);
  ko.components.register(batches.name, batches);
  ko.components.register(main.name, main);
  ko.components.register(shipments.name, shipments);
  ko.components.register(suppliers.name, suppliers);
  ko.components.register(weborders.name, weborders);
}
