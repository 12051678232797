import * as ko from 'knockout';
import bpButton from './bp-button';
import bpCalculatorField from './bp-calculator-field';
import bpConfirmDialog from './bp-confirm-dialog';
import bpDialog from './bp-dialog';
import bpHeaderLink from './bp-header-link';
import bpImg from './bp-img';
import bpLink from './bp-link';
import bpNull from './bp-null';
import bpQuickDialog from './bp-quick-dialog';
import bpSearchableDropdown from './bp-searchable-dropdown';
import bpSidebarHistory from './bp-sidebar-history';
import bpSidebarLink from './bp-sidebar-link';
import bpTimeDate from './bp-time-date';


export default function () {
  ko.components.register(bpButton.name, bpButton);
  ko.components.register(bpCalculatorField.name, bpCalculatorField);
  ko.components.register(bpConfirmDialog.name, bpConfirmDialog);
  ko.components.register(bpDialog.name, bpDialog);
  ko.components.register(bpHeaderLink.name, bpHeaderLink);
  ko.components.register(bpImg.name, bpImg);
  ko.components.register(bpLink.name, bpLink);
  ko.components.register(bpNull.name, bpNull);
  ko.components.register(bpQuickDialog.name, bpQuickDialog);
  ko.components.register(bpSearchableDropdown.name, bpSearchableDropdown);
  ko.components.register(bpSidebarHistory.name, bpSidebarHistory);
  ko.components.register(bpSidebarLink.name, bpSidebarLink);
  ko.components.register(bpTimeDate.name, bpTimeDate);
}
