import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { batchesService, BatchItem } from '../../../api/service.batches';
import { shipmentsService } from '../../../api/service.shipments';
import { suppliersService } from '../../../api/service.suppliers';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatDateTime, formatWeight } from '../../../utils/format';
import app, { dialog, quickDialog } from '../../app';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';
import { QuickDialogParams } from '../../elements/bp-quick-dialog';

class BatchesCreate {
  readonly title: Observable<string>;
  readonly dialog: Observable<DialogParams | null>;
  readonly quickDialog: Observable<QuickDialogParams | null>;
  readonly productId: Observable<number>;
  readonly quantity: Observable<number>;
  readonly errors: Observable<string>;

  readonly recipeItems: ObservableArray<any>;

  constructor(params: any) {
    console.log('params', params);
    this.title = ko.observable('Batches / Create');

    app.checkBeforeNavigation(true);

    this.productId = ko.observable(params.id);
    this.quantity = ko.observable(params.quantity);
    this.errors = ko.observable('');

    this.dialog = ko.observable(null);
    this.quickDialog = ko.observable(null);

    this.recipeItems = ko.observableArray();

    this.loadRecipe();
  }

  loadRecipe = () => {

    batchesService.getRecipe(this.productId(), this.quantity())
      .then(result => {
        console.log('result', result);

        this.title(`Batches / Create ${formatWeight(this.quantity())} - ${result.product}`);

        const recipeMap = result.recipeItems.map(r => {

          const shipmentNumber = ko.observable<number>(null);
          const label = ko.observable(`${r.isDefault ? '* Required' : ''}`);
          const isValid = ko.observable(!r.isDefault);

          const quantity = this.quantity() * r.quantity;
          const weightUsed = ko.observable<number>();
          if (r.isDefault) {
            weightUsed(quantity);
          }

          const errors = ko.observable('');

          shipmentNumber.subscribe(() => {
            label(shipmentNumber() ? `SN${shipmentNumber()}` : `${r.isDefault ? '* Required' : ''}`);
            isValid(shipmentNumber() != null);
          });

          return {
            ...r,
            shipmentNumber: shipmentNumber,
            weightUsed: weightUsed,
            icon: shipmentNumber() ? 'icon/edit' : 'icon/search',
            label: label,
            errors: errors
          }
        });

        this.recipeItems(recipeMap);
      })
      .catch(e => console.log(e));
  };

  goto = {
    cancel: (): void => router.goto(routes.batchesDashboard.interpolate({})),
    batchDetails: (batchId: number): void => router.goto(routes.batchesDashboard.interpolate({ id: batchId })),
  };

  actions = {
    createBatch: (): void => {
      // Save the batch and redirect to batchDetails.
      let batchItems: BatchItem[] = [];

      let isValid = ko.observable(true);

      this.recipeItems().forEach(r => {
        r.errors('');
        let errors = '';

        if (r.isDefault) {
          // Must have shipment number and weight.
          if (r.shipmentNumber() == null) {
            errors += `<li>You must specify a shipment number for ${r.supplierProduct}</li>`;
          }

          if ((r.weightUsed() as number) < 0) {
            errors += `<li>You must specify the weight of ${r.supplierProduct} used</li>`;
          }
        } else {
          if ((r.weightUsed() as number) > 0 && r.shipmentNumber() == null) {
            errors += `<li>You must specify a shipment number for ${r.supplierProduct} if you are using it</li>`;
          }
        }

        if (errors !== '') {
          isValid(false);
          r.errors(`<ul>${errors}</ul>`);
        }

        if (r.weightUsed() > 0) {
          batchItems.push({ supplierProductId: r.supplierProductId, shipmentId: r.shipmentNumber(), quantityUsed: r.weightUsed() });
        }
      });

      if (isValid()) {
        batchesService.create(this.productId(), this.quantity(), batchItems)
          .then((result: any) => {

            if (result.success) {
              app.checkBeforeNavigation(false);
              this.goto.batchDetails(result.batchId);
            } else {
              this.errors(result.message);
            }

          })
          .catch((e) => { console.log(e); });
      }

    },

    getShipmentNumber: (supplierProductId: number): void => {
      var recipeItem = this.recipeItems().filter(r => r.supplierProductId == supplierProductId)[0];

      // lookup recent shipments for this product.
      // limit to 3 results.

      shipmentsService.getshipmentsForProduct(supplierProductId, 3)
        .then((results) => {

          if (results.length < 1) {
            this.errors('Error: There are no received shipments that contain this item.');

            return;
          }

          const recipeMap = results.map(r => {
            return {
              Name: `${formatDate(r.receivedTime)}    SN${r.shipmentId} - ${r.quantity}Kgs`,
              Id: r.shipmentId
            }
          });

          const items = ko.observableArray(recipeMap);

          const initValue = items()[0].Id;

          const shipmentsField: FieldType<any> = {
            title: 'Select shipment to use',
            type: 'dropdown',
            value: ko.observable(initValue),
            options: items,
            optionsText: (o) => o && o.Name,
            optionsValue: (o) => o && o.Id,
            optionsCaption: ko.observable('-- Select --')
          }

          dialog({
            title: 'Select Shipment',
            message: ko.observable(''),
            fields: ko.observableArray([shipmentsField]),
            cancelText: 'Cancel',
            submitText: 'Select',
            submitAction: () => {

              const shipmentId = shipmentsField.value();

              recipeItem.shipmentNumber(shipmentId);

              dialog(null);
            }
          });

        });
    },
  };
}

export default {
  name: 'bp-batches-create',
  viewModel: BatchesCreate,
  template: require('./create.html')
};
