import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

export const reportService = {
  getEmailLogs,
  resendEmail,
  sendAdhocEmail
};

type EmailLogResponseType = {
  emailHeader: string;
  messages: EmailMessageType[];
  emailFooter: string;
}

type EmailMessageType = {
  outboundEmailMessageId: number;
  userId: number;
  firstName: string;
  lastName: string;
  recipientEmailAddress: string;
  time: string;
  subject: string;
  messageBody: string;
  lastAttemptTime: string;
  attemptCount: number;
  succeeded: boolean;
  completed: boolean;
  mailGunStatus: string;
  mailGunSentTime: string;
}

function getEmailLogs(): Promise<EmailLogResponseType> {
  return fetchHandler(`${config.apiUrl}/Report/GetEmailLogs`, authPostDataRequest({}))
    .then((result) => { return result; });
}

function resendEmail(outboundEmailMessageId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/Report/ResendEmailMessage`, authPostDataRequest({ outboundEmailMessageId }))
    .then((result) => { return result; });
}

function sendAdhocEmail(emails: string, emailsWithCredits: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/System/AdHocEmail`, authPostDataRequest({ emails, emailsWithCredits }))
    .then((result) => { return result; });
}