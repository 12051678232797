import * as ko from 'knockout';
import { Computed, Observable, ObservableArray } from 'knockout';
import { idInfosService } from '../../../api/service.idInfos';
import { locationsService } from '../../../api/service.locations';
import { ShippingMethodResponse, webOrdersService } from '../../../api/service.weborders';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDate, formatDateTime, formatPhoneNumber, formatPrice, formatTime } from '../../../utils/format';
import { calculateOrderProgressClass } from '../../../utils/helpers';
import { dialog } from '../../app';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';

class WebordersDetails {
  readonly title: Observable<string>;
  readonly webOrderId: Observable<number>;
  readonly viewType: Observable<string>;
  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;
  readonly paidIcon: Computed<string>;

  readonly userPIN = ko.observable<string>('');

  readonly customerFirstName = ko.observable<string>();
  readonly customerLastName = ko.observable<string>();
  readonly customerEmailAddress = ko.observable<string>();
  readonly paymentMethodId = ko.observable<number>(0);
  readonly paymentMethod = ko.observable<string>();
  readonly shippingMethodId = ko.observable<number>(0);
  readonly shippingMethod = ko.observable<string>();
  readonly isFreightOption = ko.observable<boolean>();
  readonly isDeliveryOption = ko.observable<boolean>();
  readonly isPickupOption = ko.observable<boolean>();
  readonly orderStatusId = ko.observable<number>(0);
  readonly orderStatus = ko.observable<string>();
  readonly numberContainers = ko.observable<number>(0);
  readonly imageClass = ko.observable<string>();
  readonly progressIcon = ko.observable<string>();
  readonly paymentReceivedTime = ko.observable<number | null>();
  readonly addressStreet = ko.observable<string>();
  readonly addressSuburb = ko.observable<string>();
  readonly addressCity = ko.observable<string>();
  readonly addressPostCode = ko.observable<string>();
  readonly orderNotes = ko.observable<string>();
  readonly pickupDate = ko.observable<number>();
  readonly pickupTimeSlot = ko.observable<string>();
  readonly invoiceCount = ko.observable<number>();

  readonly formattedCustomerName = ko.observable<string>();
  readonly formattedEmailAddress = ko.observable<string>();
  readonly formattedOrderTotal = ko.observable<string>();
  readonly totalContainers = ko.observable<number>();
  readonly formattedPickupTime = ko.observable<string>();
  readonly formattedPickupDate = ko.observable<string>();
  readonly formattedInvoicing = ko.observable<string>('Invoicing (0)');

  readonly webOrderItems: ko.ObservableArray<any>;
  readonly userActions: ko.ObservableArray<any>;
  readonly phoneNumbers: ko.ObservableArray<any>;
  readonly freightTickets: ko.ObservableArray<any>;
  readonly locationList: ObservableArray<IdInfo>;
  readonly statusList: ObservableArray<IdInfo>;
  readonly paymentMethodList: ObservableArray<IdInfo>;
  readonly shippingMethodList: ko.ObservableArray<IdInfo & ShippingMethodResponse>;
  readonly freightProviderList: ObservableArray<IdInfo>;
  readonly productList: ObservableArray<IdInfo>;

  constructor(params: any) {
    this.title = ko.observable('Web Order');
    this.webOrderId = ko.observable(params.id);
    this.viewType = ko.observable(params.type);
    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.webOrderItems = ko.observableArray();
    this.userActions = ko.observableArray();
    this.phoneNumbers = ko.observableArray();
    this.freightTickets = ko.observableArray();
    this.locationList = ko.observableArray();
    this.statusList = ko.observableArray();
    this.paymentMethodList = ko.observableArray();
    this.shippingMethodList = ko.observableArray();
    this.freightProviderList = ko.observableArray();
    this.productList = ko.observableArray();

    this.loadOrder();
    this.loadOrderItems();
    this.loadUserActions();

    this.paidIcon = ko.pureComputed<string>(() => (this.paymentReceivedTime() != null ? 'icon/paid' : 'icon/empty-space'));

    if (this.locationList().length == 0) {
      this.loadPickLocations();
    }

    if (this.statusList().length == 0) {
      this.loadStatuses();
    }

    if (this.paymentMethodList().length == 0) {
      this.loadPaymentMethods();
    }

    if (this.shippingMethodList().length == 0) {
      this.loadShippingMethods();
    }

    if (this.freightProviderList().length == 0) {
      this.loadFreightProviders();
    }

    if (this.productList().length == 0) {
      this.loadProducts();
    }
  };

  loadOrder = () => {
    webOrdersService.getById(this.webOrderId())
      .then(order => {
        this.customerFirstName(order.customerFirstName);
        this.customerLastName(order.customerLastName);
        this.customerEmailAddress(order.customerEmailAddress);
        this.paymentMethodId(order.paymentMethodId);
        this.paymentMethod(order.paymentMethod);
        this.shippingMethodId(order.shippingMethodId);
        this.isFreightOption(order.isFreightOption);
        this.isDeliveryOption(order.isDeliveryOption);
        this.isPickupOption(order.isPickupOption);
        this.shippingMethod(order.shippingMethod);
        this.paymentReceivedTime(order.paymentReceivedTime);
        this.orderNotes(order.orderNotes);
        this.invoiceCount(order.invoiceCount);

        this.formattedInvoicing(`Invoicing (${this.invoiceCount()})`);

        if (order.address != null) {
          this.addressStreet(order.address.street);
          this.addressSuburb(order.address.suburb);
          this.addressCity(order.address.city);
          this.addressPostCode(order.address.postCode);
        }

        this.formattedCustomerName(`${this.customerFirstName()} ${this.customerLastName()}`);
        this.formattedEmailAddress(`<a href='mailto:${this.customerEmailAddress()}?subject=Woofles Web Order ${this.webOrderId()}'>${this.customerEmailAddress()}</a>`);
        this.orderStatus(order.status);

        this.formattedPickupDate(order.pickupTimeSlot ? formatDate(order.pickupTimeSlot) : '');
        this.formattedPickupTime(order.pickupTimeSlot ? formatTime(order.pickupTimeSlot) : '');

        this.progressIcon('icon/battery-full');

        if (order.phoneNumbers != null) {
          const phoneNumbers = order.phoneNumbers.map(p => {
            return {
              ...p,
              formattedNumber: formatPhoneNumber(p.number)
            }
          });

          this.phoneNumbers(phoneNumbers);
        }

        if (order.freightTickets != null) {
          const freightTickets = order.freightTickets.map(f => {
            return {
              ...f,
              formattedTicketUrl: `<a href='${f.trackingUrl}${f.ticketNumber}' target='_blank'>${f.ticketNumber}</a>`,
            }
          });

          this.freightTickets(freightTickets);
        }

      })
      .catch(e => console.log(e));
  };

  loadOrderItems() {
    webOrdersService.getOrderItems(this.webOrderId())
      .then(results => {

        let maxContainerNumber = 0;
        let orderTotal = 0;
        let totalSupplied = 0;
        let totalOrdered = 0;

        const orderItemMap = results.map(r => {

          var maxContainer = Math.max(...r.containers);

          if (maxContainer > maxContainerNumber) {
            maxContainerNumber = maxContainer;
          }

          const lineTotal = r.quantityOrdered * r.unitPrice;
          orderTotal += lineTotal;
          totalSupplied += r.quantitySupplied;
          totalOrdered += r.quantityOrdered;

          return {
            ...r,
            formattedUnitPrice: formatPrice(r.unitPrice),
            formattedLineTotal: formatPrice(lineTotal),
            formattedContainers: r.containers.join(','),
            backgroundColor: r.quantitySupplied > 0 && r.quantitySupplied < r.quantityOrdered
              ? 'orange'
              : r.quantitySupplied < r.quantityOrdered
                ? 'red'
                : 'green',

          }
        });

        this.formattedOrderTotal(formatPrice(orderTotal));
        this.webOrderItems(orderItemMap);
        this.imageClass(calculateOrderProgressClass(totalSupplied, totalOrdered));
        this.totalContainers(maxContainerNumber);
      })
      .catch(e => console.log(e));
  };

  loadUserActions() {
    webOrdersService.getUserActions(this.webOrderId())
      .then(results => {
        const userActionMap = results.map(r => {
          return {
            ...r,
            formattedTime: formatDateTime(r.time)
          }
        });

        this.userActions(userActionMap);
      })
      .catch(e => console.log(e));
  };

  loadPickLocations() {
    locationsService.getAll()
      .then(locations => {
        this.locationList(locations.filter(l => l.isCanPickFrom).map(c => { return ({ Id: c.locationId, Name: c.name }) }));
      });
  };

  loadStatuses() {
    webOrdersService.getWebOrderStatuses()
      .then(statuses => {
        this.statusList(statuses.map(s => { return ({ Id: s.id, Name: s.name }) }));
      });
  };

  loadPaymentMethods() {
    idInfosService.getPaymentMethods()
      .then(paymentMethods => {
        this.paymentMethodList(paymentMethods.map(s => { return ({ Id: s.id, Name: s.name }) }));
      });
  };

  loadShippingMethods = () => {
    webOrdersService.getShippingMethods()
      .then(shippingMethods => {
        this.shippingMethodList(shippingMethods.map(c => { return ({ ...c, Id: c.id, Name: c.name }) }));
      });
  };

  loadFreightProviders() {
    idInfosService.getFreightProviders()
      .then(freightProviders => {
        this.freightProviderList(freightProviders.map(s => { return ({ Id: s.id, Name: s.name }) }));
      });
  };

  loadProducts() {
    idInfosService.getProducts()
      .then(products => {
        this.productList(products.map(s => { return ({ Id: s.id, Name: s.name }) }));
      });
  };

  goto = {
    orders: (): void => router.goto(routes.webordersDashboard.interpolate({ type: this.viewType() })),
    invoices: (): void => router.goto(routes.webordersInvoices.interpolate({ id: this.webOrderId() })),
  };

  actions = {
    updateCustomer: (): void => {
      const message = ko.observable('');

      const model = {
        userPin: ko.observable<string>(this.userPIN()),
        emailAddress: ko.observable(this.customerEmailAddress()),
        shippingMethodId: ko.observable(this.shippingMethodId()),
        paymentMethodId: ko.observable(this.paymentMethodId()),
        deleteFreightTickets: ko.observable(false)
      };

      const confirmField: FieldType<string> = {
        title: 'Confirm',
        type: 'display',
        value: ko.observable('You are about to edit the details of this order.')
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      const paymentMethodField: FieldType<IdInfo> = {
        title: 'Payment Method',
        type: 'dropdown',
        value: model.paymentMethodId,
        options: this.paymentMethodList(),
        optionsText: (o: any) => o.Name,
        optionsValue: (o: any) => o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      const deleteFreightField: FieldType<boolean> = {
        title: 'There are freight tickets attached to this order that will become part of the invoice.  Do you want to delete them?',
        type: 'checkbox',
        visible: ko.observable(false),
        value: model.deleteFreightTickets,
      };

      const shippingMethodField: FieldType<IdInfo> = {
        title: 'Shipping Method',
        type: 'dropdown',
        value: model.shippingMethodId,
        options: this.shippingMethodList(),
        optionsText: (o: any) => o.Name,
        optionsValue: (o: any) => o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      ko.computed(() => {
        const shippingMethod: IdInfo & ShippingMethodResponse = this.shippingMethodList().filter(c => c.Id == model.shippingMethodId())[0];

        const deleteFreightFieldVisible = deleteFreightField.visible;

        if (deleteFreightFieldVisible) { deleteFreightFieldVisible(shippingMethod && this.freightTickets().length > 0 && shippingMethod.isPickupOption); }
      });

      dialog({
        title: 'Set order details',
        message: message,
        fields: ko.observableArray([confirmField, userPinField, paymentMethodField, shippingMethodField, deleteFreightField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (model.paymentMethodId() == null) {
            errors += 'Payment method cannot be empty. <br />';
          }

          if (model.shippingMethodId() == null) {
            errors += 'Shipping method cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.setOrderDetails(model.userPin(), this.webOrderId(), model.paymentMethodId(), model.shippingMethodId(), model.deleteFreightTickets())
            .then(result => {
              if (result.success) {
                this.loadOrder();
                this.loadUserActions();
                dialog(null);
              } else {
                message(`<div class="error"> - Failed to change status. ${result.message}</div>`);
              }
            });

        }
      });

    },

    updateStatus: (): void => {
      const message = ko.observable('');

      const model = {
        userPin: ko.observable<string>(this.userPIN()),
        statusId: ko.observable(this.orderStatusId())
      };

      const confirmField: FieldType<string> = {
        title: 'Confirm',
        type: 'display',
        value: ko.observable('You are about to change the status of this order.')
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      const statusField: FieldType<IdInfo> = {
        title: 'Order Status',
        type: 'dropdown',
        value: model.statusId,
        options: this.statusList(),
        optionsText: (o: any) => o.Name,
        optionsValue: (o: any) => o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      dialog({
        title: 'Set order status',
        message: message,
        fields: ko.observableArray([confirmField, userPinField, statusField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.setStatus(model.userPin(), this.webOrderId(), model.statusId())
            .then(result => {
              if (result.success) {
                this.loadOrder();
                this.loadUserActions();

                switch (model.statusId()) {
                  case 1:
                  case 2:
                  case 5:
                  case 6:
                  case 7:
                  case 8:
                  case 9:
                  case 10:
                    break;

                  default:
                    // Close current order and go back to order list.
                    this.goto.orders();
                    break;
                }

                dialog(null);
              } else {
                message(`<div class="error"> - Failed to change status. ${result.message}</div>`);
              }
            });
        }
      });
    },

    updatePickupSlot: (): void => {
      const message = ko.observable('');

      var currentTime = Date.now();
      var localTime = formatDate(currentTime) + 'T' + formatTime(currentTime);

      var pickupDate = ko.pureComputed(() => this.formattedPickupDate() ? this.formattedPickupDate() + 'T' + this.formattedPickupTime() : localTime.slice(0, 16));

      const model = {
        userPin: ko.observable<string>(this.userPIN()),
        pickupDate: ko.observable<string>(pickupDate()),
        emailCustomer: ko.observable<boolean>(true)
      };

      const confirmField: FieldType<string> = {
        title: 'Confirm',
        type: 'display',
        value: ko.observable('You are about to change the pickup slot for this order.')
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      const pickupField: FieldType<string> = {
        title: 'Pickup Date/Time',
        type: 'datetime',
        step: 300,
        value: model.pickupDate
      };

      const emailCustomerField: FieldType<boolean> = {
        title: 'Email customer with details?',
        type: 'checkbox',
        value: model.emailCustomer
      }

      dialog({
        title: 'Set pickup slot',
        message: message,
        fields: ko.observableArray([confirmField, userPinField, pickupField, emailCustomerField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.setPickupDate(model.userPin(), this.webOrderId(), model.pickupDate(), model.emailCustomer())
            .then(result => {
              if (result.success) {
                this.loadOrder();
                this.loadUserActions();

                dialog(null);
              } else {
                message(`<div class="error"> - Failed to set pickup date. ${result.message}</div>`);
              }
            });
        }
      });
    },

    setAsPaid: (): void => {
      const message = ko.observable('');

      const model = {
        userPin: ko.observable<string>('')
      };

      const confirmField: FieldType<string> = {
        title: 'Confirm',
        type: 'display',
        value: ko.observable('You are about to set this order to PAID.')
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      dialog({
        title: 'Set order to PAID',
        message: message,
        fields: ko.observableArray([confirmField, userPinField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.setPaid(model.userPin(), this.webOrderId())
            .then(result => {
              if (result.success) {
                this.loadOrder();
                this.loadUserActions();

                dialog(null);
              } else {
                message(`<div class="error"> - Failed to set order as paid.</div>`);
              }
            });
        }
      });
    },

    setAsUnpaid: () => {
      const message = ko.observable<string>('');

      const model = {
        userPin: ko.observable('')
      };

      const confirmField: FieldType<string> = {
        title: 'Confirm',
        type: 'display',
        value: ko.observable('You are about REVOKE payment for this order.  Are you sure?')
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      dialog({
        title: 'Set order to UNPAID',
        message: message,
        fields: ko.observableArray([confirmField, userPinField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.setUnpaid(model.userPin(), this.webOrderId())
            .then(result => {
              if (result.success) {
                this.paymentReceivedTime(null);
                this.loadUserActions();

                dialog(null);

                setTimeout(() => {
                  this.toast(null);
                }, 5000);
              } else {
                message(`<div class="error"> - Failed to revoke payment.</div>`);
              }
            });
        }
      });
    },

    addFreightTicket: () => {
      const message = ko.observable<string>('');

      const model = {
        userPin: ko.observable(this.userPIN()),
        freightProviderId: ko.observable(0),
        ticketNumber: ko.observable(''),
        excessTickets: ko.observable(''),
        weight: ko.observable(0),
        charge: ko.observable(0)
      };

      const confirmField: FieldType<string> = {
        title: 'Confirm',
        type: 'display',
        value: ko.observable('You are about to add freight tickets to this order.')
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      const freightProviderField: FieldType<IdInfo> = {
        title: 'Freight Provider',
        type: 'dropdown',
        value: model.freightProviderId,
        options: this.freightProviderList(),
        optionsText: (o: any) => o.Name,
        optionsValue: (o: any) => o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      const ticketNumberField: FieldType<string> = {
        title: 'Ticket Number',
        type: 'text',
        value: model.ticketNumber
      };

      const exessTicketsField: FieldType<string> = {
        title: 'Excess Ticket Numbers',
        type: 'text',
        value: model.excessTickets
      };

      const weightField: FieldType<string> = {
        title: 'Parcel weight (in Kgs)',
        type: 'number',
        value: model.weight
      };

      const chargeField: FieldType<string> = {
        title: 'Charge for parcel',
        type: 'number',
        value: model.charge
      };

      dialog({
        title: 'Add freight tickets to order',
        message: message,
        fields: ko.observableArray([confirmField, userPinField, freightProviderField, ticketNumberField, exessTicketsField, weightField, chargeField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (model.freightProviderId() == null) {
            errors += 'You must select the freight provider. <br />';
          }

          if (model.ticketNumber() == '') {
            errors += 'You must enter the ticket number - this will be sent to the customer. <br />';
          }

          if (model.weight() == null || model.weight() <= 0) {
            errors += 'You must enter the weight of this parcel in Kgs. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.addFreightTicket(model.userPin(), this.webOrderId(), model.freightProviderId(), model.ticketNumber(), model.excessTickets(), model.weight(), model.charge())
            .then(result => {
              if (result.success) {
                this.loadOrder();
                this.loadUserActions();

                dialog(null);
              } else {
                message(`<div class="error"> - Failed to add freight ticket.</div>`);
              }
            });
        }
      });
    },

    addProduct: () => {
      const message = ko.observable<string>('');

      const model = {
        userPin: ko.observable(this.userPIN()),
        productId: ko.observable<number>(),
        quantity: ko.observable<number>(1),
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      const productField: FieldType<IdInfo> = {
        title: 'Product',
        type: 'dropdown',
        value: model.productId,
        options: this.productList(),
        optionsText: (o: any) => o.Name,
        optionsValue: (o: any) => o.Id,
        optionsCaption: ko.observable('-- Select --')
      };

      const quantityField: FieldType<string> = {
        title: 'Quantity',
        type: 'number',
        value: model.quantity
      };

      dialog({
        title: 'Add product to order',
        message: message,
        fields: ko.observableArray([userPinField, quantityField, productField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (model.productId() == null) {
            errors += 'You must select the product. <br />';
          }

          if (model.quantity() < 1) {
            errors += 'Quantity must be greater than 0 <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.addProduct(model.userPin(), this.webOrderId(), model.productId()!, model.quantity())
            .then(result => {
              if (result.success) {
                this.loadOrder();
                this.loadUserActions();
                this.loadOrderItems();

                dialog(null);
              } else {
                message(`<div class="error"> - Failed to add product.</div>`);
              }
            });
        }
      });
    },
  }

  editOrderItem = (webOrderItemId: number) => {
    const message = ko.observable('');

    var webOrderItem: any = this.webOrderItems().filter((l) => l.webOrderItemId == webOrderItemId)[0];

    const model = {
      userPin: ko.observable<string>(this.userPIN()),
      quantity: ko.observable<number>(webOrderItem.quantityOrdered),
      containerNumbers: ko.observable<string>(webOrderItem.formattedContainers + ',')
    };

    const userPinField: FieldType<string> = {
      title: 'User Pin',
      type: 'userPin',
      value: model.userPin,
      setFocus: true
    };

    const quantityField: FieldType<string> = {
      title: 'Quantity',
      type: 'number',
      value: model.quantity,
    };

    const containerNumbersField: FieldType<string> = {
      title: 'Container/Parcel Numbers',
      type: 'text',
      value: model.containerNumbers,
    };

    dialog({
      title: `Update quantity for ${webOrderItem.product}`,
      message: message,
      fields: ko.observableArray([userPinField, quantityField, containerNumbersField]),
      cancelText: 'Cancel',
      submitText: 'Update',
      submitAction: () => {
        //Check fields
        let errors = '';

        if (model.userPin() === '') {
          errors += 'User  PIN cannot be empty. <br />';
        }

        if (model.quantity()! < webOrderItem.quantitySupplied) {
          errors += 'You cannot change the quantity to less than whats already been picked. <br />';
        }

        if (model.containerNumbers().length == 0) {
          errors += 'Container numbers cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);

          return;
        }

        webOrdersService.updateOrderItem(model.userPin(), this.webOrderId(), webOrderItemId, model.quantity()!, model.containerNumbers())
          .then(result => {
            if (result.success) {
              this.loadOrderItems();
              this.loadUserActions();

              dialog(null);
            } else {
              message(`<div class="error"> - Failed to update order item. ${result.message}</div>`);
            }
          });
      }
    });

  };

  deleteOrderItem = (webOrderItemId: number) => {
    const message = ko.observable('');

    var webOrderItem: any = this.webOrderItems().filter((l) => l.webOrderItemId == webOrderItemId)[0];

    const model = {
      userPin: ko.observable<string>(this.userPIN())
    };

    if (webOrderItem.quantitySupplied > 0) {
      dialog({
        title: `Remove ${webOrderItem.product} from the order.`,
        message: `<div class="error"><ul>You cannot remove an item that has already been picked.</ul></div>`,
        fields: ko.observableArray(),
        cancelText: 'Cancel',
        submitText: 'Ok',
        submitAction: () => {
          dialog(null);
        }
      });
    } else {

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      dialog({
        title: `Remove ${webOrderItem.product} from the order.`,
        message: message,
        fields: ko.observableArray([userPinField]),
        cancelText: 'Cancel',
        submitText: 'Update',
        submitAction: () => {
          //Check fields
          let errors = '';

          if (model.userPin() === '') {
            errors += 'User  PIN cannot be empty. <br />';
          }

          if (webOrderItem.quantitySupplied > 0) {
            errors += 'You cannot remove an item that has already been picked. <br />';
          }

          if (errors !== '') {
            message(`<div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.deleteOrderItem(model.userPin(), this.webOrderId(), webOrderItemId)
            .then(result => {
              if (result.success) {
                this.loadOrderItems();
                this.loadUserActions();

                dialog(null);
              } else {
                message(`<div class="error"> - Failed to delete order item. ${result.message}</div>`);
              }
            });
        }
      });
    }
  };

  pickProduct = (webOrderItemId: number) => {

    const origMessage = '';
    const message = ko.observable(origMessage);

    var webOrderItem: any = this.webOrderItems().filter((l) => l.webOrderItemId == webOrderItemId)[0];
    var remaining: number = webOrderItem.quantityOrdered - webOrderItem.quantitySupplied;

    const model = {
      userPin: ko.observable<string>(this.userPIN()),
      locationId: ko.observable<number>(null),
      quantityPicked: ko.observable<number>(remaining),
      batchNumber: ko.observable<string>(''),
      containerNumbers: ko.observable<string>(webOrderItem.formattedContainers + ',')
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'display',
      value: ko.observable(`Product: ${webOrderItem.product}`),
    };

    const quantityOrderedField: FieldType<string> = {
      title: 'Quantity Ordered',
      type: 'display',
      value: ko.observable(`Required: ${webOrderItem.quantityOrdered}`),
    };

    const quantitySuppliedField: FieldType<string> = {
      title: 'Quantity Supplied',
      type: 'display',
      value: ko.observable(`Supplied: ${webOrderItem.quantitySupplied}`),
    };

    const userPinField: FieldType<string> = {
      title: 'User PIN',
      type: 'userPin',
      value: model.userPin,
      setFocus: model.userPin() === ''
    };

    const quantityPickedField: FieldType<string> = {
      title: 'Quantity Picked',
      type: 'number',
      value: model.quantityPicked,
      setFocus: model.userPin() !== ''
    };

    const locationField: FieldType<IdInfo> = {
      title: 'Pick Location',
      type: 'dropdown',
      value: model.locationId,
      options: this.locationList(),
      optionsText: (o: any) => o.Name,
      optionsValue: (o: any) => o.Id,
      optionsCaption: ko.observable('-- Select --')
    }

    const batchNumberField: FieldType<string> = {
      title: 'Batch Number',
      type: 'text',
      value: model.batchNumber,
    };

    const containerNumbersField: FieldType<string> = {
      title: 'Container/Parcel Numbers',
      type: 'text',
      value: model.containerNumbers,
    };

    dialog({
      title: `Pick product`,
      message: message,
      fields: ko.observableArray([nameField, quantityOrderedField, quantitySuppliedField, userPinField, quantityPickedField, locationField, batchNumberField, containerNumbersField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        let errors = '';

        //Check fields
        if (model.userPin() === '') {
          errors += 'User  PIN cannot be empty. <br />';
        }

        if (model.quantityPicked() == null || isNaN(model.quantityPicked())) {
          errors += 'Quantity picked cannot be empty and must be a number. <br />';
        }

        if (model.locationId() == null) {
          errors += 'Pick location cannot be empty. <br />';
        }

        if (model.batchNumber().length == 0) {
          errors += 'Batch number cannot be empty. <br />';
        }

        if (model.containerNumbers().length == 0) {
          errors += 'Container numbers cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`<div class="error"><ul>${errors}</ul></div>`);

          return;
        } else {
          message('');
        }

        webOrdersService.savePickings(model.userPin(), this.webOrderId(), webOrderItemId, webOrderItem.productId, model.locationId()!, model.quantityPicked(), model.batchNumber(), model.containerNumbers())
          .then(result => {
            console.log(result);


            if (result.success) {
              dialog(null);

              this.toast('Items successfully picked');

              this.userPIN(model.userPin());

              this.loadOrderItems();
              this.loadUserActions();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + `<br><div class="error"> - ${result.message}.</div>`);
            }
          });
      },
    });
  }
}

export default {
  name: 'bp-weborders-details',
  viewModel: WebordersDetails,
  template: require('./details.html')
};
