import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

export const productsService = {
  getAll,
  getAllManufactured,
  getAllAccessories,
  getById,
  createProduct,
  deactivateProduct,
  reactivateProduct,
  getProductCategories,
  getProductGroupings,
  getProductRecipes,
  createBarcode,
  deleteBarcode,
  updateProduct,
  getProductImages,
  getProductBarcodes,
  uploadImage,
  setPrimaryImage,
  deleteImage,
  createRecipeItem,
  deleteRecipeItem,
  updateTilButton,
  updateSpecial,
  createSpecial,
  deleteSpecial,
  createStocktake,
  getStocktakes,
  getStocktakeById,
  updateStocktakeItem,
  saveStocktake
};

type ProductResponse = {
  isActive: boolean;
  productId: number;
  name: string;
  categoryId: number;
  categoryName: string;
  unitWeight: number;
  retailRRP: number;
  salePrice: number;
  saleQuantity: number;
  startTime: string;
  endTime: string;
  isLoyaltyItem: boolean;
  isBulk: boolean;
  criticalLevelMin: number;
  warningLevelMin: number;
  isWebsite: boolean;
  isRetail: boolean;
  applyWastage: boolean;
  applyConsumables: boolean;
  wastageCalc: number;
  groupingId: number;
  groupingName: string;
  packagingDetails: string;
  totalManufactureCost: string;
  totalMargin: string;
  isCat: boolean;
  isDog: boolean;
  description: string;
  line1: string;
  line2: string;
  line3: string;
  freightTypeId: number;
  freightType: string;
}

type StocktakeResponse = {
  stocktakeId: number;
  date: string;
  isComplete: boolean;
  totalValue: number;
  list: StocktakeItem[] | null;
}

type StocktakeItem = {
  stocktakeItemId: number;
  supplierProductId?: number;
  productId?: number;
  count: number;
  costPrice: number;
  totalCostPrice: number;
  productName?: string;
  supplierProductName?: string;
  supplierName?: string;
}

type ProductCategoryResponse = {
  productCategoryId: number;
  name: string;
  parentCategoryId: number;
  parentCategoryName: string;
}

type ProductGroupingReponse = {
  productGroupId: number;
  name: string;
}

type ProductImageResponse = {
  productImageId: number;
  imageUrl: string;
  isPrimaryImage: boolean;
}

type ProductRecipeResponse = {
  totalManufactureCosts: number;
  totalMargin: number;
  idealRRP: number;
  recipeItem: {
    supplierProductId: number;
    supplier: string;
    supplierProduct: string;
    quantity: number;
    unitPrice: number;
  }
}

type IdInfoResponse = {
  productId: number;
  name: string;
}

function getAll(): Promise<ProductResponse[]> {
  return fetchHandler(`${config.apiUrl}/product/getProducts`, authPostDataRequest({}))
    .then((result) => { return result.products; });
}

function getAllManufactured(): Promise<ProductResponse[]> {
  return fetchHandler(`${config.apiUrl}/product/getManufacturedProducts`, authPostDataRequest({}))
    .then((result) => { return result.products; });
}

function getAllAccessories(): Promise<IdInfoResponse[]> {
  return fetchHandler(`${config.apiUrl}/product/getAccessoryProducts`, authPostDataRequest({}))
    .then((result) => { return result.products; });
}

function getById(productId: number): Promise<ProductResponse> {
  return fetchHandler(`${config.apiUrl}/product/details`, authPostDataRequest({ productId }))
    .then(product => { return product; });
}

function createProduct(productCategoryId: number, productGroupId: number, name: string, isRetail: boolean, isWebsite: boolean, isLoyaltyItem: boolean, isBulk: boolean, applyWastage: boolean, wastageCalc: number, unitWeight: number, retailRRP: number, criticalLevelMin: number, warningLevelMin: number, packagingDetails: string, description: string, isCat: boolean, isDog: boolean): Promise<ProductResponse> {
  return fetchHandler(`${config.apiUrl}/product/create`, authPostDataRequest({ productCategoryId, productGroupId, name, isRetail, isWebsite, isLoyaltyItem, isBulk, applyWastage, wastageCalc, unitWeight, retailRRP, criticalLevelMin, warningLevelMin, packagingDetails, description, isCat, isDog }))
    .then((product: ProductResponse) => { return product; });
}

function deactivateProduct(productId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/product/activate`, authPostDataRequest({ productId, activateProduct: false }))
    .then((result) => { return result; });
}

function reactivateProduct(productId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/product/activate`, authPostDataRequest({ productId, activateProduct: true }))
    .then((result) => { return result; });
}

function getProductCategories(subCategoriesOnly: boolean): Promise<ProductCategoryResponse[]> {
  return fetchHandler(`${config.apiUrl}/product/getProductCategories`, authPostDataRequest({ subCategoriesOnly }))
    .then((result) => { return result.productCategories; });
}

function getProductGroupings(): Promise<ProductGroupingReponse[]> {
  return fetchHandler(`${config.apiUrl}/product/getProductGroups`, authPostDataRequest({}))
    .then((result) => { return result.productGroups; });
}

function getProductRecipes(productId: number): Promise<ProductRecipeResponse> {
  return fetchHandler(`${config.apiUrl}/product/getRecipeItems`, authPostDataRequest({ productId }))
    .then((result) => { return result; });
}


function createBarcode(productId: number, barcode: string): Promise<ProductImageResponse[]> {
  return fetchHandler(`${config.apiUrl}/product/createBarcode`, authPostDataRequest({ productId, barcode }))
    .then((result) => { return result; });
}

function deleteBarcode(productId: number, barcode: string): Promise<ProductImageResponse[]> {
  return fetchHandler(`${config.apiUrl}/product/deleteBarcode`, authPostDataRequest({ productId, barcode }))
    .then((result) => { return result; });
}

function getProductImages(productId: number): Promise<ProductImageResponse[]> {
  return fetchHandler(`${config.apiUrl}/product/getImages`, authPostDataRequest({ productId }))
    .then((result) => { return result.list; });
}

function getProductBarcodes(productId: number): Promise<string[]> {
  return fetchHandler(`${config.apiUrl}/product/getBarcodes`, authPostDataRequest({ productId }))
    .then((result) => { return result.list; });
}

function uploadImage(productId: number, imageData: string | ArrayBuffer, isPrimary: boolean): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/product/uploadImage`, authPostDataRequest({ productId, imageData, isPrimary }))
    .then((result: boolean) => { return result; });
}

function deleteImage(productImageId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/product/deleteImage`, authPostDataRequest({ productImageId }))
    .then((result: boolean) => { return result; });
}

function setPrimaryImage(productId: number, productImageId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/product/setPrimaryImage`, authPostDataRequest({ productId, productImageId }))
    .then((result: boolean) => { return result; });
}

function updateProduct(productId: number, productCategoryId: number, productGroupId: number | null, name: string, isRetail: boolean, isWebsite: boolean,
  isLoyaltyItem: boolean, isBulk: boolean, applyWastage: boolean, wastageCalc: number, unitWeight: number, retailRRP: number,
  criticalLevelMin: number, warningLevelMin: number, packagingDetails: string, isCat: boolean, isDog: boolean, description: string,
  applyConsumables: boolean, freightTypeId: number): Promise<ProductResponse> {
  return fetchHandler(`${config.apiUrl}/product/update`, authPostDataRequest({
    productId, productCategoryId, productGroupId, name, isRetail, isWebsite,
    isLoyaltyItem, isBulk, applyWastage, wastageCalc, unitWeight, retailRRP, criticalLevelMin, warningLevelMin, packagingDetails, isCat, isDog,
    description, applyConsumables, freightTypeId
  }))
    .then((product: ProductResponse) => { return product; });
}

function createRecipeItem(productId: number, supplierProductId: number, quantity: number, isDefault: boolean): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/product/createRecipeItem`, authPostDataRequest({ productId, supplierProductId, quantity, isDefault }))
    .then((result: boolean) => { return result; });
}

function deleteRecipeItem(userPin: string, productId: number, supplierProductId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/product/deleteRecipeItem`, authPostDataRequest({ userPin, productId, supplierProductId }))
    .then((result) => { return result; });
}

function updateTilButton(productId: number, line1: string, line2: string, line3: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/product/updateTilButton`, authPostDataRequest({ productId, line1, line2, line3 }))
    .then((result) => { return result; });
}

function updateSpecial(productId: number, salePrice: number, saleQuantity: number, startTime: string, endTime: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/product/updateSpecial`, authPostDataRequest({ productId, salePrice, saleQuantity, startTime, endTime }))
    .then((result) => { return result; });
}

function createSpecial(productId: number, salePrice: number, saleQuantity: number, startTime: string, endTime: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/product/createSpecial`, authPostDataRequest({ productId, salePrice, saleQuantity, startTime, endTime }))
    .then((result) => { return result; });
}

function deleteSpecial(productId: number): Promise<boolean> {
  return fetchHandler(`${config.apiUrl}/product/deleteSpecial`, authPostDataRequest({ productId }))
    .then((result) => { return result; });
}

function createStocktake(userPin: string, time: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/stocktake/create`, authPostDataRequest({ userPin, time }))
    .then(result => { return result; });
}

function getStocktakes(): Promise<StocktakeResponse[]> {
  return fetchHandler(`${config.apiUrl}/stocktake/get`, authPostDataRequest({}))
    .then(stocktakes => { return stocktakes.stocktakes; });
}

function getStocktakeById(stocktakeId: number): Promise<StocktakeResponse> {
  return fetchHandler(`${config.apiUrl}/stocktake/getById`, authPostDataRequest({ stocktakeId }))
    .then(stocktakes => { return stocktakes; });
}

function updateStocktakeItem(stocktakeItemId: number, count: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/stocktake/update`, authPostDataRequest({ stocktakeItemId, count }))
    .then(result => { return result; });
}

function saveStocktake(stocktakeId: number, isComplete: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/stocktake/save`, authPostDataRequest({ stocktakeId, isComplete }))
    .then(result => { return result; });
}
