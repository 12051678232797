import * as ko from 'knockout';
import { Observable } from 'knockout';
import { usersService } from '../../../api/service.users';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatFromNow } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminUsers {
  title: Observable<string>;
  users: ko.ObservableArray<any>;
  filteredUsers: ko.ObservableArray<any>;

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  filter = ko.observable('');

  constructor(params: any) {
    this.title = ko.observable('Admin / All Users (Customers)');
    this.users = ko.observableArray();
    this.filteredUsers = ko.observableArray();

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadUsers();

    ko.computed(() => {
      var loweredFilter = this.filter().toLocaleLowerCase();

      this.filteredUsers(this.users()
        .filter((u) =>
          u.emailAddress.toLocaleLowerCase().indexOf(loweredFilter) > -1
          || u.firstName.toLocaleLowerCase().indexOf(loweredFilter) > -1
          || u.lastName.toLocaleLowerCase().indexOf(loweredFilter) > -1)
      );
    });
  }

  loadUsers = () => {
    usersService
      .getAll()
      .then(users => {

        const userMap = users.map(u => {
          const icon = u.isActive ? 'icon/circle-tick' : 'icon/cancel-circle';
          const iconClass = u.isActive ? 'verify-icon' : 'cancel-icon';

          let access = [];
          if (u.isAdmin) {
            access.push('Admin');
          }
          if (u.isCashier) {
            access.push('Cashier');
          }
          if (u.isCashRegister) {
            access.push('Till');
          }

          const formattedAccess = access.length > 0 ? access.join(', ') : 'Customer';

          const formattedLastLogin = u.hasAccount ? formatFromNow(u.lastLoginTime) : '-';
          const formattedLastTransaction = formatFromNow(u.lastTransactionTime);

          return {
            ...u,
            icon: icon,
            iconClass: iconClass,
            formattedName: `${u.firstName} ${u.lastName}`,
            formattedAccess: formattedAccess,
            formattedLastLogin: formattedLastLogin,
            formattedLastTransaction: formattedLastTransaction,
            formattedEmailAddress: u.emailAddress,
          }
        });

        this.users(userMap);
        this.filteredUsers(this.users());
      })
      .catch(e => console.log(e));
  }

  createUser = () => {
    const origMessage = 'Create new user';
    const message = ko.observable(origMessage);

    const model = {
      firstName: ko.observable(''),
      lastName: ko.observable(''),
      emailAddress: ko.observable(''),
      phoneNumber: ko.observable(''),
      isAdmin: ko.observable(false),
      isCashier: ko.observable(false),
      isCashRegister: ko.observable(false),
      userPin: ko.observable(''),
      tilUserPin: ko.observable(''),
      emailReceipts: ko.observable(true)
    }

    const userPin: FieldType<string> = {
      title: 'Your PIN',
      type: 'userPin',
      value: model.userPin,
    };

    const firstNameField: FieldType<string> = {
      title: 'First Name',
      type: 'text',
      value: model.firstName,
    };

    const lastNameField: FieldType<string> = {
      title: 'Last Name',
      type: 'text',
      value: model.lastName,
    };

    const emailAddressField: FieldType<string> = {
      title: 'Email Address',
      type: 'text',
      value: model.emailAddress,
    };

    const phoneNumber: FieldType<string> = {
      title: 'Phone Number',
      type: 'text',
      value: model.phoneNumber,
    };

    const isAdminField: FieldType<boolean> = {
      title: 'Is Admin User?',
      type: 'checkbox',
      value: model.isAdmin,
    };

    const isCashierField: FieldType<boolean> = {
      title: 'Is Cashier User?',
      type: 'checkbox',
      value: model.isCashier,
    };

    const tilUserPin: FieldType<string> = {
      title: 'Till Pin (for til login)',
      type: 'number',
      value: model.tilUserPin,
    };

    const emailReceiptsField: FieldType<boolean> = {
      title: 'Email till receipts?',
      type: 'checkbox',
      value: model.emailReceipts,
    };

    dialog({
      title: `Create user`,
      message: message,
      fields: ko.observableArray([userPin, firstNameField, lastNameField, emailAddressField, phoneNumber, isAdminField, isCashierField, tilUserPin, emailReceiptsField]),
      cancelText: 'Cancel',
      submitText: 'Create',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.firstName() === '') {
          errors += 'First name cannot be empty. <br />';
        }

        if (model.lastName() === '') {
          errors += 'Last name cannot be empty. <br />';
        }

        if (model.emailAddress() === '') {
          errors += 'Email address cannot be empty. <br />';
        }

        if (model.isCashier() && model.tilUserPin() === '') {
          errors += 'A unique pin must be provided for all cashiers. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        usersService.create(model.firstName(), model.lastName(),
          model.emailAddress(), model.phoneNumber(),
          model.isAdmin(), model.isCashier(), model.userPin(), model.tilUserPin(), true)
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('User created.');

              this.loadUsers();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to create user.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  goto = {
    userDetails: (id: number): void => router.goto(routes.adminUserDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-users',
  viewModel: AdminUsers,
  template: require('./users.html')
};
