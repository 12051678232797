import * as ko from 'knockout';
import { Observable } from 'knockout';
import { locationsService } from '../../../api/service.locations';
import { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class AdminLocations {
  title: Observable<string>;
  locations: ko.ObservableArray<any>;

  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  constructor(params: any) {
    this.title = ko.observable('Admin / All Locations');
    this.locations = ko.observableArray();

    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadLocations();

  }

  loadLocations = () => {
    locationsService
      .getAll()
      .then(locations => {

        const locationMap = locations.map(l => {
          return {
            ...l,
            formattedType: (l.isPhysicalStore ? 'Physical' : 'Intangable') + (l.isCanPickFrom ? ' (Pickable)' : ''),
            formattedBulkStore: l.isBulkStore ? 'Bulk Storage' : '',
          }
        });

        this.locations(locationMap);
      })
      .catch(e => console.log(e));
  }

  createLocation = () => {
    const origMessage = 'Create location details';
    const message = ko.observable(origMessage);

    const model = {
      name: ko.observable(''),
      isPhysicalStore: ko.observable(true),
      isCanPickFrom: ko.observable(true),
      isBulkStore: ko.observable(false)
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const isPhysicalField: FieldType<boolean> = {
      title: 'Physical Location?',
      type: 'checkbox',
      value: model.isPhysicalStore,
    };

    const isCanPickField: FieldType<boolean> = {
      title: 'Can pick from?',
      type: 'checkbox',
      value: model.isCanPickFrom,
    };

    const isBulkField: FieldType<boolean> = {
      title: 'Bulk storage?',
      type: 'checkbox',
      value: model.isBulkStore,
    };

    dialog({
      title: `Edit location`,
      message: message,
      fields: ko.observableArray([nameField, isPhysicalField, isCanPickField, isBulkField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        locationsService.createLocation(model.name(), model.isPhysicalStore(), model.isCanPickFrom(), model.isBulkStore())
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Location created.');

              this.loadLocations();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to create location.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  editLocation = (locationId: number) => {
    const origMessage = 'Edit location details';
    const message = ko.observable(origMessage);

    var location: any = this.locations().filter((l) => l.locationId == locationId)[0];

    const model = {
      name: ko.observable(location.name),
      isPhysicalStore: ko.observable(location.isPhysicalStore),
      isCanPickFrom: ko.observable(location.isCanPickFrom),
      isBulkStore: ko.observable(location.isBulkStore)
    }

    const nameField: FieldType<string> = {
      title: 'Name',
      type: 'text',
      value: model.name,
    };

    const isPhysicalField: FieldType<boolean> = {
      title: 'Physical Location?',
      type: 'checkbox',
      value: model.isPhysicalStore,
    };

    const isCanPickField: FieldType<boolean> = {
      title: 'Can pick from?',
      type: 'checkbox',
      value: model.isCanPickFrom,
    };

    const isBulkField: FieldType<boolean> = {
      title: 'Bulk storage?',
      type: 'checkbox',
      value: model.isBulkStore,
    };

    dialog({
      title: `Edit location`,
      message: message,
      fields: ko.observableArray([nameField, isPhysicalField, isCanPickField, isBulkField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {
        var errors = '';

        //Check fields
        if (model.name() === '') {
          errors += 'Name cannot be empty. <br />';
        }

        if (errors !== '') {
          message(`${origMessage} <br><div class="error"><ul>${errors}</ul></div>`);

          setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
          return;
        }

        locationsService.updateLocation(locationId, model.name(), model.isPhysicalStore(), model.isCanPickFrom(), model.isBulkStore())
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Location updated.');

              this.loadLocations();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Failed to update location.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  deleteLocation = (locationId: number) => {
    dialog({
      title: `Delete location`,
      message: 'Are you sure you want to delete this location?',
      fields: ko.observableArray(),
      cancelText: 'Cancel',
      submitText: 'Delete',
      submitAction: () => {
        var errors = '';
        locationsService.deleteLocation(locationId)
          .then(result => {
            if (result) {
              dialog(null);

              this.toast('Location deleted.');

              this.loadLocations();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            }
          });
      },
    });
  }

  goto = {
    //productDetails: (id: number): void => router.goto(routes.adminProductDetails.interpolate({ id: id })),
  }
}

export default {
  name: 'bp-admin-locations',
  viewModel: AdminLocations,
  template: require('./locations.html')
};
