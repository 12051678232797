import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import { webContentService } from '../../../api/service.webcontent';
import { dialog } from '../../app';
import { FieldType } from '../../elements/bp-dialog';


class AdminDisplayBoards {
  title: Observable<string>;
  products: ObservableArray<any>;
  tableRows: ObservableArray<any>;

  constructor(params: any) {
    this.title = ko.observable('Admin / Display Boards');

    this.products = ko.observableArray();
    this.tableRows = ko.observableArray();

    this.loadDisplayProducts();
  }

  loadDisplayProducts = () => {
    this.tableRows([]);
    let rowId = 0;

    webContentService.getCustomAdverts()
      .then((result: any) => {
        result.products.forEach((p: any) => {
          this.tableRows.push({ rowId: rowId, productId: p.productId, name: p.name });
          rowId++;
        });

        while (this.tableRows().length < 10) {
          this.tableRows.push({ rowId: rowId, productId: 0, name: '' });
          rowId++;
        }

        productsService.getAll()
          .then(results => {
            this.products.push(...results.filter((p: any) => !this.tableRows().map(d => d.productId).includes(p.productId)));
          });
      });
  }

  removeProduct = (rowId: number) => {
    const productIds: number[] = [];

    this.tableRows().forEach(r => {
      if (r.rowId === rowId) {
        productIds.push(0);
      } else {
        productIds.push(r.productId);
      }
    });

    webContentService.saveCustomAdverts(productIds)
      .then(results => {
        this.loadDisplayProducts();
      });
  }

  editProduct = (rowId: number) => {
    const origMessage = 'Edit product';
    const message = ko.observable(origMessage);

    const model = {
      productId: ko.observable(0)
    };

    const productField: FieldType<string> = {
      title: 'Type',
      type: 'dropdown',
      value: model.productId,
      options: this.products,
      optionsText: (o: any) => o.name,
      optionsValue: (o: any) => o.productId,
      optionsCaption: ko.observable('Select')
    }

    dialog({
      title: `Edit Product`,
      message: message,
      fields: ko.observableArray([productField]),
      cancelText: 'Cancel',
      submitText: 'Save',
      submitAction: () => {

        const productIds: number[] = [];

        this.tableRows().forEach(r => {
          if (r.rowId === rowId) {
            productIds.push(model.productId());
          } else {
            productIds.push(r.productId);
          }
        });

        webContentService.saveCustomAdverts(productIds)
          .then(results => {
            this.loadDisplayProducts();
          });

        dialog(null);
      }
    });
  }
}

export default {
  name: 'bp-admin-display-boards',
  viewModel: AdminDisplayBoards,
  template: require('./display-boards.html')
};
