import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

export const webContentService = {
  getAll,
  getById,
  savePageContent,
  getOpeningHours,
  saveOpeningHours,
  deleteOpeningHours,
  createOpeningHours,
  getCustomAdverts,
  saveCustomAdverts
};

type PageContent = {
  pageContentId: number;
  pageName: string;
  contentName: string;
  html: string;
  updateTime: string;
  allowEditing: boolean;
}

type OpeningHours = {
  openingHoursId: number;
  day: string;
  canBeDeleted: boolean;
  isClosed: boolean;
  startTime: string;
  endTime: string;
  displayOrder: number;
  isActive: boolean;
  allowPickup: boolean;
  formattedPickupStart: string;
  formattedPickupEnd: string;
  specialDate: string;
  zoneSpecialDate: string;
}

function getAll(): Promise<PageContent[]> {
  return fetchHandler(`${config.apiUrl}/PublicContent/Get`, authPostDataRequest({}))
    .then((result) => { return result.pageContents; });
}

function getById(pageContentId: number): Promise<PageContent> {
  return fetchHandler(`${config.apiUrl}/PublicContent/GetById`, authPostDataRequest({ pageContentId: pageContentId }))
    .then((result) => { return result.pageContents; });
}

function savePageContent(pageContentId: number, html: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/PublicContent/SetPageContent`, authPostDataRequest({ pageContentId: pageContentId, html: html }))
    .then((result) => { return result; });
}

function getOpeningHours(): Promise<OpeningHours[]> {
  return fetchHandler(`${config.apiUrl}/PublicContent/GetAllOpeningHours`, authPostDataRequest({}))
    .then((result) => { return result.days; });
}

function saveOpeningHours(openingHoursId: number, day: string, specialDate: string, startTime: string, endTime: string, allowPickup: boolean, pickupStart: string, pickupEnd: string, isClosed: boolean, isActive: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/PublicContent/SetOpeningHours`, authPostDataRequest({ openingHoursId, day, specialDate, startTime, endTime, allowPickup, pickupStart, pickupEnd, isClosed, isActive }))
    .then((result) => { return result; });
}

function deleteOpeningHours(openingHoursId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/PublicContent/DeleteOpeningHours`, authPostDataRequest({ openingHoursId }))
    .then((result) => { return result; });
}

function createOpeningHours(day: string, startTime: string, endTime: string, isClosed: boolean, isActive: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/PublicContent/CreateOpeningHours`, authPostDataRequest({ day, startTime, endTime, isClosed, isActive }))
    .then((result) => { return result; });
}

function getCustomAdverts(): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/PublicContent/GetCustomAdverts`, authPostDataRequest({}))
    .then((result) => { return result; });
}

function saveCustomAdverts(productIds: number[]): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/PublicContent/SaveCustomAdverts`, authPostDataRequest({ productIds }))
    .then((result) => { return result; });
}
