import { Observable, Computed } from 'knockout';
import * as ko from 'knockout';

class BpButton {
  action: () => void;
  icon: Computed<string>;
  label: string;
  description: string;
  enable: Observable<boolean>;

  constructor(params: any) {
    this.action = params.action;

    this.icon = ko.pureComputed(() => ko.unwrap(params.icon));

    this.label = params.label;

    this.description = params.description;

    this.enable = ko.isObservable(params.enable)
      ? params.enable : ko.observable(true);
  }
}

export default {
  name: 'bp-button',
  viewModel: BpButton,
  template: require('./bp-button.html')
};
