import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webContentService } from '../../../api/service.webcontent';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { formatDateTime } from '../../../utils/format';
import { dialog } from '../../app';
import { DialogParams, FieldType, IdInfo } from '../../elements/bp-dialog';

class WebContentDetails {
  pageContentId: ko.Observable<number>;
  title = ko.observable<string>('Admin / Public Website Content');

  isEditing = ko.observable<boolean>(false);

  pageName = ko.observable<string>('');
  contentName = ko.observable<string>('');
  html = ko.observable<string>('');
  formatLastUpdated = ko.observable<string>('');
  allowEditing = ko.observable<boolean>(false);

  constructor(params: any) {
    this.pageContentId = ko.observable(params.id);

    webContentService.getById(this.pageContentId())
      .then(result => {

        this.pageName(result.pageName);
        this.contentName(result.contentName);
        this.html(result.html);
        this.allowEditing(result.allowEditing);
        this.formatLastUpdated(`Last updated: ${formatDateTime(result.updateTime)}`);
        this.title(`Admin / Public Website Content / ${result.pageName} - ${this.contentName()}`);
      })
      .catch((e: any) => { console.log(e); })
  }

  goto = {
    editPageContent: (id: number): void => router.goto(routes.adminWebContentDetails.interpolate({ id: id })),
  }

  actions = {
    editPageContent: (): void => {
      // Hide display box and replace with textarea.
      this.isEditing(true);
    },

    saveChanges: (): void => {

      webContentService.savePageContent(this.pageContentId(), this.html())
        .then(result => {
          if (result) {
            this.isEditing(false);
          } else {
            alert("Failed to save changes");
          }
        })
        .catch((e: any) => { console.log(e); });

      // If success
      this.isEditing(false);
    }
  }
}

export default {
  name: 'bp-web-content-details',
  viewModel: WebContentDetails,
  template: require('./web-content-details.html')
};
