import * as ko from 'knockout';
import '../assets/css/badpanda';
import registerComponents from './components/index';

export const breakpoint = ko.observable();
export const title = ko.observable('Woofles Production Portal');

registerComponents();

ko.applyBindings({ breakpoint: breakpoint, title: title }, window.document.getElementsByTagName('html')[0]);
