import { handleResponse } from './handleResponse';

export type ApiResponse = {
  success: boolean;
  message: string;
  data?: any;
}

export function fetchHandler(requestInfo: string, requestInit: RequestInit) {
  return fetch(requestInfo, requestInit)
    .then(handleResponse)
    .catch(e => { console.log('fetchHandlerError', e) });
}
