import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

export const batchesService = {
  getAll,
  getRecipe,
  create
};

type BatchResponse = {
  batchId: number;
  time: number;
  productId: number;
  product: string;
  manufacturedWeight: number;
}

type CreateBatchResponse = {
  product: string;
  recipeItems: RecipeItem[];
};

type RecipeItem = {
  quantity: number;
  isDefault: boolean;
  supplier: string;
  supplierProduct: string;
  supplierProductId: number;
};

export type BatchItem = {
  supplierProductId: number;
  shipmentId: number;
  quantityUsed: number;
};

function getAll(): Promise<BatchResponse[]> {
  return fetchHandler(`${config.apiUrl}/batches/get`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getRecipe(productId: number, quantity: number): Promise<CreateBatchResponse> {
  console.log('getRecipe', productId, quantity);
  return fetchHandler(`${config.apiUrl}/batches/getRecipe`, authPostDataRequest({ productId, manufacturedWeight: quantity }))
    .then((results) => { return results; });
}

function create(productId: number, manufacturedWeight: number, batchItems: BatchItem[]): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/batches/create`, authPostDataRequest({ productId, manufacturedWeight, batchItems }))
    .then((results) => { return results; });
}