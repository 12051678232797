import * as ko from 'knockout';
import { Observable } from 'knockout';
import { accountsService } from '../../../api/service.accounts';
import { LocalDate, localFromUtc } from '../../../utils/datetime';
import { roleList } from '../../../utils/const';
import { DialogParams, FieldType } from "../../elements/bp-dialog";

class AdminAccountDetails {
  readonly title: Observable<string>;
  readonly id: Observable<number>;
  readonly username = ko.observable<string>();
  readonly email = ko.observable<string>();
  readonly created = ko.observable<LocalDate>();
  readonly updated = ko.observable<LocalDate>();
  readonly isVerified = ko.observable<boolean>();
  readonly verified = ko.observable<string>();
  readonly roles = ko.observableArray<string>();
  readonly steamIds = ko.observableArray<string>();
  readonly dialog: Observable<DialogParams | null>;
  readonly toast: Observable<string | null>;

  constructor(params: any) {
    this.id = ko.observable(params.id);
    this.title = ko.observable('Account Details');
    this.dialog = ko.observable(null);
    this.toast = ko.observable(null);

    this.loadAccount();
  }

  private loadAccount() {
    accountsService
      .getById(this.id())
      .then(account => {
        this.username(account.username);
        this.email(account.email);
        this.created(localFromUtc(account.createdUtc));
        this.updated(localFromUtc(account.updatedUtc));
        this.isVerified(account.isVerified);
        this.verified(account.isVerified ? '(verified)' : '');
        this.roles(account.roles);
        this.steamIds(account.steamIds);
      })
      .catch(e => console.log(e));
  }

  unlinkSteamId = (accountId: number, steamId: string) => {
    const origMessage = `You are about to unlink '${steamId}' from this account. Are you sure?`;
    const message = ko.observable(origMessage);

    this.dialog({
      title: `Unlink Steam Id`,
      message: message,
      cancelText: 'Cancel',
      submitText: 'Unlink',
      submitAction: () => {
        accountsService.removeAccountSteamId(accountId, steamId)
          .then(result => {
            if (result) {
              this.dialog(null);

              const removedSteamId = this.steamIds().find(s => s === steamId);
              if (!removedSteamId) { return; }

              this.steamIds.remove(removedSteamId);

              this.toast('Unlink succeeded.');
              setTimeout(() => { this.toast(null); }, 5000);

            } else {
              message(origMessage + '<br><div class="error"> - Unlink failed.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  addSteamId = () => {
    const origMessage = 'Add a SteamId to the account';
    const message = ko.observable(origMessage);
    const model = {
      steamId: ko.observable()
    }

    const steamField: FieldType<string> = {
      title: 'SteamId',
      type: 'text',
      value: model.steamId,
    };

    this.dialog({
      title: `Add SteamId`,
      message: message,
      fields: ko.observableArray([steamField]),
      cancelText: 'Cancel',
      submitText: 'Add',
      submitAction: () => {
        accountsService.addAccountSteamId(this.id(), model.steamId())
          .then(result => {
            if (result) {
              this.dialog(null);

              this.toast('Add SteamId succeeded.');

              this.loadAccount();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Add SteamId failed.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  revokeRole = (role: string) => {
    const origMessage = `You are about to revoke '${role}' from this account. Are you sure?`;
    const message = ko.observable(origMessage);

    this.dialog({
      title: `Revoke Role`,
      message: message,
      cancelText: 'Cancel',
      submitText: 'Revoke',
      submitAction: () => {
        accountsService.removeAccountRole(this.id(), role)
          .then(result => {
            if (result) {
              this.dialog(null);

              const removedRole = this.roles().find(r => r === role);
              if (!removedRole) { return; }

              this.roles.remove(removedRole);

              this.toast('Revoke succeeded.');
              setTimeout(() => { this.toast(null); }, 5000);

            } else {
              message(origMessage + '<br><div class="error"> - Revoke failed.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }

  grantRole = () => {
    const origMessage = 'Grant a site role';
    const message = ko.observable(origMessage);
    const model = {
      role: ko.observable()
    }

    const roleField: FieldType<string> = {
      title: 'Role',
      type: 'dropdown',
      value: model.role,
      options: roleList,
      optionsText: (o: any) => o,
      optionsValue: (o: any) => o,
    };

    this.dialog({
      title: `Grant Role`,
      message: message,
      fields: ko.observableArray([roleField]),
      cancelText: 'Cancel',
      submitText: 'Grant',
      submitAction: () => {
        accountsService.grantAccountRole(this.id(), model.role())
          .then(result => {
            if (result) {
              this.dialog(null);

              this.toast('Grant succeeded.');

              this.loadAccount();

              setTimeout(() => {
                this.toast(null);
              }, 5000);
            } else {
              message(origMessage + '<br><div class="error"> - Grant failed.</div>');

              setTimeout(() => { this.toast(null); message(origMessage) }, 5000);
            }
          });
      },
    });
  }
}

export default {
  name: 'bp-admin-account-details',
  viewModel: AdminAccountDetails,
  template: require('./account-details.html')
};
